import React from 'react';
import {Col, ListGroup, ListGroupItem, Popover, Row, Table} from "react-bootstrap";
import {Glyph} from "./Glyph";
import {GlobalContext} from "../../GlobalContext";
import moment from "moment-timezone";

export class NotificationsPopover extends React.Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.className.includes("show") && !prevProps.className.includes("show")) {
            // Some unread?
            if (this.props.notifications.some(n => n.isRead == 0)) {
                // mark all as read after 1 second
                window.setTimeout(this.markAllRead, 1000);
            }
        }
    }

    markAllRead = () => {
        // only do this if the popup is still visible
        if (this.props.className.includes("show")) {
            this.context.apiRequest("POST", "/notifications/read", {ids: this.props.notifications.filter(n => n.isRead == 0).map(n => n.id)});
        }
    }

    render() {
        return <Popover id={`notifications-popover`} {...this.props}>
            <Popover.Title as="h4">Notifications</Popover.Title>
            <Popover.Content as={ListGroup} variant="flush" style={{padding: '0px'}}>
                    {
                        this.props.notifications && this.props.notifications.length > 0 ?
                            this.props.notifications.map((n, i) => <ListGroupItem key={i} style={{padding: "5px"}} className={n.isRead == 0 ? "border-danger" : ""}>
                                <Table borderless size={"sm"} className={"w-100 m-0"}>
                                    <tbody>
                                    <tr>
                                        {
                                            n.iconName && <td className={"text-center align-middle"}><Glyph className={"mr-2"} name={n.iconName}
                                                                 style={n.iconColor ? {
                                                                     fontSize: "1.5rem",
                                                                     color: n.iconColor
                                                                 } : {fontSize: "2.0rem"}}/></td>
                                        }
                                        <td>{n.text}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} className={"tiny-hint text-right"}>{moment(n.createdAt).fromNow()}</td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </ListGroupItem>)
                            : <ListGroupItem>
                                <Row>
                                    <Col sm={2}><Glyph name={"hand-thumbs-up"} className={"text-secondary"} style={{fontSize: "2.0rem"}}/></Col>
                                    <Col>No recent notifications.</Col>
                                </Row>
                            </ListGroupItem>
                    }
            </Popover.Content>
        </Popover>
    }
}