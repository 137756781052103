import React from 'react';
import {GlobalContext} from "../../GlobalContext";
import {Card, Col, Container, Row} from "react-bootstrap";
import {PatientSearch} from "../common/patient/PatientSearch";
import {
    Switch,
    Route, Redirect
} from "react-router-dom";
import PatientSearchResults from "../common/patient/PatientSearchResults";
import {LiensToDoList} from "./LiensToDoList";
import PatientLienList from "./PatientLienList";
import {PendingLiensList} from "./PendingLiensList";

export class LiensHome extends React.Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.state = {
            patientId: null,
            patientSearchTerm: null
        };
    }

    patientSelected = (patientId) => {
        this.context.resetLogoutTimer();
        this.setState({patientId: patientId, patientSearchResults: null});
    };

    patientSearchGoClicked = (searchTerm) => {
        this.context.resetLogoutTimer();
        this.setState({patientId: null, patientSearchTerm: searchTerm});
    };

    render() {
        return (
            <Container fluid={true}>
                <Row>
                    <Col md={8}>
                        <PatientSearch id={"liensPatientSearch"} clearOnSelect={true} showNewButton={false} showGoButton={true} patientSelected={this.patientSelected} goButtonClicked={this.patientSearchGoClicked}/>
                        {this.state.patientId !== null ? <Redirect push to={`/liens/patients/${this.state.patientId}`} /> : <span/> }
                        {this.state.patientSearchTerm ? <Redirect push to={`/liens/patients/search?q=${this.state.patientSearchTerm}`} /> : <span/> }
                    </Col>
                </Row>
                <Row>
                    <Col md={8}>
                        <Switch>
                            <Route exact path={'/liens'}>
                                <DefaultLiensHomeContent/>
                            </Route>
                            <Route exact path={'/liens/patients/search'}>
                                <PatientSearchResults patientSelected={this.patientSelected}/>
                            </Route>
                            <Route path={'/liens/patients/:patientId'}>
                                <PatientLienList/>
                            </Route>
                        </Switch>
                    </Col>
                    <Col>
                        <LiensToDoList patientSelected={this.patientSelected} className={"dashboardSideBox-md"}/>
                        <PendingLiensList patientSelected={this.patientSelected} className={"dashboardSideBox-md mt-2"}/>
                    </Col>
                </Row>
            </Container>
        );
    }
}

function DefaultLiensHomeContent() {
    return <Card>
        <Card.Body>
            <h3>Liens Dashboard</h3>
            <p>To search for a patient, enter any part of the name or an Intergy ID.</p>
            <p>Click on one of the suggestions, or use the down arrow on your keyboard and hit enter
                to choose a patient.</p>
            <p>Alternatively, you can click the Go button to have the search results displayed in
                this window.</p>
        </Card.Body>
    </Card>;
}