import React from "react";
import {Button, Form, Modal} from "react-bootstrap";

export class ItemModal extends React.Component {

    idField = 'id';
    itemTypeName = 'Item';

    constructor(props) {
        super(props);

        this.state = {
            item: props.item,
            validated: false,
            validationErrors: []
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.item[this.idField] != this.props.item[this.idField]) {
            this.setState({item: this.props.item, validated: false}, this.itemChanged);
        }
        if (!prevProps.show && this.props.show) {
            this.setState({item: this.props.item, validated: false}, this.becameVisible);
        }
    }

    ok = () => {
        if (this.validateForm()) {
            this.props.onOk(this.state.item);
        }
    };

    /**
     * Override me
     * @returns {boolean}
     */
    doCustomValidation = () => {
        return true;
    };

    /**
     * Override me
     * @returns {JSX.Element}
     */
    renderFormContents = (item) => {
        return <span/>;
    };

    /**
     * Override me if needed
     */
    itemChanged = () => {};

    /**
     * Override me if needed
     */
    becameVisible = () => {};

    validateForm = () => {
        this.setState({validated: true, validationErrors: []});
        let valid = this.doCustomValidation();
        let form = document.getElementById('item-form');
        valid &= form.checkValidity();
        this.setState({validated: true});
        return valid;
    };

    addValidationErrors = (errs) => {
        let ve = this.state.validationErrors;
        if (Array.isArray(errs)) {
            ve = ve.concat(errs);
        } else {
            ve.push(errs);
        }
        this.setState({validationErrors: ve});
    };

    itemFieldChanged = (e, overrideValue, callback) => {
        let fieldName = e.target.name;
        let value = overrideValue === undefined ? e.target.value : overrideValue;
        this.setItemField(fieldName, value, callback);
    };

    setItemField = (fieldName, value, callback) => {
        this.setState(prvState => ({
            item: {
                ...prvState.item,
                [fieldName]: value
            }
        }), callback);
    };

    getTitle = (item) => {
        if (!item) return '';
        return (item[this.idField] > 0 ? 'Edit' : 'New') + ' ' + (this.itemTypeName || 'Item');
    };

    render() {
        let i = this.state.item;
        return <Modal size={this.props.size || "md"} show={this.props.show} onHide={this.props.onCancel}>
            <Modal.Header closeButton>
                <Modal.Title>{this.getTitle(i)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    this.state.validationErrors ? <ul className={'danger'}>
                        {this.state.validationErrors.map((e, i) => <li key={i}>{e}</li>)}
                    </ul> : <></>
                }
                <Form id={"item-form"} noValidate validated={this.state.validated} onSubmit={this.ok}>
                    {
                        i && this.renderFormContents(i)
                    }
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={this.props.onCancel}>
                    Cancel
                </Button>
                <Button variant="dark" onClick={this.ok}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    }
}