import React from "react";
import {GlobalContext} from "../../GlobalContext";
import {Button, Col, Container, Form, FormControl, InputGroup, Table} from "react-bootstrap";

export class AdminTestReportsForm extends React.Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.state = {
            jobs: [],
            selectedJob: null,
            recipients: null,
            success: false
        }
    }

    componentDidMount() {
        this.context.apiRequest("GET", "/reports")
            .then(d => {
                if (d.data) {
                    let jobs = [];
                    d.data.forEach(r => {
                        jobs = jobs.concat(r.scheduledJobs);
                    });
                    this.setState({jobs: jobs});
                }
            })
    }

    jobChanged = (e) => {
        let id = e.target.value;
        let job = this.state.jobs.find(j => j.id == id);
        if (job) {
            let recipients = Array.isArray(job.settings.to) ? job.settings.to.join(',') : job.settings.to;
            this.setState({selectedJob: job, recipients: recipients});
        }
    };

    runReport = () => {
        let j = this.state.selectedJob;
        if (j) {
            let settings = j.settings;
            let recipients = this.state.recipients;
            if (recipients) {
                if (recipients.includes(',')) {
                    recipients = recipients.split(',').map(r => r.trim());
                }
                settings.to = recipients;
            }
            this.setState({success: false}, () => {
                this.context.apiRequest("POST", "/reports/test", settings)
                    .then(d => {
                        if (d && d.status == 'OK') {
                            this.setState({success: true});
                        }
                    });
            })
        }
    };

    render() {
        let j = this.state.selectedJob;
        return <Container>
            <Form id={"report-test-form"} onSubmit={e => e.preventDefault()}>
                <Form.Row>
                    <Form.Group as={Col} controlId="jobId" md={6}>
                        <Form.Label size="sm">
                            Select a report:
                        </Form.Label>
                        <Form.Control as={"select"} custom={true} name={"jobId"} defaultValue={""} onChange={this.jobChanged}>
                            <option value={""} disabled>Select an automated report to test</option>
                            {
                                this.state.jobs.map(j => <option key={j.id} value={j.id}>{j.name}</option>)
                            }
                        </Form.Control>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                        {
                            j ? <Col md={6}>
                                    <Table size={"sm"}>
                                        <tbody>
                                        <tr>
                                            <td>Schedule:</td>
                                            <td>{j.cronEnglish}</td>
                                        </tr>
                                        <tr>
                                            <td>Sent to:</td>
                                            <td>{j.settings.to}</td>
                                        </tr>
                                        <tr>
                                            <td>Parameters:</td>
                                            <td>{j.settings.parameters.map(p => <div key={p.name}>{p.name} = {p.value}</div>)}</td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                                : <span/>
                        }
                </Form.Row>
                <Form.Row>
                    {
                        j ?
                            <Form.Group as={Col} controlId="recipient" md={6}>
                                <Form.Label size="sm">
                                    Recipient(s):
                                </Form.Label>
                                <InputGroup>
                                    <FormControl
                                        name={"recipient"}
                                        placeholder="Recipient's email"
                                        aria-label="Recipient's email"
                                        aria-describedby="basic-addon2"
                                        value={this.state.recipients}
                                        onChange={e => this.setState({recipients: e.target.value})}
                                    />
                                    <InputGroup.Append>
                                        <Button variant="dark" onClick={this.runReport}>Run Report</Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Form.Group>
                        : <span/>
                    }
                </Form.Row>
                {
                    this.state.success ? <Form.Row>
                        <Col className={"text-center text-success"} md={6}>
                            Report triggered successfully.
                        </Col>
                    </Form.Row> : <span/>
                }
            </Form>
        </Container>
    }
}