import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {LookupTableEditor} from "../common/LookupTableEditor";

export class AdminLookupTablesList extends React.Component {

    render() {
        return <Container>
            <Row>
                <Col>
                    <LookupTableEditor title={"Insurance"} tableName={"insurance"} idField={"insuranceId"} orderBy={"carrierName"} allowAdd={true}
                                        columnHeaders={['ID', 'Name', 'Active']}
                                        defaultItem={{insuranceId: 0, carrierName: '', active: 1}}
                                        fields={['insuranceId', 'carrierName', 'active']}
                                        editable={[false, true, true]}
                                        inputTypes={['', '', 'switch']}
                                        limit={100}
                                        filterable={[false, true, false]}/>
                </Col>
            </Row>
            <Row className={"mt-5"}>
                <Col>
                    <LookupTableEditor title={"ICD10s"} tableName={"icd10"} idField={"code"} orderBy={"code"} allowAdd={true}
                                        columnHeaders={['Code', 'Description']}
                                        defaultItem={{code: '', description: ''}}
                                        fields={['code', 'description']}
                                        editable={[true, true]}
                                        inputTypes={['', '']}
                                       limit={100}
                                       filterable={[true, true]}/>
                </Col>
            </Row>
        </Container>
    }
}