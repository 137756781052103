import moment from "moment-timezone";

export const shortDatePattern = '^(0?[1-9]|1[0-2])\\/(0?[1-9]|1\\d|2\\d|3[01])\\/(19|20)\\d{2}$';
export const TIMEZONE = 'America/Los_Angeles';

export const validateMMDDYYYY = (str) => {
    let date_regex = /^(0?[1-9]|1[0-2])\/(0?[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/ ;
    return date_regex.test(str);
};

export const formatDate = (d, twoDigitYear) => {
    return d ? moment(d).tz(TIMEZONE).format(twoDigitYear ? 'MM/DD/YY' : 'MM/DD/YYYY') : '';
};

export const formatDateTime = (dt) => {
    return dt ? moment(dt).tz(TIMEZONE).format('MM/DD/YYYY h:mm A') : '';
};

export const todayStr = (twoDigitYear) => {
    return nowMoment().format(twoDigitYear ? 'MM/DD/YY' : 'MM/DD/YYYY');
};

export const nowMoment = () => {
    return moment().tz(TIMEZONE);
}

export const addBusinessDays = (dt, days) => {
    let absDays = Math.abs(days);
    let mt = moment(dt);

    let i=0;
    let day;
    while (i < absDays) {
        if (days < 0) mt.subtract(1, 'day');
        else mt.add(1, 'day');
        day = mt.day();
        if (day > 0 && day < 6) i++;
    }

    return mt.toDate();
}