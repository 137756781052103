
const cache = {};

export function checkCache(uri) {
    let now = Date.now();
    if(cache[uri] && cache[uri].expiry > now) {
        return cache[uri].data;
    }
    return null;
}

export function putCache(uri, data, expirySeconds) {
    let now = Date.now();

    cache[uri] = {expiry: now + (expirySeconds*1000), data};
}
