import React from 'react';
import {GlobalContext} from "../../../GlobalContext";
import {Table} from "react-bootstrap";
import {SpinnerWrapper} from "../SpinnerWrapper";
import {formatDateTime} from "../../../utils/Dates";

export class PatientAppointmentList extends React.Component {
    static contextType = GlobalContext;

    render() {
        return <SpinnerWrapper visible={this.props.loading}>
            <Table striped bordered>
                <thead>
                    <tr>
                        <th>Date/Time</th>
                        <th>Status</th>
                        <th>Type</th>
                        <th>Reason</th>
                        <th>Provider</th>
                    </tr>
                </thead>
                <tbody>
                    { this.props.apptList.map(appt => <tr key={appt.appointmentId}>
                        <td>{formatDateTime(appt.appointmentDateTime)}</td>
                        <td>{appt.status}</td>
                        <td>{appt.appointmentType}</td>
                        <td>{appt.reason}</td>
                        <td>{appt.providerFirst} {appt.providerLast}</td>
                    </tr>)
                    }
                </tbody>
            </Table>
        </SpinnerWrapper>;
    }
}