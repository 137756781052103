import React from "react";
import {GlobalContext} from "../../GlobalContext";
import {Table, Form} from "react-bootstrap";
import {Glyph} from "./Glyph";
import {LookupTableEditorRow} from "./LookupTableEditorRow";

export class LookupTableEditor extends React.Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);

        this.state = {
            rows: [],
            more: false,
            filterable: this.props.filterable && this.props.filterable.includes(true),
            filters: {}
        }
    }

    componentDidMount() {
        this.loadTable();
    }

    loadTable = () => {
        let qParams = [];
        if (this.props.orderBy) qParams.push('orderBy='+this.props.orderBy);
        if (this.props.limit) qParams.push('limit='+this.props.limit);

        let filterMap = this.state.filters;
        let filterKeys = Object.keys(filterMap);
        if (filterKeys) {
            let filterClauses = [];
            filterKeys.forEach(k => {
                filterClauses.push(`${k} like '${filterMap[k]}%'`)
            });
            qParams.push('filter=' + encodeURIComponent(filterClauses.join(' and ')));
        }

        this.context.apiRequest('GET', `/lookup/${this.props.tableName}${qParams ? '?' + qParams.join('&') : ''}`)
            .then(resp => {
                if (resp && resp.data) {
                    this.setState({rows: resp.data, more: resp.meta.more || false});
                }
            });
    }

    addNew = () => {
        let rows = this.state.rows;
        let obj = JSON.parse(JSON.stringify(this.props.defaultItem));
        if (!(rows.find(r => r[this.props.idField] === obj[this.props.idField]))) {
            rows.unshift(obj);
            this.setState({rows: rows});
        }
    }

    removeNew = () => {
        let rows = this.state.rows;
        rows.splice(0, 1);
        this.setState({rows: rows});
    }

    filterChanged = (e) => {
        let field = e.target.name;
        let val = e.target.value;
        let filters = this.state.filters;
        filters[field] = val;
        this.setState({filters: filters});
        this.filterChangeDelay();
        console.log(`Filter changed: ${field}=${val}`);
    }

    filterTimer = null;

    filterChangeDelay = () => {
        if (this.filterTimer) clearTimeout(this.filterTimer);
        this.filterTimer = setTimeout(this.loadTable, 500);
    }

    render() {
        return <div>
            <h4>{this.props.title}{this.props.allowAdd ? <Glyph name={"plus-circle"} className={"clickable ml-2"} label={"Add"} onClick={this.addNew}/> : <></>}</h4>
            <div className={"lookupTableEditor"}>
                <Table striped size={"sm"}>
                    <thead>
                        <tr>
                            {this.props.columnHeaders.map((n, i) => <th key={i} className={"align-middle"}>

                                {this.props.filterable && this.props.filterable[i] ? <Form inline>{n}<Form.Control className={"ml-2 d-inline-block"} name={this.props.fields[i]} size={"sm"} onChange={this.filterChanged}/></Form> : n}
                            </th>)}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        this.state.rows.map((r, i) => <LookupTableEditorRow onCancelNew={this.removeNew} key={i} row={r} rowIndex={i} rowId={r[this.props.idField]} {...this.props}/>)
                    }
                    {
                        this.state.more && <tr className={"align-middle"}>
                            <td colSpan={this.props.columnHeaders.length+1} className={"small text-center align-middle"}>Additional results not shown{this.state.filterable && ' - use filters above to narrow down results'}.</td>
                        </tr>
                    }
                    </tbody>
                </Table>
            </div>
        </div>
    }
}