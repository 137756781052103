import React from "react";
import {GlobalContext} from "../../GlobalContext";
import {Form} from "react-bootstrap";
import {Glyph} from "./Glyph";

export class LookupTableEditorRow extends React.Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);

        this.state = {
            row: props.row,
            id: props.rowId
        }
    }
    
    fieldChanged = (e, overrideValue) => {
        let obj = this.state.row;
        obj['dirty'] = true;
        if (typeof overrideValue == 'undefined' || overrideValue == null) {
            obj[e.target.name] = e.target.value;
        } else {
            obj[e.target.name] = overrideValue;
        }
        this.setState({row: obj});
    }

    buildField = (fieldName, fieldIndex) => {
        let content;
        if (this.props.editable[fieldIndex]) {
            switch (this.props.inputTypes[fieldIndex]) {
                case 'switch':
                    content = <Form.Check
                        id={`${fieldName}_${this.props.rowIndex}`}
                        type={"switch"}
                        name={fieldName}
                        aria-label={this.props.columnHeaders[fieldIndex]}
                        checked={this.state.row[fieldName] === 1}
                        onChange={e => this.fieldChanged(e, e.target.checked ? 1 : 0)}/>;
                    break;
                default:
                    content = <Form.Control size={"sm"} name={fieldName} value={this.state.row[fieldName]} onChange={(e) => this.fieldChanged(e)}/>;
            }
        } else {
            content = this.state.row[fieldName];
        }

        return <td key={fieldName+this.state.id} className={"align-middle"}>
            {content}
        </td>
    }

    saveRow = () => {
        let obj = this.state.row;
        let method, uri;
        if (this.state.id) {
            method = 'PUT';
            uri = `/lookup/${this.props.tableName}/${this.state.id}?idField=${this.props.idField}`
        } else {
            // creating - remove ID field and do a POST
            let idIndex = this.props.fields.findIndex(c => c === this.props.idField);
            if (!this.props.editable[idIndex]) delete obj[this.props.idField];
            method = 'POST';
            uri = `/lookup/${this.props.tableName}?idField=${this.props.idField}`
        }
        delete obj.dirty;
        this.context.apiRequest(method, uri, obj)
            .then(resp => {
                if (resp && resp.data) {
                    this.setState({row: resp.data, id: resp.data[this.props.idField]});
                }
            });
    }

    cancelEditRow = () => {
        let obj = this.state.row;
        let idVal = obj[this.props.idField];
        if (idVal) {
            let filter = `${this.props.idField}=`;
            if (typeof idVal === 'string') {
                filter += `'${idVal}'`;
            } else {
                filter += idVal;
            }
            this.context.apiRequest('GET', `/lookup/${this.props.tableName}?filter=${encodeURIComponent(filter)}`)
                .then(resp => {
                    if (resp && resp.data && resp.data.length == 1) {
                        this.setState({row: resp.data[0]});
                    }
                });
        } else {
            this.props.onCancelNew();
        }
    }

    render() {
        const r = this.state.row;
        return <tr>
                    {this.props.fields.map((f,j) => this.buildField(f, j))}
                    <td style={{width: '50px'}}>
                        <Glyph name={"check-square"} className={`clickable mr-2 ${r.dirty ? '' : 'hidden'}`} label={'Save'} onClick={() => this.saveRow()}/>
                        <Glyph name={"x-square"} className={`clickable ${r.dirty ? '' : 'hidden'}`} label={'Cancel'} onClick={() => this.cancelEditRow()}/>
                    </td>
                </tr>
    }
}