import {ItemList} from "../common/ItemList";
import React from "react";
import {ItemModal} from "../common/ItemModal";
import {Glyph} from "../common/Glyph";
import {Form} from "react-bootstrap";
import {GlobalContext} from "../../GlobalContext";

export class AdminSystemConfigTable extends ItemList {
    static contextType = GlobalContext;

    baseUrl = `/config`;
    idField = 'key';
    itemTypeName = 'Config';
    headerColumns = ['Key', 'Value', ''];
    headerColumnClasses = ['w-25', 'w-50', ''];
    dataColumnClasses = ['align-middle text-monospace', 'align-middle', 'align-middle'];
    addSupported = false;
    editSupported = false;
    deleteSupported = false;
    paging = false;
    modalClass = ItemModal;

    itemChanged = (e) => {
        let dirtyKey = 'dirty_'+e.target.name;
        let items = this.state.items;
        items.filter(i => i.key === e.target.name)[0].value = e.target.value;
        this.setState({[dirtyKey]: true, items: items});
    };

    save = (key) => {
        let value = this.state.items.filter(i => i.key === key)[0].value;
        let data = {key, value};
        this.context.apiRequest('POST', '/config', data)
            .then(d => {
                if (d && d.status === 'OK') {
                    let dirtyKey = 'dirty_'+key;
                    this.setState({[dirtyKey]: false});
                }
            });
    };

    getItemCellContents = (item) => {
        let dirtyKey = 'dirty_'+item.key;
        return [
            item.key,
            <Form.Control name={item.key} value={item.value || ''} onChange={(e) => this.itemChanged(e)}/>,
            this.state[dirtyKey] ? <Glyph name={'check-square clickable'} style={{fontSize: '1.5em'}} onClick={() => this.save(item.key)}/> : <span/>
        ];
    };
}