import React from "react";
import {Form} from "react-bootstrap";

export class RadioList extends React.Component {

    render() {
        let vals = this.props.values || [];
        return <span className={this.props.className}>
            {
                vals.map((v, i) => <Form.Check className={"fullSizeLabel"} key={i} inline={this.props.inline} label={this.props.labels ? this.props.labels[i] : v}
                                               value={v} name={this.props.name}
                                               type={"radio"} onChange={this.props.onChange}
                                               checked={this.props.currentValue === v}/>)
            }
        </span>
    }
}