import React from 'react';
import {Form} from "react-bootstrap";
import {shortDatePattern} from "../../utils/Dates";

export class DateInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value !== this.props.value) {
            this.setState({value: this.props.value})
        }
    }

    onChange = (e) => {
        let val = e.target.value;
        let slashCount = (val.match(/\//g) || []).length;
        let newVal = val;

        if (slashCount !== 2) {
            // only do this logic if there aren't already 2 slashes
            let digitsOnly = val.replace(/\D/g, '');
            newVal = digitsOnly.substr(0, 2);
            if (digitsOnly.length > 2) {
                newVal += '/';
            }
            if (digitsOnly.length >= 3) {
                newVal += digitsOnly.substr(2, 2);
            }
            if (digitsOnly.length > 4) {
                newVal += '/' + digitsOnly.substr(4);
            }
        }
        this.setState({value: newVal}, () => {
            if (this.props.onChange) {
                let event = {
                    target: {
                        name: this.props.name,
                        value: newVal
                    }
                }
                this.props.onChange(event);
            }
        });
    }

    render() {
        return <Form.Control required={this.props.required || false} name={this.props.name} size={this.props.size || "sm"} onChange={this.onChange} value={this.state.value || ''} placeholder={this.props.placeholder || "mm/dd/yyyy"} pattern={this.props.pattern || shortDatePattern} disabled={this.props.disabled || false}/>;
    }
}