import React from 'react';
import {GlobalContext} from "../../../GlobalContext";
import {Card, Col, Row, Table} from "react-bootstrap";
import {withRouter} from 'react-router-dom';
import {parse} from "qs";
import {SpinnerWrapper} from "../SpinnerWrapper";

class PatientSearchResults extends React.Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.state = {
            patients: [],
            loading: true
        };
    }

    getTerm = (props) => {
        const query = parse((props || this.props).location.search, {
            ignoreQueryPrefix: true
        });
        return query.q;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let term = this.getTerm();
        let prevTerm = this.getTerm(prevProps);
        if (term !== prevTerm) {
            this.doSearch(term);
        }
    }

    componentDidMount() {
        let term = this.getTerm();
        this.doSearch(term);
    }

    doSearch = (term) => {
        this.context.apiRequest("GET", "/patients/search?q="+encodeURIComponent(term))
            .then(d => {
                if(d.data) this.setState({patients: d.data, loading: false});
            })
    }

    render() {
        return (
            <Card>
                <Card.Body>
                    <SpinnerWrapper visible={this.state.loading}>
                        <Card.Title>
                            <Row>
                                <Col>
                                    <h4>Search Results ({this.state.patients.length})</h4>
                                </Col>
                            </Row>
                        </Card.Title>
                        <Table striped hover>
                            <thead>
                                <tr>
                                    <th>Last Name</th>
                                    <th>First Name</th>
                                    <th>DOB</th>
                                    <th>Intergy ID</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.patients.map(p => <tr key={p.id} className={"clickable"} onClick={() => this.props.patientSelected(p.id)}>
                                    <td>{p.lastName}</td>
                                    <td>{p.firstName}</td>
                                    <td>{p.dob}</td>
                                    <td>{p.externalId}</td>
                                </tr>)
                            }
                            </tbody>
                        </Table>
                    </SpinnerWrapper>
                </Card.Body>
            </Card>
        );
    }
}

export default withRouter(PatientSearchResults);