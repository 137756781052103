import React from "react";
import {GlobalContext} from "../../GlobalContext";

export class RoleWrapper extends React.Component {
    static contextType = GlobalContext;

    render() {
        let hasRole = this.context.hasAnyRole(this.props.roles) || this.context.hasAnyRole(["System Admin"]);
        return ((!this.props.inverse && hasRole) || (!hasRole && this.props.inverse)) ? this.props.children : <></>;
    }
}