import React from "react";
import {Col, Form} from "react-bootstrap";
import {ItemModal} from "../common/ItemModal";
import moment from "moment";
import {DateInput} from "../common/DateInput";

export class LienPaymentModal extends ItemModal {

    idField = 'id';
    itemTypeName = 'Payment';

    doCustomValidation = () => {
        let valid = true;
        let dt = this.state.item.paymentDate;
        if (!moment(dt).isValid()) {
            valid = false;
            this.addValidationErrors(`Invalid date format: ${dt}`);
        }
        if (!/^\-?\d+\.?\d*$/.test(this.state.item.amount)) {
            valid = false;
            this.addValidationErrors(`Invalid number format: ${this.state.item.amount}`);
        }
        return valid;
    }

    renderFormContents = (t) => {
        return <div>
            <Form.Row>
                <Form.Group as={Col} controlId="paymentDate" md={4}>
                    <Form.Label size="sm">
                        Payment Date
                    </Form.Label>
                    <DateInput required size="sm" name={"paymentDate"} value={t.paymentDate}
                               pattern={"^(0?[1-9]|1[0-2])\\/(0?[1-9]|1\\d|2\\d|3[01])\\/\\d{2}$"}
                               placeholder={"mm/dd/yy"}
                               onChange={this.itemFieldChanged}/>
                </Form.Group>
                <Form.Group as={Col} controlId="amount" md={8}>
                    <Form.Label size="sm">
                        Amount
                    </Form.Label>
                    <Form.Control required size="sm" name={"amount"} value={t.amount} placeholder={'0.00'}
                                  onChange={this.itemFieldChanged}/>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} controlId="description">
                    <Form.Label size="sm">
                        Description
                    </Form.Label>
                    <Form.Control as={"textarea"} size="sm" name={"description"} value={t.description}
                                  onChange={this.itemFieldChanged}/>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} controlId="adjustment">
                    <Form.Check
                        label={"This is an adjustment"}
                        type={"switch"}
                        id={"adjustment"}
                        name={"adjustment"}
                        aria-label={"Adjustment"}
                        checked={t.adjustment === 1}
                        onChange={e => this.itemFieldChanged(e, e.target.checked ? 1 : 0)}/>
                </Form.Group>
            </Form.Row>
        </div>;
    };
}