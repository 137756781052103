import React from "react";
import {Button, Modal} from "react-bootstrap";
import {GroupSearch} from "./GroupSearch";

export class GroupSearchModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            groupId: null,
            groupName: null,
            searchTerm: null
        }
    }

    reset = () => {
        this.setState({groupId: null, groupName: null, searchTerm: null});
    };

    ok = () => {
        this.props.onOk(this.state.groupId, this.state.groupName);
        this.reset();
    };

    cancel = () => {
        this.reset();
        this.props.onCancel();
    };

    render() {
        return <Modal size={"sm"} show={this.props.show} onHide={this.cancel}>
            <Modal.Header closeButton>
                <Modal.Title>Find Group</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <GroupSearch size={"sm"} groupSelected={(id, name) => this.setState({groupId: id, groupName: name})}/>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={this.cancel}>
                    Cancel
                </Button>
                <Button variant="dark" onClick={this.ok}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    }
}