import React from 'react';
import {GlobalContext} from "../../GlobalContext";
import {Card, Col, Container, Row} from "react-bootstrap";
import {
    Switch,
    Route, Redirect
} from "react-router-dom";
import {AlertList} from "../common/AlertList";
import {MarketingSearch} from "./MarketingSearch";
import MarketingSearchResults from "./MarketingSearchResults";
import ProviderView from "./ProviderView";
import GroupView from "./GroupView";
import {MarketingFutureActivityWidget} from "./MarketingFutureActivityWidget";

export class MarketingHome extends React.Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.state = {
            groupId: null,
            providerId: null,
            searchTerm: null
        };
    }

    marketingSearchGoClicked = (searchTerm) => {
        this.setState({providerId: null, groupId: null, searchTerm: searchTerm});
    };

    itemSelected = (discrim, id) => {
        if (discrim === 'Provider') {
            this.setState({providerId: id, groupId: null, searchTerm: null});
        } else if (discrim === 'Group') {
            this.setState({providerId: null, groupId: id, searchTerm: null});
        }
    }

    render() {
        return (
            <Container fluid={true}>
                <Row>
                    <Col md={8}>
                        <MarketingSearch id={"marketingSearch"} clearOnSelect={true} showNewButtons={true} showGoButton={true} itemSelected={this.itemSelected} goButtonClicked={this.marketingSearchGoClicked}/>
                        {this.state.providerId !== null ? <Redirect push to={`/marketing/providers/${this.state.providerId}`} /> : <span/> }
                        {this.state.groupId !== null ? <Redirect push to={`/marketing/groups/${this.state.groupId}`} /> : <span/> }
                        {this.state.searchTerm ? <Redirect push to={`/marketing/search?q=${encodeURIComponent(this.state.searchTerm)}`} /> : <span/> }
                    </Col>
                </Row>
                <Row>
                    <Col md={8}>
                        <Switch>
                            <Route exact path={['/marketing']}>
                                <DefaultMarketingHomeContent/>
                            </Route>
                            <Route exact path={'/marketing/search'}>
                                <MarketingSearchResults itemSelected={this.itemSelected}/>
                            </Route>
                            <Route path={'/marketing/providers/:providerId'}>
                                <ProviderView/>
                            </Route>
                            <Route path={'/marketing/groups/:groupId'}>
                                <GroupView/>
                            </Route>
                        </Switch>
                    </Col>
                    <Col>
                        <AlertList uri={"/alerts/marketing"} title={"Marketing Alerts"} className={"dashboardSideBox-sm"}/>
                        <AlertList uri={"/alerts/referral"} title={"Referral Alerts"} className={"dashboardSideBox-sm mt-2"}/>
                        <MarketingFutureActivityWidget className={"dashboardSideBox mt-2"}/>
                    </Col>
                </Row>
            </Container>
        );
    }
}

function DefaultMarketingHomeContent() {
    return <Card>
        <Card.Body>
            <h3>Marketing Dashboard</h3>
            <p>To search for a provider or group, enter any part of the name.</p>
            <p>Click on one of the suggestions, or use the down arrow on your keyboard and hit enter
                to make your selection.</p>
            <p>Alternatively, you can click the Go button to have the search results displayed in
                this window.</p>
        </Card.Body>
    </Card>;
}