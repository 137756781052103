import React from 'react';

export const GlobalContext = React.createContext({
    authenticated: false,
    token: null,
    userId: 0,
    hasAnyRole: (roles) => {},
    userDidAuthenticate: (token) => {},
    userDidLogout: () => {},
    apiRequest: () => {},
    cachedGet: () => {},
    toast: (title, message) => {}
});