import React from 'react';
import {GlobalContext} from "../../GlobalContext";
import {Accordion, Card, Col, Row, Table} from "react-bootstrap";
import {SpinnerWrapper} from "../common/SpinnerWrapper";
import {Glyph} from "../common/Glyph";

export class ProviderMonthlyReferralList extends React.Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            clicked: false,
            rows: []
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((!prevState.clicked && this.state.clicked) || (prevProps.providerId !== this.props.providerId)) {
            // First click: Initiate the load
            this.context.apiRequest("GET", `/providers/${this.props.providerId}/referralsAuths?year=${this.props.year}&month=${this.props.month}`)
                .then(d => {
                    if (d.data) {
                        this.setState({rows: d.data, loading: false});
                    }
                });
        }
    }

    render() {
        return (
            <Accordion>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey={this.props.monthName+this.props.year} onClick={() => this.setState({clicked: true})} className={"clickable p-2"}>
                        <Row>
                            <Col xs={6}>{this.props.monthName} {this.props.year}</Col>
                            <Col xs={6} className={"text-right"}>{this.props.referralCount} Referrals In / {this.props.authCount} Auths Out</Col>
                        </Row>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={this.props.monthName+this.props.year}>
                        <Card.Body>
                            <SpinnerWrapper visible={this.state.loading}>
                                <Table striped size={"sm"}>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Date</th>
                                            <th>Status</th>
                                            <th>Patient</th>
                                            <th>CNS Provider</th>
                                            <th>Type</th>
                                            <th>Subtype</th>
                                            <th>Insurance</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.rows.map((r, index) => <tr key={index}>
                                        <td><Glyph name={r.type === 'Referral' ? 'arrow-right-square-fill' : 'arrow-left-square-fill'} variant={r.type === 'Referral' ? 'success' : 'danger'} label={r.type}/></td>
                                        <td>{r.date}</td>
                                        <td>{r.status}</td>
                                        <td>{r.patientLast}, {r.patientFirst}</td>
                                        <td>{r.providerLast ? `${r.providerLast}, ${r.providerFirst}` : ''}</td>
                                        <td>{r.appointmentType}</td>
                                        <td>{r.appointmentSubtype}</td>
                                        <td>{r.insurance}</td>
                                    </tr>)}
                                    </tbody>
                                </Table>
                            </SpinnerWrapper>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        );
    }
}