import React from "react";
import {Col, Form} from "react-bootstrap";
import {ItemModal} from "../common/ItemModal";
import {OptionalSelect} from "../common/OptionalSelect";
import {USER_ICONS} from "../../utils/Enums";
import {Glyph} from "../common/Glyph";

export class UserProfileModal extends ItemModal {
    idField = 'userId';
    itemTypeName = 'My Profile';

    renderFormContents = (l) => {
        let u = this.state.item;
        return <div>
            <Form.Row>
                <Form.Group as={Col} controlId="firstName">
                    <Form.Label size="sm">
                        First Name
                    </Form.Label>
                    <Form.Control required disabled size="sm" name={"firstName"} value={u.firstName} onChange={this.itemFieldChanged}/>
                </Form.Group>
                <Form.Group as={Col} controlId="lastName">
                    <Form.Label size="sm">
                        First Name
                    </Form.Label>
                    <Form.Control required disabled size="sm" name={"lastName"} value={u.lastName} onChange={this.itemFieldChanged}/>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} controlId="email">
                    <Form.Label size="sm">
                        Email Address
                    </Form.Label>
                    <Form.Control type={"email"} size="sm" name={"email"} value={u.email} onChange={this.itemFieldChanged}/>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} controlId="customColor">
                    <Form.Label size="sm">
                        Custom Color
                    </Form.Label>
                    <Form.Control type={"color"} size={"sm"}name={"customColor"} value={u.customColor || ''}
                                  onChange={this.itemFieldChanged}/>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} controlId="customColor" md={10}>
                    <Form.Label size="sm">
                        Icon
                    </Form.Label>
                    <OptionalSelect name={"iconName"} options={USER_ICONS} labelKey={"value"} valueKey={"value"} canSelectNone={true} noneValue={''} noneLabel={'None'} value={u.iconName || ''} onChange={this.itemFieldChanged}/>
                </Form.Group>
                <Col className={"text-right"}>{u.iconName && <Glyph name={u.iconName} style={{fontSize: "3rem"}}/>}</Col>
            </Form.Row>
        </div>;
    }
}