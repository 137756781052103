import React from "react";
import {Col, Modal, Row, Table} from "react-bootstrap";
import {GlobalContext} from "../../GlobalContext";
import {SpinnerWrapper} from "../common/SpinnerWrapper";
import {Glyph} from "../common/Glyph";
import ReactToPrint from "react-to-print";

export class GroupDataSheetModal extends React.Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.state = {
            summary: null,
            loading: true
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.groupId !== this.props.groupId) {
            this.context.apiRequest("GET", `/groups/${this.props.groupId}/summary`)
                .then(d => {
                    if (d.data) {
                        console.debug(d.data);
                        this.setState({summary: d.data, loading: false});
                    }
                });
        }
    }

    render() {
        let s = this.state.summary;
        let refTotals = {r1yMoAvg: 0.0, r30: 0, uts30: 0};
        if (s) {
            for (const prov of s.providers) {
                refTotals.r1yMoAvg += parseFloat(prov.r1yMoAvg);
                refTotals.r30 += parseInt(prov.r30);
                refTotals.uts30 += parseInt(prov.uts30);
            }
        }

        return <Modal size={this.props.size || "xl"} show={this.props.show} onHide={this.props.onCancel}>
            <Modal.Header closeButton>
                <Modal.Title className={"w-100"}>
                    {this.props.title}
                    <ReactToPrint
                        documentTitle={this.props.title}
                        bodyClass={"m-5"}
                        trigger={() => {
                            // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                            // to the root node of the returned component as it will be overwritten.
                            return <Glyph name={"printer"} className={"float-right clickable"} label={"Print"}/>;
                        }}
                        content={() => this.componentRef}
                    />

                </Modal.Title>
            </Modal.Header>
            <Modal.Body ref={el => (this.componentRef = el)}>
                <SpinnerWrapper visible={this.props.loading}>
                    {
                        s && <>
                            <Row className={"d-none d-print-block mb-3"}><Col><h2>{this.props.title}</h2></Col></Row>
                            <Row>
                                <Col md={6}>
                                    <h6>Providers</h6>
                                    <Table striped size={"sm"} className={"mb-5"}>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Specialty</th>
                                                <th className={"text-right pr-2"}>1Y Avg</th>
                                                <th className={"text-right pr-2"}>30 Days</th>
                                                <th className={"text-right pr-2"}>UTS 30 Days</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            s.providers.map((p, i) => <tr key={i}>
                                                <td>{p.lastName}, {p.firstName}</td>
                                                <td>{p.specialty}</td>
                                                <td className={"text-right pr-3"}>{+(Math.round((p.r1yMoAvg || 0) + "e+1")  + "e-1")}</td>
                                                <td className={"text-right pr-3"}>{p.r30 || 0}</td>
                                                <td className={"text-right pr-3"}>{p.uts30 || 0}</td>
                                            </tr>)
                                        }
                                            <tr>
                                                <td className={"font-weight-bold"}>Total</td>
                                                <td></td>
                                                <td className={"font-weight-bold text-right pr-3"}>{+(Math.round((refTotals.r1yMoAvg || 0) + "e+1")  + "e-1")}</td>
                                                <td className={"font-weight-bold text-right pr-3"}>{refTotals.r30 || 0}</td>
                                                <td className={"font-weight-bold text-right pr-3"}>{refTotals.uts30 || 0}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                                <Col>
                                    <h6>Locations</h6>
                                    <Table striped size={"sm"} className={"mb-5"}>
                                        <thead>
                                        <tr>
                                            <th>Address</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            s.locations.map((l, i) => <tr key={i}>
                                                <td>{l.addr1}, {l.city}, {l.state} {l.zip}</td>
                                            </tr>)
                                        }
                                        </tbody>
                                    </Table>
                                    <h6>Staff</h6>
                                    <Table striped size={"sm"} className={"mb-5"}>
                                        <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Position</th>
                                            <th>Notes</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            s.staff.map((s, i) => <tr key={i}>
                                                <td>{s.name}</td>
                                                <td>{s.position}</td>
                                                <td>{s.notes}</td>
                                            </tr>)
                                        }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Row>
                                {s.lastActivity && <Col>
                                    <h6>Last Activity</h6>
                                    <div
                                        className={"font-italic text-secondary"}>{s.lastActivity.timestamp}, {s.lastActivity.authorFirst} {s.lastActivity.authorLast}</div>
                                    <div className={"small"}>{s.lastActivity.notes}</div>
                                </Col>}
                            </Row>
                        </>
                    }
                </SpinnerWrapper>
            </Modal.Body>
        </Modal>
    }}