import React from 'react';
import {ItemModal} from "../ItemModal";
import {GlobalContext} from "../../../GlobalContext";
import {Col, Form} from "react-bootstrap";
import {GroupSearch} from "../GroupSearch";
import {ProviderSearch} from "../ProviderSearch";
import {DateInput} from "../DateInput";
import {shortDatePattern, todayStr} from "../../../utils/Dates";
import {OptionalSelect} from "../OptionalSelect";
import {ICD10Search} from "../ICD10Search";
import {Glyph} from "../Glyph";
import {AuthActivityRow} from "./AuthActivityRow";

export class AuthModal extends ItemModal {
    static contextType = GlobalContext;

    idField = 'authorizationId';
    itemTypeName = 'Authorization';
    
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            testTypes: [],
            testSubtypes: [],
            insurances: [],
            specialties: [],
            icd10ToAdd: null,
            tempActivities: []
        };
    };

    becameVisible = () => {
        this.context.cachedGet(`/lookup/test_type?orderBy=testType`, 300)
            .then(d => this.setState({testTypes: d.data}));
        this.context.cachedGet(`/lookup/subspecialty?filter=${encodeURIComponent('active=1')}&orderBy=subspecialty`, 300)
            .then(d => this.setState({testSubtypes: d.data}));
        this.context.cachedGet(`/lookup/specialty?orderBy=specialty`, 300)
            .then(d => this.setState({specialties: d.data}));
        this.context.cachedGet(`/lookup/insurance?filter=${encodeURIComponent('active=1')}&orderBy=carrierName`, 300)
            .then(d => this.setState({insurances: d.data}));

        let acts = this.state.item.activities || [];
        // Clone the activities array so we can manipulate them freely before saving
        let tempActs = JSON.parse(JSON.stringify(acts));
        this.setState({tempActivities: tempActs})

    };

    fromProviderSelected = (providerId) => {
        this.setItemField('fromProviderId', providerId);
    }
    toProviderSelected = (providerId) => {
        this.setItemField('toProviderId', providerId);
    }
    toGroupSelected = (groupId) => {
        this.setItemField('toGroupId', groupId);
    }
    icd10Selected = (code, description) => {
        let diags = this.state.item.diagnoses;
        diags.push({code, description});

        this.setState(prvState => ({
            item: {
                ...prvState.item,
                diagnoses: diags
            }
        }));
    }

    newActivity = () => {
        let auth = this.state.item;
        return {
            authorizationId: auth.authorizationId,
            authActivityId: 0,
            activityDate: todayStr(),
            statusCode: "",
            activityTypeCode: "",
            editing: true
        };
    }

    addActivity = () => {
        let acts = this.state.tempActivities;
        let newAct = this.newActivity();
        // Give it a dummy negative id so we can identify it later in callbacks
        newAct.authActivityId = (acts.length + 1) * -1;
        acts.push(newAct);
        this.setState({tempActivities: acts});
    };

    cancelEditActivity = (id) => {
        let acts = this.state.tempActivities;
        let index = acts.findIndex(a => a.authActivityId === id);
        if (id < 0) {
            acts.splice(index, 1);
        } else {
            // Replace with the original
            let originalAct = this.state.item.activities.find(a => a.authActivityId === id);
            acts[index] = originalAct;
        }
        this.setState({tempActivities: acts});
    };

    activityFieldUpdated = (id, fieldName, value) => {
        let acts = this.state.tempActivities;
        let act = acts.find(a => a.authActivityId === id);
        if (act) {
            act[fieldName] = value;
        }
    };

    /**
     * Override me
     * @returns {boolean}
     */
    doCustomValidation = () => {
        this.state.item.activities = this.state.tempActivities;
        return true;
    };

    renderFormContents = (a) => {
        return <div>
            <Form.Row>
                <Col xs={5}>
                    <Form.Group controlId="toGroupSearch">
                        <Form.Label size="sm">To Group:</Form.Label>
                        <GroupSearch internal={false} id={"toGroupSearch"} size={"sm"} groupSelected={this.toGroupSelected} initialValue={a.toGroupName || ''} placeholder={'Search Groups'}/>
                    </Form.Group>
                </Col>
                <Col xs={5}>
                    <Form.Group controlId="toProviderSearch">
                        <Form.Label size="sm">To Provider:</Form.Label>
                        <ProviderSearch internal={false} id={"toProviderSearch"} size={"sm"} providerSelected={this.toProviderSelected} initialValue={a.toProviderLast ? `${a.toProviderLast}, ${a.toProviderFirst}` : ''}/>
                    </Form.Group>
                </Col>
            </Form.Row>
            <Form.Row>
                <Col xs={5}>
                    <Form.Group controlId="referralOutDate">
                        <Form.Label size="sm">Date:</Form.Label>
                        <DateInput name={"referralOutDate"} value={a.referralOutDate} pattern={shortDatePattern} placeholder={"M/D/YYYY"} onChange={this.itemFieldChanged} size={"sm"}/>
                        <Form.Control.Feedback type={"invalid"}>M/D/YYYY e.g 2/28/2021</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col xs={5}>
                    <Form.Group controlId="fromProviderSearch">
                        <Form.Label size="sm">Ordered By:</Form.Label>
                        <ProviderSearch internal={false} id={"fromProviderSearch"} size={"sm"} providerSelected={this.fromProviderSelected} initialValue={a.fromProviderLast ? `${a.fromProviderLast}, ${a.fromProviderFirst}` : ''}/>
                    </Form.Group>
                </Col>
            </Form.Row>
            <Form.Row>
                <Col xs={4}>
                    <Form.Group controlId="testCode">
                        <Form.Label size="sm">Test Type:</Form.Label>
                        <OptionalSelect name={"testCode"} options={this.state.testTypes} valueKey={"testCode"}
                                        labelKey={"testType"} value={a.testCode || ''} canSelectNone={false} onChange={this.itemFieldChanged}/>
                    </Form.Group>
                </Col>
                <Col xs={4}>
                    <Form.Group controlId="testSubtypeCode">
                        <Form.Label size="sm">Test Sub-type:</Form.Label>
                        <OptionalSelect name={"testSubtypeCode"} options={this.state.testSubtypes} valueKey={"subspecialtyCode"}
                                        labelKey={"subspecialty"} value={a.testSubtypeCode || ''} canSelectNone={true} onChange={this.itemFieldChanged}/>
                    </Form.Group>
                </Col>
                <Col xs={4}>
                    <Form.Group controlId="specialtyCode">
                        <Form.Label size="sm">Specialty:</Form.Label>
                        <OptionalSelect name={"specialtyCode"} options={this.state.specialties} valueKey={"specialtyCode"}
                                        labelKey={"specialty"} value={a.specialtyCode || ''} canSelectNone={true} onChange={this.itemFieldChanged}/>
                    </Form.Group>
                </Col>
            </Form.Row>
            <Form.Row>
                <Col xs={6}>
                    <Form.Group controlId="icd10Name">
                        <Form.Label size="sm">ICD10s:</Form.Label>
                        <ICD10Search id={"icd10Name"} size={"sm"} icd10Selected={this.icd10Selected} initialValue={''} className={"mb-2"}/>
                        { a.diagnoses.map(d => <div key={d.code} className={'small'}>{d.code}: {d.description}</div>) }
                    </Form.Group>
                </Col>
                <Col xs={6}>
                    <Form.Group controlId="primaryInsuranceId">
                        <Form.Label size="sm">Insurance:</Form.Label>
                        <OptionalSelect name={"primaryInsuranceId"} options={this.state.insurances} valueKey={"insuranceId"}
                                        labelKey={"carrierName"} value={a.primaryInsuranceId || ''} canSelectNone={true} onChange={this.itemFieldChanged}/>
                    </Form.Group>
                </Col>
            </Form.Row>
            <Form.Row>
                <Col xs={12}>
                    <Form.Group>
                        <Form.Row>
                            <Col xs={12}>
                                <Form.Label>Activity:</Form.Label>
                                { a.authorizationId > 0 ? <Glyph name={"plus-circle"} className={"clickable ml-2"} onClick={this.addActivity}/> : <span/> }
                            </Col>
                        </Form.Row>
                        {
                            this.state.tempActivities.map(act => <AuthActivityRow key={act.authActivityId} activity={act}
                                                         editing={act.editing}
                                                         onCancelEdit={this.cancelEditActivity}
                                                         fieldUpdated={this.activityFieldUpdated}/>)
                        }
                    </Form.Group>
                </Col>
            </Form.Row>
            <Form.Row>
                <Col xs={12}>
                    <Form.Group controlId="notes">
                        <Form.Label>Notes:</Form.Label>
                        <Form.Control name={"notes"} as="textarea" size={"sm"} rows={3} value={a.notes || ''} onChange={this.itemFieldChanged}/>
                    </Form.Group>
                </Col>
            </Form.Row>
        </div>
    }

}