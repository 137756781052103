import React from "react";
import {Spinner} from "react-bootstrap";

export class SpinnerWrapper extends React.Component {

    render() {
        return (
            this.props.visible ? <div style={{verticalAlign: 'middle', textAlign: 'center', height: '100%', width: '100%'}}>
                <Spinner animation={"border"} size={this.props.size || "lg"}/>
            </div> : this.props.children
        );
    }
}