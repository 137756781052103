import React from "react";
import {ItemList} from "../ItemList";
import moment from "moment-timezone";
import {PatientOrderModal} from "./PatientOrderModal";

export class PatientOrderList extends ItemList {

    idField = 'id';
    updateProperty = 'patientId';
    itemTypeName = 'Order';
    newItem = () => {
        return {
            id: 0,
            patientId: this.props.patientId,
            assignedTo: 'AUTHORIZATIONS DPT.',
            dueDate: moment().format('M/D/YYYY'),
            taskType: 'AUTH',
            createdByUserId: null,
            createdBy: null,
            taskDescription: 'Authorization',
            notes: []
        };
    };

    headerColumns = ['Created', 'Due Date', 'Type', 'Ordered By', 'Notes'];
    headerColumnClasses = ['text-center', 'text-center', '', '', ''];
    dataColumnClasses = ['text-center', 'text-center', '', '', 'w-50'];
    addSupported = true;
    editSupported = true;
    deleteSupported = false;
    paging = false;
    modalClass = PatientOrderModal;

    getBaseUrl = () => {
        return `/patients/${this.props.patientId}/orders`;
    };

    /**
     * @param r
     * @returns the contents of the table cells for a particular item.
     */
    getItemCellContents = (o) => {
        return [
            moment(o.createdDate).format('M/D/YYYY'),
            moment(o.dueDate).format('M/D/YYYY'),
            o.taskDescription,
            o.createdBy,
            o.notes && o.notes.map((n,i) => <span key={i}>{n.text}<br/></span>)
        ];
    };
}