import React from "react";
import {Button, Col, Form, InputGroup} from "react-bootstrap";
import {ItemModal} from "../common/ItemModal";
import {OptionalSelect} from "../common/OptionalSelect";
import {generateKVPairs, LienTaskResponseStatus, LienTaskStatus} from "../../utils/Enums";
import LienNotesList from "./LienNotesList";
import {DateInput} from "../common/DateInput";
import {Glyph} from "../common/Glyph";
import moment from "moment-timezone";

export class LienTaskModal extends ItemModal {

    componentDidMount() {
        this.setState({snoozeDays: 7});
    }

    idField = 'id';
    itemTypeName = 'Task';

    incrementSnoozeDays = () => {
        this.updateSnoozeDays(this.state.snoozeDays+1)
    }

    decrementSnoozeDays = () => {
        if (this.state.snoozeDays > 1) {
            this.updateSnoozeDays(this.state.snoozeDays-1)
        }
    }

    snooze = () => {
        let sd = this.state.snoozeDays;
        if (sd !== '' && !isNaN(sd)) {
            let newDueDate = moment();
            newDueDate.add(sd, 'days');
            this.setItemField('dueDate', newDueDate.format('MM/DD/YY'), this.ok);
        }
    }

    updateSnoozeDays = (val) => {
        console.log(val);
        if (val === '' || !isNaN(val)) {
            this.setState({snoozeDays: val === '' ? '' : Number(val)});
        }
    }

    renderFormContents = (t) => {
        return <div>
            <h6>{t.title}</h6>
            <Form.Row>
                <Form.Group as={Col} controlId="description">
                    <Form.Label size="sm">
                        Description
                    </Form.Label>
                    <Form.Control as={"textarea"} size="sm" name={"description"} value={t.description || ''}
                                  onChange={this.itemFieldChanged}/>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} controlId="status">
                    <Form.Label size="sm">
                        Sent/Requested
                    </Form.Label>
                    <OptionalSelect required={true} name={"status"} value={t.status} options={generateKVPairs(LienTaskStatus)} valueKey={"value"} labelKey={"label"} onChange={this.itemFieldChanged}/>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} controlId="responseStatus">
                    <Form.Label size="sm">
                        Response
                    </Form.Label>
                    <OptionalSelect required={true} name={"responseStatus"} value={t.responseStatus} options={generateKVPairs(LienTaskResponseStatus)} valueKey={"value"} labelKey={"label"} onChange={this.itemFieldChanged}/>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} xs={3} controlId="dueDate">
                    <Form.Label size="sm">Due Date</Form.Label>
                    <DateInput size="sm" name={"dueDate"} value={t.dueDate} onChange={this.itemFieldChanged}
                               pattern={"^(0?[1-9]|1[0-2])\\/(0?[1-9]|1\\d|2\\d|3[01])\\/\\d{2}$"}
                               placeholder={"mm/dd/yy"}/>
                </Form.Group>
                <Col xs={4}></Col>
                {t.status === "PENDING" && <Form.Group as={Col} xs={5} controlId="snoozeDays">
                    <Form.Label size="sm">Snooze:</Form.Label>
                    <InputGroup size={"sm"}>
                        <InputGroup.Prepend>
                            <Button variant="outline-secondary" onClick={this.decrementSnoozeDays}><Glyph name={"dash"}/></Button>
                            <Button variant="outline-secondary" onClick={this.incrementSnoozeDays}><Glyph name={"plus"}/></Button>
                        </InputGroup.Prepend>
                        <Form.Control
                            placeholder="Days"
                            value={this.state.snoozeDays}
                            onChange={e => this.updateSnoozeDays(e.target.value)}
                        />
                        <InputGroup.Append>
                            <InputGroup.Text>days</InputGroup.Text>
                            <Button variant="outline-secondary" onClick={this.snooze}><Glyph name={"alarm-fill"}/></Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Form.Group>}
            </Form.Row>
            <h6 className={"mt-3"}>Comments</h6>
            <LienNotesList lienId={t.caseId} taskId={t.id}/>
        </div>;
    };
}