import React from 'react';
import {GlobalContext} from "../../GlobalContext";
import {Button, Card, Col, Form, Row, Tab, Tabs} from "react-bootstrap";
import {withRouter} from 'react-router-dom';
import {OptionalSelect} from "../common/OptionalSelect";
import {Glyph} from "../common/Glyph";
import {GroupProviderList} from "./GroupProviderList";
import {GroupLocationList} from "./GroupLocationList";
import {GroupStaffList} from "./GroupStaffList";
import {GroupAssignmentList} from "./GroupAssignmentList";
import {GroupActivitiesList} from "./GroupActivitiesList";
import {GroupDataSheetModal} from "./GroupDataSheetModal";
import moment from "moment-timezone";

class GroupView extends React.Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.state = {
            editing: false,
            loading: false,
            group: null,
            simpleStats: null,
            validated: false,
            providerSearchModalVisible: false,
            groupTypes: [],
            specialties: [],
            showDataSheet: false
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let groupId = this.props.match.params.groupId;
        let prevGroupId = prevProps.match.params.groupId;
        if (!this.state.loading && prevGroupId !== groupId) {
            this.loadGroup();
        }
    }

    componentDidMount() {
        this.loadGroup();
        this.context.cachedGet(`/lookup/group_type`, 300)
            .then(d => this.setState({groupTypes: d.data}));
        this.context.cachedGet(`/lookup/specialty?filter=${encodeURIComponent('active=1')}`, 300)
            .then(d => this.setState({specialties: d.data}));
    }

    loadGroup = () => {
        let groupId = this.props.match.params.groupId;
        this.setState({group: null, simpleStats: null, activity: [], activityLoading: true});
        if (groupId > 0) {
            this.context.apiRequest("GET", `/groups/${groupId}?stats=true`)
                .then(d => {
                    if (d.data && d.data.groupId) {
                        this.setState({group: d.data, loading: false, validated: false, editing: false});
                    }
                });
            this.context.apiRequest("GET", `/groups/${groupId}/simpleStats`)
                .then(d => {
                    if (d.data) {
                        this.setState({simpleStats: d.data});
                    }
                });
        } else {
            this.setState({group: this.emptyGroup(), loading: false, validated: false, editing: true});
        }
    };

    emptyGroup = () => {
        return {
            groupId: 0,
            name: '',
            groupSpecialtyCode: null,
            phone: '',
            fax: '',
            website: '',
            internal: 0,
            notes: '',
            active: 1,
            groupTypeId: null,
            locations: [],
            staff: [],
            providers: [],
            assignments: []
        }
    }

    edit = () => {
        this.setState({editing: true});
    };

    cancelEdit = () => {
        if (this.state.group.groupId === 0) {
            // We were creating a new provider, revert back to marketing home
            this.props.history.push("/marketing");
        } else {
            this.loadGroup();
            this.setState({editing: false});
        }
    };

    save = (e) => {
        if (e) e.preventDefault();
        if (!this.validateForm()) {
            return;
        }
        let g = this.state.group;
        this.context.apiRequest("POST", `/groups/${g.groupId}`, g)
            .then(d => {
                if (d.data && d.data.groupId) {
                    this.setState({group: d.data, loading: false, editing: false, validated: false});
                }
            });
    };

    validateForm = () => {
        let valid = true;
        let form = document.getElementById('group-form');
        valid &= form.checkValidity();
        this.setState({validated: true});
        return valid;
    };

    groupFieldChanged = (e, overrideValue) => {
        let fieldName = e.target.name;
        let value = overrideValue === undefined ? e.target.value : overrideValue;
        this.setState(prvState => ({
            group: {
                ...prvState.group,
                [fieldName]: value
            }
        }))
    };

    loadSubList = (path, dataField, loadingFlagField, force) => {
        if(force || this.state[loadingFlagField]) {
            this.setState({[loadingFlagField]: true})
            let groupId = this.props.match.params.groupId;
            this.context.apiRequest('GET', `/groups/${groupId}/${path}`)
                .then(d => {
                    if (d.data) {
                        this.setState({[dataField]: d.data});
                    }
                })
                .finally(() => this.setState({[loadingFlagField]: false}));
        }
    };

    render() {
        let g = this.state.group;
        let ss = this.state.simpleStats;
        return (
            <Card>
                <Card.Body>
                    <Card.Title>
                        <Row>
                            <Col xs={6}>
                                <h4><Glyph name={"people-fill"} className={"mr-3"}/>{g ? `${g.name}` : `Loading...`}</h4>
                            </Col>
                            <Col className={"text-right"}>
                                {ss && <span className={"small text-secondary mr-3"}>3 mo: {ss.referralsIn3Mo} in, {ss.referralsOut3Mo} out</span>}
                                <Button variant={"dark"} className={"mr-2"} onClick={() => this.setState({showDataSheet: true})}><Glyph name={"clipboard-data"} label={"Data Sheet"}/></Button>
                                <GroupDataSheetModal groupId={g ? g.groupId : 0} title={`Data Sheet for ${g ? g.name : ''} ${moment().format("M/D/YYYY")}`} show={this.state.showDataSheet} onCancel={() => this.setState({showDataSheet: false})}/>
                                {this.state.editing ?
                                    <Button variant={"secondary"} onClick={this.cancelEdit}>Cancel</Button> : <span/>}
                                {this.state.editing ?
                                    <Button variant={"dark"} className={"ml-2"} onClick={this.save}>Save</Button> :
                                    <span/>}
                                {this.state.editing ? <span/> :
                                    <Button variant={"dark"} onClick={this.edit}>Edit</Button>}
                            </Col>
                        </Row>
                    </Card.Title>
                    {g ? (
                        <div>
                            <Form id={"group-form"} noValidate validated={this.state.validated} onSubmit={this.save}>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="name">
                                        <Form.Label size="sm">
                                            Name
                                        </Form.Label>
                                        <Form.Control required size="sm" name={"name"} value={g.name}
                                                      onChange={this.groupFieldChanged}
                                                      disabled={!this.state.editing}/>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="groupTypeId">
                                        <Form.Label size="sm">
                                            Group Type
                                        </Form.Label>
                                        <OptionalSelect name={"groupTypeId"} value={g.groupTypeId} options={this.state.groupTypes} valueKey={"groupTypeId"} labelKey={"name"} onChange={this.groupFieldChanged} noneLabel={"Select a type"} disabled={!this.state.editing}/>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="specialtyCode">
                                        <Form.Label size="sm">
                                            Specialty
                                        </Form.Label>
                                        <OptionalSelect name={"groupSpecialtyCode"} value={g.groupSpecialtyCode} options={this.state.specialties} valueKey={"specialtyCode"} labelKey={"specialty"} onChange={this.groupFieldChanged} noneLabel={"Select a specialty"} disabled={!this.state.editing}/>
                                    </Form.Group>
                                </Form.Row>
                                
                                <Form.Row>
                                    <Form.Group as={Col} controlId="active" xs={1}>
                                        <Form.Label size="sm">
                                            Active
                                        </Form.Label>
                                        <Form.Check
                                            type={"switch"}
                                            id={"active"}
                                            name={"active"}
                                            aria-label={"Active"}
                                            checked={g.active === 1}
                                            disabled={!this.state.editing}
                                            onChange={e => this.groupFieldChanged(e, e.target.checked ? 1 : 0)}/>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="phone" md={2}>
                                        <Form.Label size="sm">
                                            Phone
                                        </Form.Label>
                                        <Form.Control size="sm" name={"phone"} value={g.phone || ""}
                                                      onChange={this.groupFieldChanged}
                                                      disabled={!this.state.editing}/>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="fax" md={2}>
                                        <Form.Label size="sm">
                                            Fax
                                        </Form.Label>
                                        <Form.Control size="sm" name={"fax"} value={g.fax || ""}
                                                      onChange={this.groupFieldChanged}
                                                      disabled={!this.state.editing}/>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="website" md={3}>
                                        <Form.Label size="sm">
                                            Website
                                        </Form.Label>
                                        <Form.Control size="sm" name={"website"} value={g.website || ""}
                                                      onChange={this.groupFieldChanged}
                                                      disabled={!this.state.editing}/>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="notes">
                                        <Form.Label size="sm">
                                            Notes
                                        </Form.Label>
                                        <Form.Control
                                            name={"notes"}
                                            as="textarea"
                                            size={"sm"}
                                            style={{ height: '75px' }}
                                            onChange={this.groupFieldChanged}
                                            value={g.notes || ''}
                                            disabled={!this.state.editing}
                                        />
                                    </Form.Group>
                                </Form.Row>
                            </Form>
                        </div>
                    ) : <span/>}
                    {g && g.groupId > 0 ? <Tabs id="groupTabs" defaultActiveKey={"providers"} className={"mt-3"}>
                        <Tab eventKey="providers" title="Providers">
                            <GroupProviderList groupId={g.groupId}/>
                        </Tab>
                        <Tab eventKey="locations" title="Locations">
                            <GroupLocationList groupId={g.groupId}/>
                        </Tab>
                        <Tab eventKey="staff" title="Staff">
                            <GroupStaffList groupId={g.groupId}/>
                        </Tab>
                        {this.context.hasAnyRole(["System Admin"]) ?
                            <Tab eventKey="assignments" title="Assignments">
                                <GroupAssignmentList groupId={g.groupId}/>
                            </Tab> : <span/>
                        }
                        <Tab eventKey="activities" title="Activity">
                            <GroupActivitiesList groupId={g.groupId} modalSize={"lg"}/>
                        </Tab>
                    </Tabs> : <span/>}
                </Card.Body>
            </Card>
        );
    }
}

export default withRouter(GroupView);