import {StaffModal} from "../common/StaffModal";
import {ItemList} from "../common/ItemList";

export class GroupStaffList extends ItemList {

    baseUrl = `/groups/${this.props.groupId}/staff`;
    idField = 'staffId';
    itemTypeName = 'Staff';
    newItem = () => {
        return {
            staffId: 0,
            name: '',
            position: '',
            notes: '',
            active: 1,
            phone: ''
        };
    };
    headerColumns = ['Name', 'Position', 'Phone', 'Notes'];
    headerColumnClasses = null;
    dataColumnClasses = null;
    addSupported = true;
    editSupported = true;
    deleteSupported = true;
    paging = false;
    modalClass = StaffModal;

    /**
     * @param item
     * @returns the contents of the table cells for a particular item.
     */
    getItemCellContents = (item) => {
        return [item.name, item.position, item.phone, item.notes];
    };

}