import React from "react";
import {Glyph} from "../Glyph";
import {formatDate, todayStr} from "../../../utils/Dates";
import {AuthModal} from "./AuthModal";
import {ItemList} from "../ItemList";

export class PatientAuthList extends ItemList {

    idField = 'authorizationId';
    updateProperty = 'patientId';
    itemTypeName = 'Authorization';
    newItem = () => {
        return {
            authorizationId: 0,
            patientId: this.props.patientId,
            referralOutDate: todayStr(),
            fromProviderId: null,
            fromProviderFirst: null,
            fromProviderLast: null,
            toGroupId: null,
            toProviderId: null,
            toGroupName: null,
            toProviderFirst: null,
            toProviderLast: null,
            testCode: null,
            testType: null,
            testSubtypeCode: null,
            testSubtype: null,
            priorAuthReceived: 0,
            unableToAuthorize: 0,
            notes: null,
            primaryInsuranceId: null,
            specialtyCode: null,
            specialty: null,
            diagnoses: [],
            activities: []
        };
    };

    headerColumns = ['Status', 'Ordered On', 'Procedure', 'Subtype', 'Ordered By'];
    headerColumnClasses = ['text-center', 'text-center', '', '', ''];
    dataColumnClasses = ['text-center', 'text-center', '', '', ''];
    addSupported = true;
    editSupported = true;
    deleteSupported = false;
    paging = false;
    modalClass = AuthModal;

    getBaseUrl = () => {
        return `/patients/${this.props.patientId}/authorizations`;
    };

    /**
     * @param r
     * @returns the contents of the table cells for a particular item.
     */
    getItemCellContents = (a) => {
        let glyphName = "clock";
        let glyphVariant = "primary";
        let glyphLabel = "Pending";
        if(a.priorAuthReceived === 1) {
            glyphName = "check-circle";
            glyphVariant = "success";
            glyphLabel = "Prior Auth Received";
        } else if(a.unableToAuthorize === 1) {
            glyphName = "x-circle";
            glyphVariant = "error";
            glyphLabel = "Unable To Authorize";
        }

        return [
            <Glyph className={"mr-2"} name={glyphName} variant={glyphVariant} label={glyphLabel}/>,
            formatDate(a.referralOutDate),
            a.testType,
            a.testSubtype,
            `${a.fromProviderLast}, ${a.fromProviderFirst}`
        ];
    };












    // addAuth = () => {
    //     this.setState({newAuth: this.blankAuth()});
    //     this.setState({modalVisible: true});
    // };
    //
    // blankAuth = () => {
    //     return {
    //         authorizationId: 0,
    //         patientId: this.props.patientId,
    //         referralOutDate: todayStr(),
    //         fromProviderId: null,
    //         fromProviderFirst: null,
    //         fromProviderLast: null,
    //         toGroupId: null,
    //         toProviderId: null,
    //         toGroupName: null,
    //         toProviderFirst: null,
    //         toProviderLast: null,
    //         testCode: null,
    //         testType: null,
    //         testSubtypeCode: null,
    //         testSubtype: null,
    //         priorAuthReceived: 0,
    //         unableToAuthorize: 0,
    //         notes: null,
    //         primaryInsuranceId: null,
    //         specialtyCode: null,
    //         specialty: null,
    //         diagnoses: [],
    //         activities: []
    //     };
    // }
    //
    // render() {
    //     return <SpinnerWrapper visible={this.props.loading}>
    //         <AuthView show={this.state.modalVisible} onHide={() => this.setState({modalVisible: false})}
    //                   auth={this.state.newAuth} triggerReload={this.props.triggerReload}/>
    //         <Table striped hover>
    //             <thead>
    //                 <tr>
    //                     <th className={"text-center"}>Status</th>
    //                     <th>Ordered On</th>
    //                     <th>Procedure</th>
    //                     <th>Subtype</th>
    //                     <th>Ordered By</th>
    //                     <th className={"text-right"}><Glyph name={"plus-circle"} className={"clickable ml-2"} onClick={this.addAuth}/></th>
    //                 </tr>
    //             </thead>
    //             <tbody>
    //             {
    //                 this.props.auths.map(a => <PatientAuthListRow key={a.authorizationId} auth={a} triggerReload={this.props.triggerReload}/>)
    //             }
    //             </tbody>
    //         </Table>
    //     </SpinnerWrapper>
    // }
}