import {ProviderSearchModal} from "../common/ProviderSearchModal";
import {ItemList} from "../common/ItemList";
import {Link} from "react-router-dom";
import React from "react";

export class GroupProviderList extends ItemList {

    baseUrl = `/groups/${this.props.groupId}/providers`;
    idField = 'providerId';
    itemTypeName = 'Provider';
    newItem = () => {
        return {
            providerId: 0,
            name: ''
        };
    };
    headerColumns = ['Name, Specialty'];
    headerColumnClasses = null;
    dataColumnClasses = null;
    addSupported = true;
    editSupported = false;
    deleteSupported = true;
    paging = false;
    modalClass = ProviderSearchModal;

    /**
     * @param item
     * @returns the contents of the table cells for a particular item.
     */
    getItemCellContents = (item) => {
        return [<Link className="text-dark" replace={true} to={`/marketing/providers/${item.providerId}`}>{item.name}</Link>];
    };

    getDeleteConfirmTitle = () => {
        return 'Confirm';
    };

    getDeleteConfirmPrompt = () => {
        return 'Remove this provider from the group?'
    };
}