import React from 'react';
import {GlobalContext} from "../../GlobalContext";
import {withRouter} from "react-router-dom";

class ReportView extends React.Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.state = {
            reportUrl: null
        }
    }

    componentDidMount() {
        let fileName = this.props.match.params.fileName;
        let params = new URLSearchParams(window.location.search).toString();
        this.context.apiRequest("GET", `/reports/url?fileName=${fileName}${params ? '&'+params : ''}`)
            .then(d => {
                if (d && d.data) {
                    window.location.replace(d.data);
                }
            })
    }

    render() {
        return <span/>
    }
}

export default withRouter(ReportView);