import React from "react";
import {Glyph} from "./Glyph";
import DatePicker from "react-datepicker";

export default class CalendarButton extends React.Component {
    render() {
        const CalendarButton = React.forwardRef(({ value, onClick }, ref) => (
            <Glyph className={"clickable"} name={"calendar-date"} onClick={onClick} ref={ref}/>
        ));
        return <span style={{display: 'inline-block', width: '20px'}} className={this.props.className}>
            <DatePicker customInput={<CalendarButton/>} selected={this.props.selected} onChange={this.props.onChange} />
        </span>;
    }
}