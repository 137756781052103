import {GlobalContext} from "../../GlobalContext";
import {Button, Form, Modal} from "react-bootstrap";
import React from "react";
import {OptionalSelect} from "./OptionalSelect";

export default class SelectUserModal extends React.Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.state = {
            users: [],
            selectedUserId: null
        };
    }

    componentDidMount() {
        let activeOnly = true;
        if (this.props.activeOnly !== undefined) activeOnly = this.props.activeOnly;
        this.context.apiRequest('GET', `/users?activeOnly=${activeOnly}${this.props.role ? `&role=${this.props.role}` : ''}${this.props.department ? `&department=${encodeURIComponent(this.props.department)}` : ``}`)
            .then(resp => {
                if (resp && resp.data) {
                    this.setState({users: resp.data.sort((a,b) => a.firstName.localeCompare(b.firstName))});
                }
            })
    }

    userSelected = (e) => {
        this.setState({selectedUserId: Number.parseInt(e.target.value)});
    }

    ok = () => {
        let user = this.state.users.find(u => u.userId === this.state.selectedUserId);
        this.props.onOk(user);
    }

    render() {
        return <Modal size={this.props.size || "md"} show={this.props.show} onHide={this.props.onCancel}>
            <Modal.Header closeButton>
                <Modal.Title>{this.props.title || 'Select User'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <OptionalSelect options={this.state.users}
                                    labelKey={'fullName'} valueKey={'userId'}
                                    canSelectNone={false} noneValue={''} noneLabel={'Select a user'}
                                    value={this.state.selectedUserId} onChange={this.userSelected}/>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={this.props.onCancel}>
                    Cancel
                </Button>
                <Button variant="dark" onClick={this.ok} disabled={this.state.selectedUserId == null}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    }
}