import React from "react";
import {Col, Form} from "react-bootstrap";
import {ItemModal} from "./ItemModal";

export class StaffModal extends ItemModal {

    idField = 'staffId';
    itemTypeName = 'Staff';

    renderFormContents = (s) => {
        return <div>
            <Form.Row>
                <Form.Group as={Col} controlId="name">
                    <Form.Label size="sm">
                        Name
                    </Form.Label>
                    <Form.Control required size="sm" name={"name"} value={s.name}
                                  onChange={this.itemFieldChanged}/>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} controlId="position">
                    <Form.Label size="sm">
                        Position
                    </Form.Label>
                    <Form.Control size="sm" name={"position"} value={s.position}
                                  onChange={this.itemFieldChanged}/>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} controlId="notes" xs={12}>
                    <Form.Label size="sm">
                        Notes
                    </Form.Label>
                    <Form.Control size="sm" name={"notes"} value={s.notes}
                                  onChange={this.itemFieldChanged}/>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} controlId="active" xs={4}>
                    <Form.Label size="sm">
                        Active
                    </Form.Label>
                    <Form.Check
                        type={"switch"}
                        id={"staff-active"}
                        name={"active"}
                        aria-label={"Active"}
                        checked={s.active === 1}
                        onChange={e => this.itemFieldChanged(e, e.target.checked ? 1 : 0)}/>
                </Form.Group>
                <Form.Group as={Col} controlId="phone" xs={8}>
                    <Form.Label size="sm">
                        Phone
                    </Form.Label>
                    <Form.Control size="sm" name={"phone"} value={s.phone}
                                  onChange={this.itemFieldChanged}/>
                </Form.Group>
            </Form.Row>
        </div>;
    };
}