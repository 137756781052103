import React from "react";
import {GlobalContext} from "../../GlobalContext";
import {Button, Col, Form, Table} from "react-bootstrap";
import {OptionalSelect} from "../common/OptionalSelect";
import {Glyph} from "../common/Glyph";

export class AdminSocExceptionForm extends React.Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);

        this.state = {
            testCode: '',
            insuranceId: '',
            exceptions: [],
            insurances: [],
            testTypes: []
        };
    }

    componentDidMount() {
        this.getExceptions();
        this.context.cachedGet(`/lookup/insurance?filter=${encodeURIComponent('active=1')}&orderBy=carrierName`, 300)
            .then(d => this.setState({insurances: d.data}));
        this.context.cachedGet(`/lookup/test_type?orderBy=testType`, 300)
            .then(d => this.setState({testTypes: d.data}));
    }

    getExceptions = () => {
        this.context.apiRequest("GET", "/soc/exceptions")
            .then(d => {
                if (d && d.data) {
                    this.setState({exceptions: d.data});
                }
            });
    };

    deleteException = (id) => {
        this.context.apiRequest("DELETE", "/soc/exceptions", {id})
            .then(this.getExceptions);
    };

    fieldChanged = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    ok = () => {
        if (this.state.insuranceId && this.state.testCode) {
            this.context.apiRequest("POST", "/soc/exceptions", {testCode: this.state.testCode, insuranceId: this.state.insuranceId})
                .then(d => {
                    if (d && d.data) {
                        this.setState({results: d.data});
                    }
                }).then(this.getExceptions);
        }
    };

    render() {
        return <Form id={"soc-test-form"}>
            <Form.Row className={"text-bold"}>Add an exception:</Form.Row>
            <Form.Row>
                <Form.Group as={Col} controlId="diagnosisText" md={3}>
                    <Form.Label size="sm">
                        Insurance
                    </Form.Label>
                    <OptionalSelect name={"insuranceId"} options={this.state.insurances} valueKey={"insuranceId"} labelKey={"carrierName"} canSelectNone={false} value={this.state.insuranceId} onChange={this.fieldChanged}/>
                </Form.Group>
                <Form.Group as={Col} controlId="icds" md={3}>
                    <Form.Label size="sm">
                        Test Type
                    </Form.Label>
                    <OptionalSelect name={"testCode"} options={this.state.testTypes} valueKey={"testCode"} labelKey={"testType"} canSelectNone={false} value={this.state.testCode} onChange={this.fieldChanged}/>
                </Form.Group>
                <Form.Group as={Col} md={2}>
                    <Button variant={"dark"} onClick={this.ok}>OK</Button>
                </Form.Group>
            </Form.Row>
            <Form.Row className={"mt-3"}>
                <Col md={8}>
                    <Table size={"sm"} striped>
                        <thead>
                            <tr>
                                <th>Insurance</th>
                                <th>Test Type</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.exceptions.map(e => <tr key={e.id}>
                                <td>{e.insurance.carrierName}</td>
                                <td>{e.testType.testType}</td>
                                <td className={"text-right clickable"}><Glyph name={"x"} onClick={() => this.deleteException(e.id)}/></td>
                            </tr>)
                        }
                        </tbody>
                    </Table>

                </Col>
            </Form.Row>
        </Form>
    }
}