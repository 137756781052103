import React from 'react';
import {GlobalContext} from "../../GlobalContext";
import {AsyncTypeahead} from "react-bootstrap-typeahead";

export class GroupSearch extends React.Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.state = {
            groupId: null,
            groupName: null,
            loading: false,
            options: []
        };
    }

    doSearch = (query) => {
        this.context.apiRequest("GET", "/groups/search?q="+encodeURIComponent(query))
            .then(d => {
                if(d.data) this.setState({options: d.data});
                this.setState({loading: false});
            })
    }

    selected = (selectionArray) => {
        if(selectionArray && selectionArray.length > 0) {
            if(this.props.groupSelected) {
                this.props.groupSelected(Number(selectionArray[0].id), selectionArray[0].name);
            }
        }
    }

    render() {
        return (
            <AsyncTypeahead id={this.props.id || 'group_search'} size={this.props.size}
                            labelKey={'label'}
                            isLoading={this.state.loading}
                            onSearch={this.doSearch}
                            options={this.state.options}
                            onChange={this.selected}
                            filterBy={() => true}
                            defaultInputValue={this.props.initialValue}
                            placeholder={this.props.placeholder || "Find a group..."}
                            align={"left"}/>
        );
    }
}