import React from 'react';
import {GlobalContext} from "../../../GlobalContext";
import {
    Col,
    DropdownButton,
    Form,
    Table,
    Dropdown, Row, Alert
} from "react-bootstrap";
import {Glyph} from "../Glyph";
import {todayStr} from '../../../utils/Dates';
import {ProviderSearch} from "../ProviderSearch";
import {ICD10Search} from "../ICD10Search";
import {OptionalSelect} from "../OptionalSelect";
import {ReferralActivityRow} from "./ReferralActivityRow";
import {ItemModal} from "../ItemModal";
import {DateInput} from "../DateInput";

export class ReferralModal extends ItemModal {
    static contextType = GlobalContext;

    idField = 'referralId';
    itemTypeName = 'Referral';

    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            visitTypes: [],
            visitSubtypes: [],
            insurances: [],
            internalProviders: [],
            locations: [],
            icd10ToAdd: null,
            noReferral: this.state.item.p1Last === 'No Referral',
            editing: false,
            tempActivities: [],
            errorMessage: null
        };
    };

    becameVisible = () => {
        this.context.cachedGet(`/lookup/visit_type?filter=${encodeURIComponent('active=1')}`, 300)
            .then(d => this.setState({visitTypes: d.data}));
        this.context.cachedGet(`/lookup/visit_subtype?filter=${encodeURIComponent('active=1')}`, 300)
            .then(d => this.setState({visitSubtypes: d.data}));
        this.context.cachedGet(`/lookup/insurance?filter=${encodeURIComponent('active=1')}&orderBy=carrierName`, 300)
            .then(d => this.setState({insurances: d.data}));
        this.context.cachedGet(`/lookup/location?filter=${encodeURIComponent('internal=1 and active=1')}&orderBy=addr1`, 300)
            .then(d => this.setState({locations: d.data}));
        this.context.cachedGet(`/providers/internal?active=1`, 300)
            .then(d => this.setState({internalProviders: d.data}));

        let acts = this.state.item.activities || [];
        // Clone the activities array so we can manipulate them freely before saving
        let tempActs = JSON.parse(JSON.stringify(acts));
        this.setState({tempActivities: tempActs, errorMessage: null}, () => {
            if (this.state.item.referralId === 0) {
                this.addActivity();
            }
        });
    };

    /**
     * Override me
     * @returns {boolean}
     */
    doCustomValidation = () => {
        this.setState({errorMessage: null});
        this.state.item.activities = this.state.tempActivities;
        if (!this.state.noReferral && (!this.state.item.providerId || this.state.item.providerId <= 0)) {
            this.setState({errorMessage: "You must select a referring provider or choose 'No Referral'."});
            return false;
        }
        if (!this.state.item.activities || this.state.item.activities.length == 0) {
            this.setState({errorMessage: "You must enter an activity."});
            return false;
        }
        return true;
    };

    deleteReferralDiagnosis = (code) => {
        let diags = this.state.item.diagnoses;
        let index = diags.findIndex(d => d.code === code);

        if (index >= 0) {
            diags.splice(index, 1);
            this.setItemField("diagnoses", diags);
        }
    }

    buildProviderName = (f, l, sp) => {
        let n = '';
        if (f) n += f;
        if (n) {
            if (f) n += ' ';
            n += l;
        }
        if (sp) n += ', ' + sp;
        return n;
    }

    getTitle = (item) => {
        return `${item.referralId > 0 ? 'Edit' : 'New'} referral for ${this.props.patient.firstName} ${this.props.patient.lastName}`;
    };

    isMedicare = () => {
        if (this.state.item && this.state.item.primaryInsuranceId && this.state.insurances) {
            let medicare = this.state.insurances.find(i => i.carrierName === 'Medicare');
            return (medicare && Number(medicare.insuranceId) === Number(this.state.item.primaryInsuranceId));
        }
        return false;
    }

    /**
     * Override me
     * @returns {JSX.Element}
     */
    renderFormContents = (r) => {
        let noReferral = this.state.noReferral || r.providerId === -1 || r.p1Last === 'No Referral';
        return <div>
            <Form.Row>
            <Col xs={3}>
                <Form.Group controlId="receivedDate">
                    <Form.Label size="sm">Received:</Form.Label>
                    <DateInput required name={"receivedDate"} value={r.receivedDate} onChange={this.itemFieldChanged}/>
                    <Form.Control.Feedback type="invalid">
                        Invalid date. Use the format mm/dd/yyyy, e.g. 12/31/2022.
                    </Form.Control.Feedback>
                </Form.Group>
            </Col>
        </Form.Row>
        <Form.Row>
            <Col xs={6}>
                <Form.Group controlId="provider1Name">
                    <Form.Label size="sm">Referring Provider 1:</Form.Label>
                    <Form.Row>
                        <Col sm={6} md={3}>
                            <DropdownButton
                                variant="outline-secondary"
                                title={this.state.noReferral ? 'No Referral' : 'Referred'}
                                size={"sm"}
                            >
                                <Dropdown.Item onClick={this.setReferred}>Referred</Dropdown.Item>
                                <Dropdown.Item onClick={this.setNoReferral}>No Referral</Dropdown.Item>
                            </DropdownButton>
                        </Col>
                        <Col>
                            <ProviderSearch cleared={noReferral} disabled={noReferral}
                                            internal={false} id={"provider1Search"} size={"sm"} providerSelected={this.provider1Selected}
                                            placeholder={noReferral ? 'None' : 'Search Providers'}
                                            initialValue={noReferral ? 'None' : (r.p1First || r.p1Last) ? this.buildProviderName(r.p1First, r.p1Last, r.p1Specialty) : ''}
                                            isInvalid={this.state.validated && !this.state.noReferral && (this.state.item.providerId == null || this.state.item.providerId <= 0)}
                                            required={true}/>
                        </Col>
                    </Form.Row>

                </Form.Group>
            </Col>
            <Col xs={6}>
                <Form.Group controlId="provider2Name">
                    <Form.Label size="sm">Referring Provider 2:</Form.Label>
                    <ProviderSearch required={false} internal={false} id={"provider2Search"} size={"sm"}
                                    providerSelected={this.provider2Selected}
                                    initialValue={r.p2First || r.p2Last ? this.buildProviderName(r.p2First, r.p2Last, r.p2Specialty) : ''}/>
                </Form.Group>
            </Col>
        </Form.Row>
        <Form.Row>
            <Col xs={6}>
                <Form.Group>
                    <Form.Label size="sm">Visit type/subtype:</Form.Label>
                    <Form.Row>
                        <Col xs={6}>
                            <Form.Control as={"select"} size="sm" custom={true} name={"visitTypeCode"} value={r.visitTypeCode || ''} onChange={this.itemFieldChanged}>
                                {
                                    this.state.visitTypes.map(vt => <option key={vt.visitTypeCode} value={vt.visitTypeCode}>{vt.description}</option>)
                                }
                            </Form.Control>
                        </Col>
                        <Col xs={6}>
                            <Form.Control as={"select"} size="sm" custom={true} name={"visitSubtypeCode"} value={r.visitSubtypeCode || ''} onChange={this.itemFieldChanged}>
                                {
                                    this.state.visitSubtypes.map(vt => <option key={vt.visitSubtypeCode} value={vt.visitSubtypeCode}>{vt.description}</option>)
                                }
                            </Form.Control>
                        </Col>
                    </Form.Row>
                </Form.Group>
            </Col>
            <Col xs={6}>
                <Form.Group>
                    <Form.Label size="sm">Insurance:</Form.Label>
                    <Form.Row>
                        <Col xs={6}>
                            <OptionalSelect name={"primaryInsuranceId"} value={r.primaryInsuranceId} options={this.state.insurances} valueKey={"insuranceId"} labelKey={"carrierName"} onChange={this.itemFieldChanged} noneLabel={"Primary Insurance"}/>
                            {
                                this.isMedicare() &&
                                    <Form.Check type={"switch"}
                                                className={"mt-2"}
                                                id={"tplRequest"}
                                                name={"tplRequest"}
                                                aria-label={"TPL Request"}
                                                label={"TPL Request"}
                                                checked={r.tplRequest == 1}
                                                onChange={e => this.itemFieldChanged(e, e.target.checked ? 1 : 0)}
                                    />
                            }
                        </Col>
                        <Col xs={6}>
                            <OptionalSelect name={"secondaryInsuranceId"} value={r.secondaryInsuranceId} options={this.state.insurances} valueKey={"insuranceId"} labelKey={"carrierName"} onChange={this.itemFieldChanged} noneLabel={"Secondary Insurance"}/>
                        </Col>
                    </Form.Row>
                </Form.Group>
            </Col>
        </Form.Row>
        <Form.Row>
            <Col xs={6}>
                <Form.Row>
                    <Col xs={6}>
                        <Form.Group controlId="icd10Name">
                            <Form.Label size="sm">ICD10s:</Form.Label>
                            <ICD10Search id={"icd10Name"} size={"sm"} icd10Selected={this.icd10Selected} initialValue={''} className={"mb-2"}/>

                        </Form.Group>
                    </Col>
                    <Col xs={6} className={"pt-4"}>
                        <Table striped size={"sm"}>
                            <tbody>
                            { r.diagnoses.map(d => <tr key={d.code}><td className={'small w-100'}>{d.code}: {d.description}</td><td className={"text-center"}><Glyph name={"x"} className={"clickable"} label={"Delete"} onClick={() => this.deleteReferralDiagnosis(d.code)}/></td></tr>) }
                            </tbody>
                        </Table>
                    </Col>
                </Form.Row>
            </Col>

            <Col xs={6}>
                <Form.Group controlId="diagnosis">
                    <Form.Label size="sm">Other diagnosis:</Form.Label>
                    <Form.Control name={"diagnosis"} value={r.diagnosis || ''} onChange={this.itemFieldChanged} size={"sm"}/>
                </Form.Group>
            </Col>
        </Form.Row>
        <Form.Row>
            <Col xs={12}>
                <Form.Group controlId="diagnosis">
                    <Form.Label size="sm">Notes:</Form.Label>
                    <Form.Control
                        name={"notes"}
                        as="textarea"
                        size={"sm"}
                        style={{ height: '75px' }}
                        onChange={this.itemFieldChanged}
                        value={r.notes || ''}
                    />
                </Form.Group>
            </Col>
        </Form.Row>
        <Form.Row>
            <Col md={6}>
                <Row>
                    <Col>
                        <Form.Check
                            type={"switch"}
                            id={"scheduled"}
                            name={"scheduled"}
                            aria-label={"Scheduled"}
                            label={"Scheduled"}
                            checked={r.scheduled === 1}
                            onChange={e => this.itemFieldChanged(e, e.target.checked ? 1 : 0)}/>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <Form.Group controlId="appointmentDate">
                            <Form.Label size="sm">Appointment Date:</Form.Label>
                            <DateInput name={"appointmentDate"} value={r.appointmentDate} onChange={this.itemFieldChanged}/>
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="internalProviderId">
                            <Form.Label size="sm">Appointment With:</Form.Label>
                            <OptionalSelect name={"internalProviderId"} value={r.internalProviderId} onChange={this.itemFieldChanged} options={this.state.internalProviders} valueKey={"providerId"} labelKey={"fullName"} canSelectNone={true}/>
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="locationId">
                            <Form.Label size="sm">Location:</Form.Label>
                            <OptionalSelect name={"locationId"} value={r.locationId} onChange={this.itemFieldChanged} options={this.state.locations} valueKey={"locationId"} labelKey={"addr1"} canSelectNone={true}/>
                        </Form.Group>
                    </Col>
                </Row>

            </Col>
            <Col md={6}>
                <Row>
                    <Col>
                        <Form.Check
                            type={"switch"}
                            id={"unableToSchedule"}
                            name={"unableToSchedule"}
                            aria-label={"Unable To Schedule"}
                            label={"Unable To Schedule"}
                            checked={r.unableToSchedule === 1}
                            onChange={e => this.itemFieldChanged(e, e.target.checked ? 1 : 0)}/>
                    </Col>
                </Row>
            </Col>
        </Form.Row>
        <Form.Row>
            <Col xs={12}>
                <Form.Group>
                    <Form.Row>
                        <Col xs={12}>
                            <Form.Label>Activity:</Form.Label>
                            <Glyph name={"plus-circle"} className={"clickable ml-2"} onClick={this.addActivity}/>
                        </Col>
                    </Form.Row>
                    { this.state.tempActivities.map(act => <ReferralActivityRow activity={act} editing={act.editing} key={act.referralActivityId}
                                                                    fieldUpdated={this.activityFieldUpdated}
                                                                    onCancelEdit={this.cancelEditActivity}/>)}
                </Form.Group>
            </Col>
        </Form.Row>
            {this.state.errorMessage && <Form.Row><Col><Alert variant={"danger"}>{this.state.errorMessage}</Alert></Col></Form.Row>}
        </div>;
    };

    edit = () => {
        this.setState({editing: true})
    };
    
    newActivity = () => {
        let ref = this.state.item;
        return {
            referralId: ref.referralId,
            referralActivityId: 0,
            activityDate: todayStr(),
            statusCode: "",
            activityContactTypeCode: "",
            editing: true
        };
    }

    addActivity = () => {
        let acts = this.state.tempActivities;
        let newAct = this.newActivity();
        // Give it a dummy negative id so we can identify it later in callbacks
        newAct.referralActivityId = (acts.length + 1) * -1;
        acts.push(newAct);
        this.setState({tempActivities: acts});
    };

    cancelEditActivity = (id) => {
        let acts = this.state.tempActivities;
        let index = acts.findIndex(a => a.referralActivityId === id);
        if (id < 0) {
            acts.splice(index, 1);
        } else {
            // Replace with the original
            let originalAct = this.state.item.activities.find(a => a.referralActivityId === id);
            acts[index] = originalAct;
        }
        this.setState({tempActivities: acts});
    };

    activityFieldUpdated = (id, fieldName, value) => {
        let acts = this.state.tempActivities;
        let act = acts.find(a => a.referralActivityId === id);
        if (act) {
            act[fieldName] = value;
            this.setState({tempActivities: acts});
        }
    };

    provider1Selected = (providerId) => {
        this.setItemField('providerId', providerId);
    };

    provider2Selected = (providerId) => {
        this.setItemField('secondaryProviderId', providerId);
    };

    icd10Selected = (code, description) => {
        let currentDiags = this.state.item.diagnoses || [];
        let existing = currentDiags.find(d => d.code === code);
        if (!existing) {
            currentDiags.push({code, description});
        }
        this.setItemField('diagnoses', currentDiags);
    };

    setReferred = () => {
        this.setState({noReferral: false});
        this.setItemField('providerId', '');
        this.setItemField('p1Last', '');
    };

    setNoReferral = () => {
        this.setState({noReferral: true});
        this.setItemField('providerId', -1);
    };
}