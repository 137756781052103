import {Col, Form, Row} from "react-bootstrap";
import React from "react";

export class PatientContactForm extends React.Component {
    constructor() {
        super();
        this.state = {
        }
    }
    
    render() {
        let p = this.props.patient;
        return <Form id={"patient-contact-form"} noValidate validated={this.props.validated} onSubmit={e => e.preventDefault()}>
            <Row>
                <Col xs={6}>
                    <Form.Row>
                        <Col>
                            <Form.Group controlId="addr1">
                                <Form.Label size="sm">
                                    Address line 1
                                </Form.Label>
                                <Form.Control size="sm" name={"addr1"}
                                              value={p.addr1 || ""}
                                              onChange={this.props.patientFieldChanged}
                                              disabled={!this.props.editing}/>
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col>
                            <Form.Group controlId="addr2">
                                <Form.Label size="sm">
                                    Address line 2
                                </Form.Label>
                                <Form.Control size="sm" name={"addr2"}
                                              value={p.addr2 || ""}
                                              onChange={this.props.patientFieldChanged}
                                              disabled={!this.props.editing}/>
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col>
                            <Form.Group controlId="city">
                                <Form.Label size="sm">
                                    City
                                </Form.Label>
                                <Form.Control size="sm" name={"city"}
                                              value={p.city || ""}
                                              onChange={this.props.patientFieldChanged}
                                              disabled={!this.props.editing}/>
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col xs={6}>
                            <Form.Group controlId="state">
                                <Form.Label size="sm">
                                    State
                                </Form.Label>
                                <Form.Control size="sm" name={"state"}
                                              value={p.state || ""}
                                              onChange={this.props.patientFieldChanged}
                                              disabled={!this.props.editing}/>
                            </Form.Group>
                        </Col>
                        <Col xs={6}>
                            <Form.Group controlId="zip">
                                <Form.Label size="sm">
                                    Zip
                                </Form.Label>
                                <Form.Control size="sm" name={"zip"}
                                              value={p.zip || ""}
                                              onChange={this.props.patientFieldChanged}
                                              disabled={!this.props.editing}/>
                            </Form.Group>
                        </Col>
                    </Form.Row>
                </Col>
                <Col>
                    <Form.Row>
                        <Col>
                            <Form.Group controlId="primaryPhone">
                                <Form.Label size="sm">
                                    Home phone
                                </Form.Label>
                                <Form.Control size="sm" name={"primaryPhone"}
                                              value={p.primaryPhone || ""}
                                              onChange={this.props.patientFieldChanged}
                                              disabled={!this.props.editing}/>
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col>
                            <Form.Group controlId="mobilePhone">
                                <Form.Label size="sm">
                                    Mobile Phone
                                </Form.Label>
                                <Form.Control size="sm" name={"mobilePhone"}
                                              value={p.mobilePhone || ""}
                                              onChange={this.props.patientFieldChanged}
                                              disabled={!this.props.editing}/>
                            </Form.Group>
                        </Col>
                    </Form.Row>
                </Col>
            </Row>
        </Form>;
    }
}