import React from "react";
import {ProviderSearch} from "./ProviderSearch";
import {ItemModal} from "./ItemModal";

export class ProviderSearchModal extends ItemModal {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            searchTerm: null
        }
    }

    itemChanged = () => {
        this.setState({searchTerm: null});
    };

    /**
     * Override me
     * @returns {string}
     */
    getIdField = () => {
        return 'providerId';
    };

    /**
     * Override me
     * @returns {string} Human-readable description of the type of item, used in titles/headers e.g. 'Provider'
     */
    getItemTypeName = () => {
        return 'Provider';
    }

    /**
     * Override me
     * @returns {boolean}
     */
    doCustomValidation = () => {
        return this.state.item.providerId > 0;
    };

    /**
     * Override me
     * @returns {JSX.Element}
     */
    renderFormContents = (item) => {
        return <ProviderSearch size={"sm"} providerSelected={(id, name) => this.setState({item: {providerId: id, name: name}})}/>;
    };

    getTitle = (i) => {
        return 'Add Provider';
    }
}