export const generateKVPairs = (m) => {
    let arr = [];
    for (const [key, value] of Object.entries(m)) {
        arr.push({"value": key, "label": value});
    }
    return arr;
};

export const LienTaskStatus = {
        "PENDING": "Pending",
        "COMPLETE": "Complete",
        "CANCELLED": "Cancelled"
};

export const LienTaskResponseStatus = {
        "NOT_APPLICABLE": "N/A",
        "PENDING": "Pending",
        "RECEIVED": "Received"
};

export const USER_ICONS = [
    {value: "airplane"},
    {value: "airplane-fill"},
    {value: "amazon"},
    {value: "android"},
    {value: "android2"},
    {value: "apple"},
    {value: "reddit"},
    {value: "amd"},
    {value: "backpack"},
    {value: "backpack-fill"},
    {value: "bag"},
    {value: "bag-fill"},
    {value: "bag-heart"},
    {value: "bag-heart-fill"},
    {value: "balloon"},
    {value: "balloon-fill"},
    {value: "balloon-heart"},
    {value: "balloon-heart-fill"},
    {value: "bandaid"},
    {value: "bandaid-fill"},
    {value: "bank"},
    {value: "bank-fill"},
    {value: "bicycle"},
    {value: "binoculars"},
    {value: "book"},
    {value: "boombox"},
    {value: "boombox-fill"},
    {value: "brilliance"},
    {value: "brush"},
    {value: "brush-fill"},
    {value: "bug"},
    {value: "bug-fill"},
    {value: "bullseye"},
    {value: "cake"},
    {value: "cake-fill"},
    {value: "cake2"},
    {value: "cake2-fill"},
    {value: "capsule"},
    {value: "car-front"},
    {value: "car-front-fill"},
    {value: "cash"},
    {value: "cash-coin"},
    {value: "cash-stack"},
    {value: "cassette"},
    {value: "chat-heart"},
    {value: "chat-heart-fill"},
    {value: "cone-striped"},
    {value: "controller"},
    {value: "cookie"},
    {value: "cup-hot"},
    {value: "cup-hot-fill"},
    {value: "cup-straw"},
    {value: "currency-dollar"},
    {value: "dice-1"},
    {value: "dice-1-fill"},
    {value: "dice-2"},
    {value: "dice-2-fill"},
    {value: "dice-3"},
    {value: "dice-3-fill"},
    {value: "dice-4"},
    {value: "dice-4-fill"},
    {value: "dice-5"},
    {value: "dice-5-fill"},
    {value: "dice-6"},
    {value: "dice-6-fill"},
    {value: "discord"},
    {value: "disc"},
    {value: "dribble"},
    {value: "droplet"},
    {value: "droplet-fill"},
    {value: "earbuds"},
    {value: "emoji-heart-eyes"},
    {value: "emoji-heart-eyes-fill"},
    {value: "emoji-laughing"},
    {value: "emoji-laughing-fill"},
    {value: "emoji-smile"},
    {value: "emoji-smile-fill"},
    {value: "emoji-sunglasses"},
    {value: "emoji-sunglasses-fill"},
    {value: "film"},
    {value: "flower1"},
    {value: "flower2"},
    {value: "flower3"},
    {value: "gem"},
    {value: "globe"},
    {value: "globe-americas"},
    {value: "google"},
    {value: "hammer"},
    {value: "heart"},
    {value: "heart-fill"},
    {value: "heart-half"},
    {value: "heart-pulse"},
    {value: "heart-pulse-fill"},
    {value: "hearts"},
    {value: "hurricane"},
    {value: "hypnotize"},
    {value: "infinity"},
    {value: "incognito"},
    {value: "joystick"},
    {value: "life-preserver"},
    {value: "lightbulb"},
    {value: "lightbulb-fill"},
    {value: "lightning"},
    {value: "lightning-fill"},
    {value: "lightning-charge"},
    {value: "lightning-charge-fill"},
    {value: "magnet"},
    {value: "magnet-fill"},
    {value: "moon"},
    {value: "moon-fill"},
    {value: "moon-stars"},
    {value: "moon-stars-fill"},
    {value: "peace"},
    {value: "peace-fill"},
    {value: "person-arms-up"},
    {value: "piggy-bank"},
    {value: "piggy-bank-fill"},
    {value: "radioactive"},
    {value: "rainbow"},
    {value: "robot"},
    {value: "rocket"},
    {value: "rocket-fill"},
    {value: "rocket-takeoff"},
    {value: "rocket-takeoff-fill"},
    {value: "shield"},
    {value: "shield-fill"},
    {value: "snapchat"},
    {value: "speedometer"},
    {value: "spotify"},
    {value: "star"},
    {value: "star-fill"},
    {value: "star-half"},
    {value: "stars"},
    {value: "steam"},
    {value: "stoplights"},
    {value: "suit-club"},
    {value: "suit-club-fill"},
    {value: "suit-heart"},
    {value: "suit-heart-fill"},
    {value: "suit-spade"},
    {value: "suit-spade-fill"},
    {value: "suit-diamond"},
    {value: "suit-diamond-fill"},
    {value: "tencent-qq"},
    {value: "tiktok"},
    {value: "tornado"},
    {value: "tropical-storm"},
    {value: "twitch"},
    {value: "twitter"},
    {value: "twitter-x"},
    {value: "umbrella"},
    {value: "umbrella-fill"},
    {value: "virus"},
    {value: "virus2"},
    {value: "wechat"},
    {value: "whatsapp"},
    {value: "wind"},
    {value: "windows"},
    {value: "xbox"},
    {value: "yin-yang"}
]