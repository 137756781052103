import React from "react";
import {LocationModal} from "../common/LocationModal";
import {ItemList} from "../common/ItemList";
import {Glyph} from "../common/Glyph";

export class GroupLocationList extends ItemList {

    baseUrl = `/groups/${this.props.groupId}/locations`;
    idField = 'locationId';
    itemTypeName = 'Location';
    newItem = () => {
        return {
            locationId: 0,
            groupId: this.props.groupId,
            addr1: null,
            addr2: null,
            city: null,
            state: null,
            zip: null,
            active: 1,
            internal: 0,
            phone: '',
            fax: ''
        };
    };
    headerColumns = ['Address', 'Phone', 'Fax', 'Status'];
    headerColumnClasses = null;
    dataColumnClasses = null;
    addSupported = true;
    editSupported = true;
    deleteSupported = false;
    paging = false;
    modalClass = LocationModal;

    getItemCellContents = (item) => {
        return [<div><div className={"d-inline-block"}>
                {item.addr1}
                {item.addr2 ? <span><br/>{item.addr2}</span> : <span/>}
                <br/>{item.city}, {item.state} {item.zip}
            </div>
            <a href={this.buildMapUrl(item)} target={"_blank"} rel={"noopener noreferrer"} className={"align-top ml-3"} onClick={e => e.stopPropagation()}><Glyph name={"geo-alt"}/></a></div>,
            item.phone, item.fax, item.active === 1 ? 'Active' : 'Inactive'];
    };

    buildMapUrl = (loc) => {
        return 'https://maps.google.com/?q=' + window.encodeURIComponent(`${loc.addr1} ${loc.addr2 ||''}, ${loc.city ||''} ${loc.state ||''} ${loc.zip ||''}`.trim());
    };
}