import React from 'react';
import {GlobalContext} from "../../GlobalContext";
import {
    ButtonGroup,
    Col,
    DropdownButton,
    Modal,
    ModalBody,
    Row,
    Table,
    Form,
    InputGroup,
    FormControl, Button
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import moment from "moment-timezone";
import DropdownItem from "react-bootstrap/DropdownItem";
import Cookies from "universal-cookie";
import {RoleWrapper} from "../common/RoleWrapper";
import {Glyph} from "../common/Glyph";
import {SpinnerWrapper} from "../common/SpinnerWrapper";

export default class WorkTaskStatsModal extends React.Component {
    static contextType = GlobalContext;

    filters = [
        {
            name: "Eligibility (General)",
            modalTitle: "Eligibility Stats",
            modalTitleColor: "#CCFCFF",
            subcategory: "ELIGIBILITY",
            dateCol: "referenceDate",
            progressDateLabel: 'Appointment Date'
        },
        {
            name: "Auth (General)",
            modalTitle: "Auth Stats",
            modalTitleColor: "#F6A2FF",
            subcategory: "AUTH",
            dateCol: "dueDate",
            progressDateLabel: 'Due Date'
        },
        {
            name: "Eligibility (TCD)",
            modalTitle: "Eligibility Stats (TCD)",
            subcategory: "ELIGIBILITY",
            dateCol: "referenceDate",
            progressDateLabel: 'Appointment Date'
        }
    ];

    constructor(props) {
        super(props);
        this.state = {
            data: {},
            selectedFilter: this.filters[0],
            teamSize: 1,
            minTeamSize: 1,
            officialGoal: null,
            goalLocked: false,
            loading: false
        }
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((!prevProps.show && this.props.show) || prevState.selectedFilter.name !== this.state.selectedFilter.name) {
            this.loadData();
        }

        if (this.props.show && this.state.data.productivityStats && !prevState.goalLocked && this.state.goalLocked) {
            this.context.apiRequest("POST", "/workTasks/setGoal", {
                category: this.props.category,
                subcategory: this.state.selectedFilter.subcategory,
                goal: parseInt(this.state.officialGoal)})
                .then(this.loadData);
        }
    }

    loadData = () => {
        const cookies = new Cookies();
        let teamSizeCookie = cookies.get("authBoardStats_TeamSize");
        if (teamSizeCookie && !isNaN(teamSizeCookie)) {
            this.setState({teamSize: parseInt(teamSizeCookie)});
        }
        let taskTypes = '';
        let apptReasons = '';
        let f = this.state.selectedFilter;
        if (f.taskTypes) taskTypes = '&' + f.taskTypes.map(t => `taskTypes=${t}`).join('&');
        if (f.apptReasons) apptReasons = '&' + f.apptReasons.map(t => `apptReasons=${t}`).join('&');
        this.setState({loading: true}, () => {
            this.context.apiRequest('GET', `/workTasks/taskStats?category=${this.props.category}&subcategory=${f.subcategory}`)
                .then(d => {
                    if (d && d.data) {
                        let newTeamSize = this.state.teamSize;
                        let minTeamSize = d.data.productivityStats.activeTodayTeamSize;
                        if (minTeamSize && minTeamSize > newTeamSize) newTeamSize = minTeamSize;
                        this.setState({data: d.data,
                            teamSize: newTeamSize,
                            minTeamSize: minTeamSize,
                            officialGoal: d.data.officialGoal || null,
                            goalLocked: (d.data.officialGoal != null)});
                    }
                })
                .finally(() => this.setState({loading: false}));
        })
    }

    buildUserTotalRows = () => {
        let d = this.state.data;
        let ps = d.progressStats;
        let numDays = ps.numDays;
        let userRowCount = d.teamSize+1; // User rows = team size + 1 because of 'System'
        if (ps && ps.userStats) {
            let rows = [];
            for (let i=0; i<userRowCount; i++) {
                let cols = [];
                for (let j=0; j<numDays; j++) {
                    let index = j * userRowCount + i;
                    cols.push(ps.userStats[index].totalComplete);
                }
                rows.push(<tr key={"us-"+ps.userStats.userName+i}>
                    <td>{ps.userStats[i].displayName}</td>
                    {cols.map((c,k) => <td key={"us-col-"+ps.userStats.userName+k} className={"task-stats-cell"}>{c}</td>)}
                </tr>)
            }
            return rows;
        }
        return <></>;
    }


    selectFilter = (f) => {
        this.setState({selectedFilter: f});
    }

    teamSizeChanged = (e) => {
        try {
            let val = parseInt(e.target.value);
            if (!isNaN(val) && val >= 1 && val <= 50) {
                this.setState({teamSize: val});
                // Persist in a cookie
                const cookies = new Cookies();
                cookies.set("authBoardStats_TeamSize", e.target.value, {path: "/"});
            }
        } catch(err) {
            console.warn(err);
        }
    }

    render() {
        let d = this.state.data;
        let ps = d.progressStats;
        let pd = d.productivityStats;
        let ut = d.currentUserCompletedTasks;
        let filter = this.state.selectedFilter;
        let defaultGoal = pd ? Math.ceil(pd.dailyDeptGoal/this.state.teamSize) : 1;
        let perUserGoal = this.state.officialGoal || defaultGoal;
        let totalDeptGoal = perUserGoal * this.state.teamSize;

        return <Modal show={this.props.show} onHide={this.props.onHide} size={"lg"} scrollable={true}>
            <ModalHeader closeButton style={{backgroundColor: filter.modalTitleColor || "#ffffff"}}>{filter.modalTitle || this.props.department + " Team Stats"}</ModalHeader>
            <ModalBody>
                <SpinnerWrapper visible={this.state.loading}>
                    {ps && ps.totals && <>
                        <h5 className={"float-left"}>Progress</h5>
                        <DropdownButton as={ButtonGroup} id={"stat-filter"} size="sm" title={this.state.selectedFilter.name} className={"float-right"} variant={"dark"}>
                            {
                                this.filters.map((f,i) => <DropdownItem key={i} eventKey={f.name} onClick={() => this.selectFilter(f)}>{f.name}</DropdownItem>)
                            }
                        </DropdownButton>
                        <Table size={"sm"} striped borderless className={"mb-5"}>
                            <thead>
                            <tr>
                                <th></th>
                                <th colSpan={ps.numDays || 4} className={"text-center"}>{filter.progressDateLabel}</th>
                            </tr>
                            <tr>
                                <th></th>
                                {ps.totals.map((r,i) => <th key={i} className={"text-center"}>{moment(r[filter.dateCol]).format('M/D')}</th>)}
                            </tr>
                            </thead>
                            <tbody>
                            <tr className={"font-weight-bold"}>
                                <td>Total Tasks</td>
                                {ps.totals.map((r,i) => <td key={i} className={"task-stats-cell"}>{r.totalTasks}</td>)}
                            </tr>
                            <tr>
                                <td></td>
                                <td colSpan={ps.numDays || 4} className={"text-center font-weight-bold"}>Completed Per Employee</td>
                            </tr>
                            {this.buildUserTotalRows()}
                            <tr>
                                <td></td>
                                <td colSpan={ps.numDays || 4} className={"text-center font-weight-bold"}>Team Totals</td>
                            </tr>
                            <tr className={"font-weight-bold"}>
                                <td>Total Complete</td>
                                {ps.totals.map((r,i) => <td key={i} className={"task-stats-cell"}>{r.totalComplete}</td>)}
                            </tr>
                            <tr className={"font-weight-bold"}>
                                <td>Completed Today</td>
                                {ps.totals.map((r,i) => <td key={i} className={"task-stats-cell"}>{r.totalCompletedToday}</td>)}
                            </tr>
                            <tr className={"font-weight-bold"}>
                                <td>To Do</td>
                                {ps.totals.map((r,i) => <td key={i} className={"task-stats-cell"}>{r.totalTasks-r.totalComplete}</td>)}
                            </tr>
                            <tr className={"font-weight-bold"}>
                                <td>% Complete</td>
                                {ps.totals.map((r,i) => <td key={i} className={"task-stats-cell"}>{r.totalTasks > 0 ? ((r.totalComplete/r.totalTasks)*100).toFixed(1) : '0.0'}%</td>)}
                            </tr>
                            </tbody>
                        </Table></>}

                    {pd && (this.state.selectedFilter.name == "Eligibility (General)"
                        || this.state.selectedFilter.name == "Auth (General)") && <>
                        <Row>
                            <Col md={3}>
                                <h5>Daily Productivity</h5>
                            </Col>
                            <Col className={"justify-content-end"} md={9}>
                                <Form inline style={{float: 'right'}}>
                                    <RoleWrapper roles={"Supervisor"}>
                                        {
                                            this.state.goalLocked &&
                                            <Form.Label className="mr-3" htmlFor="goalInput">
                                                <small>Default Goal: <strong>{defaultGoal}</strong></small>
                                            </Form.Label>
                                        }
                                        <Form.Label className="mr-2" htmlFor="goalInput">
                                            <small>Official Goal:</small>
                                        </Form.Label>
                                        <InputGroup size={"sm"}>
                                            <Form.Control id={"goalInput"}
                                                          disabled={this.state.goalLocked}
                                                          value={this.state.officialGoal || defaultGoal}
                                                          onChange={e => this.setState({officialGoal: e.target.value})}
                                                          type={"number"}
                                                          min={1}
                                                          max={100}
                                                          size={"sm"}
                                            />
                                            <InputGroup.Append>
                                                <Button variant={"outline-secondary"} onClick={() => this.setState({goalLocked: !this.state.goalLocked})}>
                                                    <Glyph name={this.state.goalLocked ? "lock-fill" : "unlock"}/>
                                                </Button>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </RoleWrapper>
                                    <RoleWrapper inverse={true} roles={"Supervisor"}>
                                        {
                                            d.officialGoal && <>
                                                <Form.Label className="mr-2" htmlFor="goalInput">
                                                    <small>Official Goal:</small>
                                                </Form.Label>
                                                {d.officialGoal}
                                            </>
                                        }
                                    </RoleWrapper>

                                    <Form.Label className="mr-2 ml-3" htmlFor="teamSizeInput">
                                        <small>Team size:</small>
                                    </Form.Label>
                                    <RoleWrapper roles={"Supervisor"}>
                                        <Form.Control type={"number"} id={"teamSizeInput"}
                                                      size={"sm"}
                                                      min={1}
                                                      max={"50"}
                                                      value={this.state.teamSize}
                                                      onChange={this.teamSizeChanged}/>
                                    </RoleWrapper>
                                    <RoleWrapper inverse={true} roles={"Supervisor"}>
                                        {this.state.teamSize}
                                    </RoleWrapper>
                                </Form>
                            </Col>
                        </Row>
                        <Table striped borderless size={"sm"} className={"mb-5"}>
                            <thead>
                            <tr>
                                <th></th>
                                <th className={"text-center"}>Goal</th>
                                <th className={"text-center"}>Completed</th>
                                <th className={"text-center"}>% of Goal</th>
                            </tr>
                            </thead>
                            <tbody>
                            {pd.userProductivity && pd.userProductivity.map((u,i) => <tr key={i}>
                                <td>{u.displayName}</td>
                                <td className={"task-stats-cell"}>{perUserGoal}</td>
                                <td className={"task-stats-cell"}>{u.completedTasks}</td>
                                <td className={"task-stats-cell"}>{((u.completedTasks/perUserGoal)*100).toFixed(1)+'%'}</td>
                            </tr>)
                            }
                            <tr className={"font-weight-bold"}>
                                <td>Team</td>
                                <td className={"task-stats-cell"}>{totalDeptGoal}</td>
                                <td className={"task-stats-cell"}>{pd.userProductivity.reduce((a, c) => a + c.completedTasks, 0)}</td>
                                <td className={"task-stats-cell"}>{pd.userProductivity.length > 0 ? Math.round((pd.userProductivity.reduce((a, c) => a + c.completedTasks, 0)/totalDeptGoal)*100).toFixed(1)+'%' : 'N/A'}</td>
                            </tr>
                            </tbody>
                        </Table>
                    </>}
                    {ut && <><h5>My Work Completed Today</h5>
                        <Table striped borderless size={"sm"} className={"mb-5"}>
                            <thead>
                            <tr>
                                <th className={"text-center"}>Completed At</th>
                                <th className={"text-center"}>Acct</th>
                                <th className={"text-center"}>Pt Last</th>
                                <th className={"text-center"}>Task</th>
                                <th className={"text-center"}>Reference Date</th>
                                <th className={"text-center"}>Status</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                ut.map((t,i) => <tr key={i}>
                                    <td className={"text-center"}>{moment(t.closedAt).format('M/D/YY h:mm a')}</td>
                                    <td className={"text-center"}>{t.externalPatientId}</td>
                                    <td className={"text-center"}>{t.ptLast}</td>
                                    <td className={"text-center"}>{t.taskType}</td>
                                    <td className={"text-center"}>{moment(t.referenceDate).format('M/D/YY')}</td>
                                    <td className={"text-center"}>{t.status}</td>
                                </tr>)
                            }
                            </tbody>
                        </Table>
                    </>}
                </SpinnerWrapper>
            </ModalBody>
        </Modal>
    }
}