import React from "react";
import {Col, Row} from "react-bootstrap";
import {GroupActivityModal} from "./GroupActivityModal";
import {GlobalContext} from "../../GlobalContext";
import {ItemList} from "../common/ItemList";
import {todayStr} from "../../utils/Dates";

export class GroupActivitiesList extends ItemList {
    static contextType = GlobalContext;

    componentDidMount() {
        super.componentDidMount();
        this.newItem.marketingRepUserId = this.context.userId;
    }

    baseUrl = `/groups/${this.props.groupId}/activities`;
    idField = 'groupMarketingActivityId';
    itemTypeName = 'Activity';
    newItem = () => {
        return {
            groupMarketingActivityId: 0,
            notes: '',
            groupId: this.props.groupId,
            providerIds: [],
            expense: '0.00',
            timestamp: todayStr(),
            marketingRepUserId: this.context.userProfile.userId
        }
    };
    headerColumns = ['Notes (click for full detail)'];
    headerColumnClasses = null;
    dataColumnClasses = null;
    addSupported = true;
    editSupported = true;
    deleteSupported = false;
    paging = true;
    modalClass = GroupActivityModal;

    /**
     * Override me
     * @param item
     * @returns array the contents of the table cells for a particular item.
     */
    getItemCellContents = (item) => {
        return [<div>
            <Row className={"small font-weight-light font-italic mb-2"}>
                <Col>{item.timestamp}: {item.marketingRepFirst} {item.marketingRepLast} - {item.contactType}</Col>
            </Row>
            <Row>
                <Col>{item.notes}</Col>
            </Row>
        </div>];
    };
}