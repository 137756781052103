import React from 'react';
import {GlobalContext} from "../../GlobalContext";
import {Card, Table} from "react-bootstrap";
import moment from "moment-timezone";
import {Link} from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import CalendarButton from "../common/CalendarButton";
import {nowMoment, TIMEZONE} from "../../utils/Dates";

export class MarketingFutureActivityWidget extends React.Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.state = {
            listTitle: "Loading...",
            activities: [],
            selectedDate: nowMoment().add(1, 'day').toDate(),
            calendarOpen: false
        };
    }

    refreshTimer = 0;

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.selectedDate !== this.state.selectedDate) {
            this.load();
        }
    }

    componentDidMount() {
        this.load();
    }

    componentWillUnmount() {
        if (this.refreshTimer) {
            window.clearTimeout(this.refreshTimer);
        }
    }

    toggleCalendar = (e) => {
        if (e) e.preventDefault();
        this.setState({calendarOpen: !this.state.calendarOpen});
    }

    dateSelected = (dt) => {
        this.setState({selectedDate: dt});
    }

    load = () => {
        if (this.refreshTimer) {
            window.clearTimeout(this.refreshTimer);
        }
        this.refreshTimer = window.setTimeout(this.load, 30000);

        let formattedDate = moment(this.state.selectedDate).tz(TIMEZONE).format('M/D/YYYY');
        if (this.context.authenticated) {
            this.context.apiRequest("GET", `/marketing/futureActivities?dt=${encodeURIComponent(formattedDate)}`, null, true)
                .then(d => this.setState({activities: d.data, listTitle: `Upcoming Visits on ${formattedDate}`}));
        }
    };

    render() {
        return (
            <Card className={this.props.className}>
                <Card.Header className={"align-middle"}>
                    {this.state.listTitle}{(this.state.listTitle != "Loading...") && <CalendarButton selected={this.state.selectedDate} onChange={this.dateSelected} className={"ml-3"}/>}
                </Card.Header>
                <Card.Body>
                    <Table size={"sm"} striped>
                        <tbody>
                        {
                            this.state.activities.map(a =>
                                <tr key={a.groupMarketingActivityId}><td>
                                    <div><Link className="text-dark" replace={true} to={`/marketing/groups/${a.groupId}`}>{a.groupName}</Link></div>
                                    {a.addr1 && <div className={"small"}>{a.addr1}, {a.zip}</div>}
                                </td></tr>)
                        }
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        );
    }
}