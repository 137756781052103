import React from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

export class Glyph extends React.Component {

    renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {this.props.label}
        </Tooltip>
    );

    render() {
        let icon = <i className={`${this.props.className || ''} bi-${this.props.name}${this.props.variant ? ' text-'+this.props.variant : ''}`}
                      style={this.props.style} aria-label={this.props.label || ''} onClick={this.props.onClick} ref={this.props.theRef}></i>;
        if (this.props.label) {
            return <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={this.renderTooltip}
            >
                { icon }
            </OverlayTrigger>
        } else {
            return icon;
        }
    }
}