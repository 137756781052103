import React from 'react';
import {ItemModal} from "../ItemModal";
import {GlobalContext} from "../../../GlobalContext";
import {
    Card,
    Col,
    Form,
    FormControl,
    FormGroup,
    FormLabel,
    InputGroup,
    ToggleButton,
    ToggleButtonGroup
} from "react-bootstrap";
import {GroupSearch} from "../GroupSearch";
import {ProviderSearch} from "../ProviderSearch";
import {DateInput} from "../DateInput";
import {shortDatePattern, todayStr, validateMMDDYYYY} from "../../../utils/Dates";
import {OptionalSelect} from "../OptionalSelect";
import {ICD10Search} from "../ICD10Search";
import {Glyph} from "../Glyph";
import {AuthActivityRow} from "./AuthActivityRow";
import CalendarButton from "../CalendarButton";
import moment from "moment-timezone";

export class PatientOrderModal extends ItemModal {
    static contextType = GlobalContext;

    idField = 'id';
    itemTypeName = 'Order';
    
    constructor(props) {
        super(props);
        let o = this.state.item;
        this.state = {
            ...this.state,
            dueDateStr: o.dueDate ? moment(o.dueDate).format('M/D/YYYY') : ''
        };
    };

    becameVisible = () => {
        let o = this.state.item;
        this.setState({dueDateStr: o.dueDate ? moment(o.dueDate).format('M/D/YYYY') : ''});
        console.log(`dueDate ${o.dueDate} ${o.assignedTo}`);
    }

    /**
     * Override me
     * @returns {boolean}
     */
    doCustomValidation = () => {
        return true;
    };

    statClicked = (values) => {
        // event publishes array of selected values in the toggle button set.
        if (values && values.length == 1) {
            this.setItemField("stat", values[0]);
        } else {
            this.setItemField("stat", 0);
        }
    }

    dueDateChanged = (e) => {
        let val = e.target.value;
        this.setState({dueDateStr: val});
        let valid = validateMMDDYYYY(val);
        if (valid) {
            this.setItemField("dueDate", moment(val).toISOString());
        } else {
            this.setItemField("dueDate", null);
        }
    }

    dueDateSelected = (dt) => {
        console.log(`due date selected`)
        let mt = moment(dt);
        this.setState({dueDateStr: mt.format('M/D/YYYY')});
        this.setItemField("dueDate", mt.toISOString());
    }

    taskTypes = [{val: "MSG"}, {val: "AUTH"}];

    renderFormContents = (o) => {
        return <div>
            <Form.Row>
                <Col md={5}>
                    <FormGroup>
                        <FormLabel>Task Type</FormLabel>
                        <OptionalSelect canSelectNone={false} name={"taskType"}  value={o.taskType} onChange={this.itemFieldChanged} options={this.taskTypes} valueKey={"val"} labelKey={"val"}/>
                    </FormGroup>
                </Col>
                <Col md={5}>
                    <FormGroup>
                        <FormLabel>Due Date</FormLabel>
                        <InputGroup size={"sm"} hasValidation>
                            <Form.Control isInvalid={!validateMMDDYYYY(this.state.dueDateStr)} name={"dueDate"} required={true} pattern={shortDatePattern} value={this.state.dueDateStr} onChange={this.dueDateChanged}/>
                            <InputGroup.Append>
                                <InputGroup.Text variant={"dark"}><CalendarButton selected={o.dueDate ? moment(o.dueDate).toDate() : new Date()} onChange={this.dueDateSelected}/></InputGroup.Text>
                            </InputGroup.Append>
                            <Form.Control.Feedback type="invalid">
                                M/D/YYYY
                            </Form.Control.Feedback>
                        </InputGroup>
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <FormLabel>STAT</FormLabel>
                        <ToggleButtonGroup name={"stat"} type="checkbox" value={this.s} onChange={this.statClicked} className={"d-block"}>
                            <ToggleButton value={1} checked={o.stat} variant={o.stat ? "danger" : "outline-secondary"}>
                                <Glyph name={o.stat ? "stopwatch-fill" : "stopwatch"}/>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </FormGroup>
                </Col>
            </Form.Row>
            <Form.Row>
                <Col md={12}>
                    <FormGroup>
                        <FormLabel>Subject</FormLabel>
                        <FormControl name={"taskDescription"} onChange={this.itemFieldChanged} value={o.taskDescription} required/>
                    </FormGroup>
                </Col>
            </Form.Row>
            <Form.Row>
                <FormGroup as={Col}>
                    <FormLabel>Notes</FormLabel>
                    {
                        o.notes && o.notes.map(n => <Card className={"mb-2"}>
                            <Card.Body className={"p-2"}>
                                <small>{n.text}</small>
                            </Card.Body>
                        </Card>)
                    }
                    <FormControl as={"textarea"} className={"w-100"} rows={3} placeholder={"New note"} size={"sm"} name={"newNoteText"} value={o.newNoteText || ""} onChange={this.itemFieldChanged}>

                    </FormControl>
                </FormGroup>
            </Form.Row>
        </div>
    }

}