import {Button, Form, InputGroup, Modal} from "react-bootstrap";
import React from "react";
import CalendarButton from "./CalendarButton";
import moment from "moment-timezone";
import {shortDatePattern} from "../../utils/Dates";

export default class DatePickerModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentValue: this.props.currentDate ? moment(this.props.currentDate).format('M/D/YYYY') : '',
            currentDate: this.props.currentDate || null,
            formValidated: false
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.show && !prevProps.show) {
            // reset text value to formatted value of selected date
            this.setState({formValidated: false, currentValue: moment(this.props.currentDate).format('M/D/YYYY')});
        }
    }

    ok = () => {
        let valid = document.getElementById("date-form").checkValidity();
        this.setState({formValidated: true});
        if (valid) {
            if (this.props.onOk) {
                this.props.onOk(this.state.currentDate);
                this.props.onHide();
            }
        }
    }

    parseDate = (dateStr) => {
        if (dateStr) {
            try {
                let m = moment(dateStr);
                if (m.isValid()) return m.toDate();
            } catch(err) { }
        }
        return null;
    }

    currentValueChanged = (e) => {
        let val = e.target.value;
        this.setState({currentValue: val});
        let dt = this.parseDate(val);
        if (dt) this.setState({currentDate: dt});
    }

    dateSelected = (dt) => {
        let strVal = dt ? moment(dt).format('M/D/YYYY') : '';
        this.setState({currentDate: dt, currentValue: strVal})
    }

    render() {
        return <Modal size={this.props.size || "md"} show={this.props.show} onHide={this.props.onHide}>
            <Modal.Header closeButton={true}>
                <Modal.Title>{this.props.title || "Select a date"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id={"date-form"} noValidate validated={this.state.formValidated}>
                    <InputGroup>
                        <Form.Control required={this.props.required != null ? this.props.required : true} pattern={shortDatePattern} value={this.state.currentValue || ''} onChange={this.currentValueChanged}/>
                        <InputGroup.Append>
                            <InputGroup.Text variant={"dark"}><CalendarButton selected={this.state.currentDate} onChange={this.dateSelected}/></InputGroup.Text>
                        </InputGroup.Append>
                        <Form.Control.Feedback type="invalid">
                            M/D/YYYY
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={this.props.onHide}>
                    Cancel
                </Button>
                <Button variant="dark" onClick={this.ok} disabled={this.state.currentDate == null}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    }
}