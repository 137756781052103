import {ItemModal} from "../common/ItemModal";
import {Col, Form} from "react-bootstrap";
import React from "react";
import {GlobalContext} from "../../GlobalContext";
import {Typeahead} from "react-bootstrap-typeahead";
import {OptionalSelect} from "../common/OptionalSelect";
import {USER_ICONS} from "../../utils/Enums";
import {Glyph} from "../common/Glyph";

export class AdminUserModal extends ItemModal {
    static contextType = GlobalContext;

    idField = 'userId';
    itemTypeName = 'User';

    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            roles: [],
            departments: []
        }
    }

    componentDidMount() {
        this.context.cachedGet("/lookup/role?orderBy=name")
            .then(d => {
                if (d.data) {
                    this.setState({roles: d.data});
                }
            });
        this.context.apiRequest("GET", "/users/departments")
            .then(d => {
                if (d.data) {
                    this.setState({departments: d.data});
                    console.log(d.data);
                }
            });
    }

    toggleRole = (roleName, active) => {
        let roles = this.state.item.roles;
        if (active === 1 && !roles.includes(roleName)) {
            roles.push(roleName);
        } else if (active === 0 && roles.includes(roleName)) {
            roles = roles.filter(r => r !== roleName);
        }
        let e = {target: {name: 'roles'}};
        this.itemFieldChanged(e, roles);
    };

    departmentSelected = (selectionArray) => {
        if(selectionArray && selectionArray.length > 0) {
            this.setItemField('department', selectionArray[0].name);
        }
    }

    renderFormContents = (u) => {
        return <div>
            <Form.Row>
                <Form.Group as={Col} controlId="firstName">
                    <Form.Label size="sm">
                        First Name
                    </Form.Label>
                    <Form.Control required size="sm" name={"firstName"} value={u.firstName || ''}
                                  onChange={this.itemFieldChanged}/>
                </Form.Group>
                <Form.Group as={Col} controlId="lastName">
                    <Form.Label size="sm">
                        Last Name
                    </Form.Label>
                    <Form.Control required size="sm" name={"lastName"} value={u.lastName || ''}
                                  onChange={this.itemFieldChanged}/>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} controlId="username">
                    <Form.Label size="sm">
                        Username
                    </Form.Label>
                    <Form.Control required size="sm" name={"username"} value={u.username || ''}
                                  onChange={this.itemFieldChanged}/>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} controlId="password1">
                    <Form.Label size="sm">
                        Password {u.userId > 0 ? ' (blank = no change)' : ''}
                    </Form.Label>
                    <Form.Control type={"password"} size="sm" name={"password1"} value={u.password1 || ''}
                                  onChange={this.itemFieldChanged} autoComplete="new-password"/>
                </Form.Group>
                <Form.Group as={Col} controlId="password2">
                    <Form.Label size="sm">
                        Confirm Password
                    </Form.Label>
                    <Form.Control type={"password"} size="sm" name={"password2"} value={u.password2 || ''}
                                  onChange={this.itemFieldChanged} autoComplete="new-password"/>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} controlId="email">
                    <Form.Label size="sm">
                        Email Address
                    </Form.Label>
                    <Form.Control size="sm" name={"email"} value={u.email || ''}
                                  onChange={this.itemFieldChanged}/>
                </Form.Group>
                <Form.Group as={Col} controlId="active" xs={2}>
                    <Form.Label size="sm">
                        Active
                    </Form.Label>
                    <Form.Check
                        type={"switch"}
                        id={"user-active"}
                        name={"active"}
                        aria-label={"Active"}
                        checked={u.active === 1}
                        onChange={e => this.itemFieldChanged(e, e.target.checked ? 1 : 0)}/>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} controlId="department">
                    <Form.Label size="sm">
                        Department
                    </Form.Label>
                    <Typeahead id={"department"} name={"department"} size={"sm"}
                                    labelKey={'name'}
                                    options={this.state.departments}
                                    defaultInputValue={u.department || ''}
                                    placeholder={"Department Name"}
                                    onChange={this.departmentSelected}
                                    align={"left"}
                                    allowNew={true}/>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} controlId="customColor">
                    <Form.Label size="sm">
                        Custom Color
                    </Form.Label>
                    <Form.Control type={"color"} size={"sm"}name={"customColor"} value={u.customColor || ''}
                                  onChange={this.itemFieldChanged}/>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} controlId="customColor" md={10}>
                    <Form.Label size="sm">
                        Icon
                    </Form.Label>
                    <OptionalSelect name={"iconName"} options={USER_ICONS} labelKey={"value"} valueKey={"value"} canSelectNone={true} noneValue={''} noneLabel={'None'} value={u.iconName || ''} onChange={this.itemFieldChanged}/>
                </Form.Group>
                <Col className={"text-right"}>{u.iconName && <Glyph name={u.iconName} style={{fontSize: "3rem"}}/>}</Col>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} controlId="active">
                    <Form.Label size="sm">
                        Roles
                    </Form.Label>
                    {
                        this.state.roles.map(r => <Form.Check key={r.roleId}
                            type={'checkbox'}
                            id={`role-${r.roleId}`}
                            label={r.name}
                            checked={u.roles && u.roles.includes(r.name)}
                            onChange={e => this.toggleRole(r.name, e.target.checked ? 1 : 0)}/>)
                    }
                </Form.Group>
            </Form.Row>
        </div>;
    };

}