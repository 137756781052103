import React from "react";
import {GlobalContext} from "../../GlobalContext";
import {Accordion, Card, Col, Row} from "react-bootstrap";
import {withRouter} from 'react-router-dom';
import {SpinnerWrapper} from "../common/SpinnerWrapper";
import LienCaseView from "./LienCaseView";
import {Glyph} from "../common/Glyph";
import {nowMoment} from "../../utils/Dates";

class PatientLienList extends React.Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.state = {
            patient: null,
            lienCases: [],
            loading: true,
            accordionKey: null
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let patientId = this.props.match.params.patientId;
        let prevPatientId = prevProps.match.params.patientId;
        if (!this.state.loading && prevPatientId !== patientId) {
            this.loadPatient();
        }
    }

    componentDidMount() {
        this.loadPatient();
    }

    loadPatient = () => {
        let patientId = this.props.match.params.patientId;
        if (patientId > 0) {
            this.setState({patient: null, lienCases: [], loading: true}, () => {
                this.context.apiRequest("GET", `/patients/${patientId}`)
                    .then(d => {
                        if (d.data && d.data.patientId) {
                            this.setState({patient: d.data});
                        }
                    });
                this.context.apiRequest("GET", `/patients/${patientId}/lienCases`)
                    .then(d => {
                        if (d && d.data) {
                            this.setState({lienCases: d.data, accordionKey: d.data.length > 0 ? d.data[0].id : null}, () => this.setState({loading: false}));
                        }
                    });
            });
        } else {
            this.setState({patient: null, lienCases: [], loading: true});
        }
    };

    newLien = () => {
        return {
            id: -1,
            patientId: this.state.patient.patientId,
            dateOfInjury: nowMoment().format('M/D/YY'),
            attorneyId: null,
            attorneyName: '(Unspecified Attorney)',
            attorneyGroupId: null,
            created: null,
            firstApptDateTime: null,
            paidDate: '',
            status: 'PENDING',
            paidTo: '',
            externalCaseNumber: '',
            flagged: 0,
            billType: '',
            caseManagerName: ''
        }
    }

    addLienCase = () => {
        let cases = this.state.lienCases;
        let lien = this.newLien();
        cases.unshift(lien);
        this.setState({lienCases: cases, accordionKey: lien.id});
    }

    accordionSelect = (e) => {
        this.setState({accordionKey: e});
    }

    setCase = (index, lc) => {
        let cases = [...this.state.lienCases];
        cases[index] = lc;
        this.setState({lienCases: cases, accordionKey: lc.id});
    }

    cancelEdit = (index) => {
        let cases = this.state.lienCases;
        if (cases[index].id === -1) {
            cases.splice(index, 1);
            this.setState({accordionKey: cases.length > 0 ? cases[0].id : null});
        } else {
            this.context.apiRequest("GET", `/liens/${cases[index].id}`)
                .then(resp => {
                    if (resp.data) {
                        cases[index] = resp.data;
                        this.setState({lienCases: cases});
                    }
                })
        }
    }

    render() {
        let p = this.state.patient;
        let cases = this.state.lienCases;

        return <Card>
            <Card.Body>
                <Card.Title>
                    <Row>
                        <Col xs={10}>
                            <h4>{p ? `Patient: ${p.firstName} ${p.lastName} ${p.dob || ''}` : `Loading...`}</h4>
                        </Col>
                        <Col xs={2} className={"text-right"}>
                            <Glyph name={"plus-circle"} className={"clickable"} onClick={this.addLienCase}/>
                        </Col>
                    </Row>
                </Card.Title>
                <SpinnerWrapper visible={this.state.loading}>
                    {
                        cases && cases.length > 0 ?
                        <Accordion onSelect={this.accordionSelect} activeKey={this.state.accordionKey}>
                            {
                                cases.map((lc, i) =>
                                    <Card key={lc.id}>
                                        <Accordion.Toggle as={Card.Header} eventKey={lc.id} className={"clickable"}>
                                            <Row>{`${lc.dateOfInjury || '[No DOI]'} - ${lc.linkedAttorneyName || lc.attorneyName || '[No Attorney Specified]'}`}</Row>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey={lc.id}>
                                            <Card.Body><LienCaseView lienCase={lc} patient={p} onSave={(c => this.setCase(i, c))} onCancel={() => this.cancelEdit(i)} onDelete={this.loadPatient}/></Card.Body>
                                        </Accordion.Collapse>
                                    </Card>)
                            }
                        </Accordion>
                        : <Row><Col>No liens for this patient.</Col></Row>
                    }
                </SpinnerWrapper>
            </Card.Body>
        </Card>;
    }
}

export default withRouter(PatientLienList);
