import React from 'react';
import {GlobalContext} from "../../../GlobalContext";
import {Col, Form} from "react-bootstrap";
import {shortDatePattern, todayStr, validateMMDDYYYY} from "../../../utils/Dates";
import {OptionalSelect} from "../OptionalSelect";
import {Glyph} from "../Glyph";
import {DateInput} from "../DateInput";

export class AuthActivityRow extends React.Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.state = {
            activity: this.props.activity,
            editing: this.props.editing,
            activityTypes: [],
            activityStatuses: []
        }
    }

    componentDidMount() {
        this.context.cachedGet(`/lookup/auth_activity_status?filter=${encodeURIComponent('active=1')}&orderBy=status`, 300)
            .then(d => this.setState({activityStatuses: d.data}));
        this.context.cachedGet(`/lookup/auth_contact_type?orderBy=contactType`, 300)
            .then(d => this.setState({activityTypes: d.data}));
    };

    fieldChanged = (e) => {
        let fieldName = e.target.name;
        let value = e.target.value;
        this.setObjectField(fieldName, value);
    };

    setObjectField = (fieldName, value) => {
        if (this.state.activity) {
            this.setState(prvState => ({
                activity: {
                    ...prvState.activity,
                    [fieldName]: value
                }
            }));
        }
        if (this.props.fieldUpdated) {
            this.props.fieldUpdated(this.state.activity.authActivityId, fieldName, value);
        }
    };

    edit = () => {
        this.setState({editing: true});
    };

    cancelEdit = () => {
        this.setState({editing: false, activity: this.props.activity});
        if (this.props.onCancelEdit) {
            this.props.onCancelEdit(this.state.activity.authActivityId);
        }
    };

    validateForm = () => {
        let valid = true;
        let act = this.state.activity;
        if (!act.activityDate || !validateMMDDYYYY(act.activityDate)) {
            valid = false;
        }
        if (!act.statusCode) {
            valid = false;
        }
        if (!act.contactTypeCode) {
            valid = false;
        }
        return valid;
    }

    render() {
        let a = this.state.activity || {};
        return <Form.Row key={a.authActivityId} className={"mb-1"}>
            <Col xs={3}>
                <DateInput name={"activityDate"} value={a.activityDate || todayStr()}
                              pattern={shortDatePattern} placeholder={"M/D/YYYY"} onChange={this.fieldChanged} size={"sm"} disabled={!this.state.editing}/>
                <Form.Control.Feedback type={"invalid"}>M/D/YYYY e.g 2/28/2021</Form.Control.Feedback>
            </Col>
            <Col xs={3}>
                <OptionalSelect name={"statusCode"} value={a.statusCode || ''} options={this.state.activityStatuses} labelKey={"status"} valueKey={"statusCode"}
                                canSelectNone={false} noneLabel={"Activity"} onChange={this.fieldChanged} disabled={!this.state.editing}/>
            </Col>
            <Col xs={4}>
                <OptionalSelect name={"contactTypeCode"} value={a.contactTypeCode || ''} options={this.state.activityTypes} labelKey={"contactType"} valueKey={"contactTypeCode"}
                                canSelectNone={false} noneLabel={"Status"} onChange={this.fieldChanged} disabled={!this.state.editing}/>
            </Col>
            {this.state.editing ?
                <Col xs={2} className={"d-flex align-items-center"}>
                    <Glyph name={"x-square"} className={"clickable ml-2"} onClick={this.cancelEdit}/>
                </Col>
                :
                <Col xs={2} className={"d-flex align-items-center"}>
                    <Glyph name={"pencil-square"} className={"clickable ml-2"} onClick={this.edit}/>
                </Col>
            }
        </Form.Row>
    }
}