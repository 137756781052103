import {ReferralModal} from "./ReferralModal";
import React from "react";
import {Glyph} from "../Glyph";
import {ItemList} from "../ItemList";
import {formatDate, todayStr} from "../../../utils/Dates";

export class PatientReferralList extends ItemList {

    idField = 'referralId';
    updateProperty = 'patientId';
    itemTypeName = 'Referral';
    newItem = () => {
        return {
            referralId: 0,
            receivedDate: todayStr(),
            diagnoses: [],
            activities: [],
            patientId: this.props.patientId,
            visitTypeCode: 'C',
            visitSubtypeCode: 'RV'
        };
    };
    headerColumns = ['Received', 'Status', 'Source', 'Owner'];
    headerColumnClasses = ['text-center', 'text-center', 'w-25', 'w-100'];
    dataColumnClasses = ['text-center', 'text-center', '', ''];
    addSupported = true;
    editSupported = true;
    deleteSupported = false;
    paging = false;
    modalClass = ReferralModal;

    getBaseUrl = () => {
        return `/patients/${this.props.patientId}/referrals`;
    };

    /**
     * @param r
     * @returns the contents of the table cells for a particular item.
     */
    getItemCellContents = (r) => {
        let statusIcon = null;
        let statusIconVariant = null;

        switch (r.statusCategory) {
            case 'scheduled':
                statusIcon = 'calendar-check';
                statusIconVariant = 'success';
                break;
            case 'uts':
                statusIcon = 'calendar-x';
                statusIconVariant = 'danger';
                break;
            case 'utc':
                statusIcon = 'clock';
                statusIconVariant = 'warning';
        }

        return [formatDate(r.receivedDate),
                statusIcon == null ? '' : <Glyph variant={statusIconVariant} name={statusIcon} label={r.status}/>,
                `${r.p1Last}${r.p1First ? ', ' : ''}${r.p1First}`, r.ownerUserName];
    };

}