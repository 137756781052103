import {ItemList} from "../common/ItemList";
import {AdminUserModal} from "./AdminUserModal";

export class AdminUserList extends ItemList {
    baseUrl = `/users`;
    idField = 'userId';
    itemTypeName = 'User';
    newItem = () => {
        return {
            userId: 0,
            username: '',
            firstName: '',
            lastName: '',
            password1: '',
            password2: '',
            email: '',
            active: 1,
            roles: []
        };
    };
    headerColumns = ['Last Name', 'First Name', 'Username', 'Status'];
    headerColumnClasses = null;
    dataColumnClasses = null;
    addSupported = true;
    editSupported = true;
    deleteSupported = false;
    paging = false;
    modalClass = AdminUserModal;

    getItemCellContents = (item) => {
        return [item.lastName, item.firstName, item.username, item.active === 1 ? 'Active' : 'Inactive'];
    };
}