import React from "react";
import {GlobalContext} from "../../GlobalContext";
import {Button, Col, Form, Table} from "react-bootstrap";

export class AdminSocTestForm extends React.Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);

        this.state = {
            diagnosisText: '',
            icds: '',
            results: null
        };
    }

    fieldChanged = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    ok = () => {
        this.context.apiRequest("POST", "/soc/test", {diagnosisText: this.state.diagnosisText, icds: this.state.icds})
            .then(d => {
                if (d && d.data) {
                    this.setState({results: d.data});
                }
            })
    };

    render() {
        return <Form id={"soc-test-form"}>
            <Form.Row>
                <Form.Group as={Col} controlId="diagnosisText" md={6}>
                    <Form.Label size="sm">
                        Diagnosis Text:
                    </Form.Label>
                    <Form.Control required size="sm" name={"diagnosisText"} value={this.state.diagnosisText}
                                  onChange={this.fieldChanged}/>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} controlId="icds" md={6}>
                    <Form.Label size="sm">
                        ICD10 Codes (separated by spaces):
                    </Form.Label>
                    <Form.Control required size="sm" name={"icds"} value={this.state.icds}
                                  onChange={this.fieldChanged}/>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Col><Button variant={"dark"} onClick={this.ok}>OK</Button></Col>
            </Form.Row>
            <Form.Row className={"mt-3"}>
                <Col md={6}>
                    {
                        this.state.results != null ? <Table striped size={"sm"}>
                            <thead>
                                <tr><th>Recommended Procedures</th></tr>
                            </thead>
                            <tbody>{
                                this.state.results.map((r, i) => <tr key={i}><td>{r.type} {r.subtype}</td></tr>)
                            }</tbody></Table>
                            : <span/>
                    }
                </Col>
            </Form.Row>
        </Form>
    }
}