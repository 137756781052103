import React from 'react';
import {ItemList} from "../common/ItemList";
import {LienPaymentModal} from "./LienPaymentModal";
import {LienChargeModal} from "./LienChargeModal";
import {currencyFormat} from "../../utils/Currency";
import {todayStr} from "../../utils/Dates";
import {Col, Row, Table} from "react-bootstrap";

export default class LienTransactionList extends ItemList {
    idField = 'id';
    itemTypeName = this.props.txTypeName;
    newItem = () => {
        return {
            id: 0,
            paymentDate: todayStr(true),
            chargeDate: todayStr(true),
            amount: '',
            description: '',
            adjustment: false
        };
    };
    headerColumns = ['Date', 'Description', 'Amount'];
    headerColumnClasses = ['', '', 'text-right'];
    dataColumnClasses = ['', '', 'text-right'];
    addSupported = true;
    editSupported = false;
    deleteSupported = true;
    paging = false;
    modalClass = this.props.txType === 'payments' ? LienPaymentModal : LienChargeModal;
    updateProperty = 'lienId';

    getBaseUrl = () => {
        return `/liens/${this.props.lienId}/${this.props.txType}`;
    };

    getItemCellContents = (tx) => {
        return [
            this.wrapAdjustment(tx.adjustment, this.props.txType === 'payments' ? tx.paymentDate : tx.chargeDate),
            this.wrapAdjustment(tx.adjustment, tx.description),
            this.wrapAdjustment(tx.adjustment, currencyFormat.format(tx.amount))
        ];
    };

    renderAfter = () => {
        let total = 0.00;
        for (const d of this.state.items) total += d.amount;

        return <Row>
            <Col>
                <Table>
                    <tbody>
                    <tr>
                        <td></td>
                        <td className={'font-weight-bold text-right'}>Total</td>
                        <td className={'font-weight-bold text-right'}>{currencyFormat.format(total)}</td>
                        <td></td>
                    </tr>
                    </tbody>
                </Table>
            </Col>
        </Row>;
    }

    wrapAdjustment = (adj, txt) => {
        if (adj) {
            return <span className={'font-italic'}>{txt}</span>
        }
        return txt;
    }
}