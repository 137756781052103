import React from "react";
import {Col, Form} from "react-bootstrap";
import {ItemModal} from "./ItemModal";

export class LocationModal extends ItemModal {

    idField = 'locationId';
    itemTypeName = 'Location';

    renderFormContents = (l) => {
        return <div>
            <Form.Row>
                <Form.Group as={Col} controlId="addr1">
                    <Form.Label size="sm">
                        Address Line 1
                    </Form.Label>
                    <Form.Control required size="sm" name={"addr1"} value={l.addr1 || ''}
                                  onChange={this.itemFieldChanged}/>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} controlId="addr2">
                    <Form.Label size="sm">
                        Address Line 2
                    </Form.Label>
                    <Form.Control size="sm" name={"addr2"} value={l.addr2 || ''}
                                  onChange={this.itemFieldChanged}/>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} controlId="city" xs={8}>
                    <Form.Label size="sm">
                        City
                    </Form.Label>
                    <Form.Control size="sm" name={"city"} value={l.city || ''}
                                  onChange={this.itemFieldChanged}/>
                </Form.Group>
                <Form.Group as={Col} controlId="state" xs={2}>
                    <Form.Label size="sm">
                        State
                    </Form.Label>
                    <Form.Control size="sm" name={"state"} value={l.state || ''}
                                  onChange={this.itemFieldChanged}/>
                </Form.Group>
                <Form.Group as={Col} controlId="zip" xs={2}>
                    <Form.Label size="sm">
                        Zip
                    </Form.Label>
                    <Form.Control size="sm" name={"zip"} value={l.zip || ''}
                                  onChange={this.itemFieldChanged}/>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} controlId="phone">
                    <Form.Label size="sm">
                        Phone
                    </Form.Label>
                    <Form.Control size="sm" name={"phone"} value={l.phone || ''}
                                  onChange={this.itemFieldChanged}/>
                </Form.Group>
                <Form.Group as={Col} controlId="fax">
                    <Form.Label size="sm">
                        Fax
                    </Form.Label>
                    <Form.Control size="sm" name={"fax"} value={l.fax || ''}
                                  onChange={this.itemFieldChanged}/>
                </Form.Group>
                <Form.Group as={Col} controlId="active" xs={1}>
                    <Form.Label size="sm">
                        Active
                    </Form.Label>
                    <Form.Check
                        type={"switch"}
                        id={"loc-active"}
                        name={"active"}
                        aria-label={"Active"}
                        checked={l.active === 1}
                        onChange={e => this.itemFieldChanged(e, e.target.checked ? 1 : 0)}/>
                </Form.Group>
            </Form.Row>
        </div>;
    };
}