import React from 'react';
import {GlobalContext} from "../../GlobalContext";
import {Col, Container, Row, Table} from "react-bootstrap";
import {Glyph} from "../common/Glyph";


export class ReportsHome extends React.Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.state = {
            reports: []
        };
    }

    componentDidMount() {
        this.loadReports();
    }

    loadReports = () => {
        this.context.apiRequest("GET", "/reports")
            .then(d => {
                if (d.data) this.setState({reports: d.data});
            })
    };

    generateReportUrl = (fname) => {
        return "/";
    };

    render() {
        return <Container fluid={true}>
            <Row>
            <Col md={6}>
                <Table striped>
                    <thead>
                    <tr>
                        <th>Report</th>
                        <th className={"text-center"}>Automation</th>
                        <th className={"text-center"}>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.state.reports.map((r,i) => <tr key={i}>
                            <td className={"font-weight-bold"}>{r.displayName}</td>
                            <td>
                                {
                                    r.scheduledJobs && r.scheduledJobs.length > 0 ?
                                        <ul>
                                            { r.scheduledJobs.map((j, k) => <li key={k}>{j.cronEnglish}, to {j.settings.to}{j.enabled ? '' : ' (disabled)'}</li>) }
                                        </ul>
                                        : <span/>
                                }
                            </td>
                            <td className={"text-center"}><a href={`/viewReport/${encodeURIComponent(r.fileName)}`} target={"_blank"}>
                                <Glyph name={"file-bar-graph"} className={"mr-2"} label={"Run Report"}/></a>
                            </td>
                        </tr>)
                    }
                    </tbody>
                </Table>
            </Col>
        </Row>
        </Container>;
    }
}
