import React from "react";

export const nlToBr = (str) => {
    if (!str) return '';
    let tokens = str.split('\n');
    if (tokens.length == 1) {
        return str;
    }
    else {
        return tokens.map((t,i) => <span key={i}>
            {i > 0 && <br/>}
            {t}
        </span >)
    }
}

