import {ItemList} from "../common/ItemList";
import {LienNoteModal} from "./LienNoteModal";

export default class LienNotesList extends ItemList {
    idField = 'id';
    itemTypeName = 'Note';
    newItem = () => {
        return {
            id: 0,
            text: ''
        };
    };
    headerColumns = ['Date/User', 'Comment'];
    headerColumnClasses = ['w-25', ''];
    addSupported = true;
    editSupported = false;
    deleteSupported = false;
    paging = false;
    modalClass = LienNoteModal;
    updateProperty = 'lienId';

    getBaseUrl = () => {
        return this.props.taskId ? `/liens/${this.props.lienId}/tasks/${this.props.taskId}/notes` : `/liens/${this.props.lienId}/notes`;
    };

    getItemCellContents = (note) => {
        return [
            `${note.createdDateTime}, ${note.userFirst} ${note.userLast}`,
            note.text
        ];
    };
}