import React from 'react';
import {GlobalContext} from "../../GlobalContext";
import {Card, Table} from "react-bootstrap";
import {Glyph} from "../common/Glyph";
import moment from "moment-timezone";

export class PendingLiensList extends React.Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.state = {
            listTitle: "Loading Liens List...",
            liens: []
        };
    }

    timer = null;

    componentDidMount() {
        this.loadList();
    }

    componentWillUnmount() {
        if (this.timer) clearTimeout(this.timer);
    }

    loadList = () => {
        if (this.context.authenticated) {
            this.context.apiRequest("GET", '/liens/pending-list', null, true)
                .then(d => {
                    if (d.data) {
                        this.setState({liens: d.data, listTitle: "Awaiting Approval"})
                    }
                })
                .finally(() => {
                    this.timer = setTimeout(this.loadList, 10000);
                });
        }
    }

    render() {
        return (
            <Card className={this.props.className}>
                <Card.Header>{this.state.listTitle}</Card.Header>
                <Card.Body>
                    <Table size={"sm"} hover>
                        <thead>
                        <tr>
                            <th>&nbsp;</th>
                            <th className={"small"}><strong>Patient</strong></th>
                            <th className={"small"}><strong>Attorney</strong></th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.liens.map(lc => {
                            let days = moment.duration(moment().diff(moment(lc.created))).as('days');
                            return <tr key={lc.caseId} className={"clickable"} onClick={e => this.props.patientSelected(lc.patientId)}>
                                <td>{
                                    days > 7 && <Glyph name={"circle-fill"} className={days > 14 ? "text-danger": "text-warning"}/>
                                }</td>
                                <td>{lc.ptFirst} {lc.ptLast}</td>
                                <td>{lc.linkedAttorneyGroupName || lc.lienAttorneyName || (lc.linkedAttorneyFirst ? `${lc.linkedAttorneyFirst} ${lc.linkedAttorneyLast}` : 'None')}</td>
                            </tr>
                        })}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        );
    }
}