import React from 'react';
import {GlobalContext} from "../../GlobalContext";
import {AsyncTypeahead} from "react-bootstrap-typeahead";

export class ProviderSearch extends React.Component {
    static contextType = GlobalContext;
    ref = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            cleared: this.props.cleared,
            options: []
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.cleared !== this.props.cleared && this.props.cleared) {
            this.ref.current.clear();
        }
    }

    doSearch = (query) => {
        this.context.apiRequest("GET", "/providers/search?q="+encodeURIComponent(query)+"&internal=" + (this.props.internal ? "true" : "false"))
            .then(d => {
                if(d.data) this.setState({options: d.data});
                this.setState({loading: false});
            })
    }

    selected = (selectionArray) => {
        if(selectionArray && selectionArray.length > 0) {
            if(this.props.providerSelected) {
                this.props.providerSelected(Number(selectionArray[0].id), selectionArray[0].label);
            }
        }
    }

    render() {
        return (
            <AsyncTypeahead id={this.props.id || 'provider-search'} size={this.props.size}
                            className={this.props.className}
                            labelKey={'label'}
                            isLoading={this.state.loading}
                            onSearch={this.doSearch}
                            options={this.state.options}
                            onChange={this.selected}
                            defaultInputValue={this.props.initialValue}
                            placeholder={this.props.placeholder || "Search Providers"}
                            align={"left"}
                            filterBy={() => true}
                            disabled={this.props.disabled}
                            isValid={this.props.isValid}
                            isInvalid={this.props.isInvalid}
                            ref={this.ref}
                            inputProps={{required: this.props.required || false}}/>
        )
    }
}