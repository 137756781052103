import {GlobalContext} from "../../GlobalContext";
import {ItemList} from "../common/ItemList";
import {AdminSocRuleModal} from "./AdminSocRuleModal";

export class AdminSocRuleList extends ItemList {
    static contextType = GlobalContext;

    baseUrl = `/soc/rules`;
    idField = 'id';
    itemTypeName = 'Rule';
    newItem = () => {
        return {
            id: 0,
            name: '',
            settings: {
                "ruleType": "DIAGNOSIS_CONTAINS",
                "matchStrings": [],
                "icds": [],
                "recommendedProcedures": []
            }
        };
    };
    headerColumns = ['Rule Name'];
    headerColumnClasses = null;
    dataColumnClasses = null;
    addSupported = true;
    editSupported = true;
    deleteSupported = true;
    paging = false;
    modalClass = AdminSocRuleModal;

    getItemCellContents = (item) => {
        return [item.name];
    };
}