import React from 'react';
import {GlobalContext} from "../../GlobalContext";
import {AsyncTypeahead} from "react-bootstrap-typeahead";

export class ICD10Search extends React.Component {
    static contextType = GlobalContext;
    ref = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            options: []
        };
    }

    doSearch = (query) => {
        this.context.apiRequest("GET", "/icd10/search?q="+encodeURIComponent(query))
            .then(d => {
                if(d.data) this.setState({options: d.data});
                this.setState({loading: false});
            })
    }

    selected = (selectionArray) => {
        if(selectionArray && selectionArray.length > 0) {
            if(this.props.icd10Selected) {
                this.props.icd10Selected(selectionArray[0].code, selectionArray[0].description);
            }
            this.ref.current.clear();
        }
    }

    render() {
        return (
            <AsyncTypeahead id={this.props.id} size={this.props.size}
                            className={this.props.className}
                            labelKey={'displayName'}
                            isLoading={this.state.loading}
                            onSearch={this.doSearch}
                            options={this.state.options}
                            onChange={this.selected}
                            defaultInputValue={this.props.initialValue}
                            placeholder={this.props.placeholder || 'Search ICD10'}
                            ref={this.ref}
                            align={"left"}
                            disabled={this.props.disabled}/>
        )
    }
}