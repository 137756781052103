import React from "react";
import {Col, Form} from "react-bootstrap";
import {ItemModal} from "../common/ItemModal";

export class LienNoteModal extends ItemModal {

    idField = 'id';
    itemTypeName = 'Note';

    renderFormContents = (t) => {
        return <div>
            <Form.Row>
                <Form.Group as={Col} controlId="text">
                    <Form.Control as={"textarea"} size="sm" name={"text"} value={t.text}
                                  onChange={this.itemFieldChanged}/>
                </Form.Group>
            </Form.Row>
        </div>;
    };
}