import React from 'react';
import {GlobalContext} from "../../GlobalContext";
import {Alert, Button, Card, Col, Form, ListGroup, Row} from "react-bootstrap";
import {Link, withRouter} from 'react-router-dom';
import {ProviderMonthlyReferralList} from "./ProviderMonthlyReferralList";
import {OptionalSelect} from "../common/OptionalSelect";
import {Glyph} from "../common/Glyph";
import {GroupSearchModal} from "../common/GroupSearchModal";

class ProviderView extends React.Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.state = {
            editing: false,
            loading: false,
            provider: null,
            validated: false,
            referralStats: [],
            referralStatsLoading: true,
            titles: [],
            providerTypes: [],
            specialties: [],
            groupSearchModalVisible: false,
            errorMessages: []
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let providerId = this.props.match.params.providerId;
        let prevProviderId = prevProps.match.params.providerId;
        if (!this.state.loading && prevProviderId !== providerId) {
            this.loadProvider(providerId);
        }
    }

    componentDidMount() {
        let providerId = this.props.match.params.providerId;
        console.log(`provider view for id ${providerId}`);
        this.loadProvider(providerId);
        this.context.cachedGet(`/lookup/provider_type?filter=${encodeURIComponent('active=1')}`, 300)
            .then(d => this.setState({providerTypes: d.data}));
        this.context.cachedGet(`/lookup/specialty`, 300)
            .then(d => this.setState({specialties: d.data}));
        this.context.cachedGet(`/lookup/provider_title`, 300)
            .then(d => this.setState({titles: d.data}));
    }

    loadProvider = () => {
        let providerId = this.props.match.params.providerId;
        this.setState({provider: null, referralStats: [], referralStatsLoading: true});
        if (providerId > 0) {
            this.context.apiRequest("GET", `/providers/${providerId}`)
                .then(d => {
                    if (d.data && d.data.providerId) {
                        this.setState({provider: d.data, loading: false, validated: false, editing: false});
                    }
                });

            // concurrently load referrals
            this.context.apiRequest("GET", `/providers/${providerId}/referralStats`)
                .then(d => {
                    if (d.data) {
                        this.setState({referralStats: d.data, referralStatsLoading: false});
                    }
                });

        } else {
            this.setState({provider: this.emptyProvider(), loading: false, validated: false, editing: true});
        }
    };

    emptyProvider = () => {
        return {
            providerId: 0,
            firstName: '',
            lastName: '',
            externalProviderId: '',
            npid: '',
            specialtyCode: null,
            phone: '',
            fax: '',
            email: '',
            internal: 0,
            notes: '',
            worksForProviderId: null,
            groups: [],
            active: 1
        }
    }

    edit = () => {
        this.setState({editing: true});
    };

    cancelEdit = () => {
        if (this.state.provider.providerId === 0) {
            // We were creating a new provider, revert back to marketing home
            this.props.history.push("/marketing");
        } else {
            this.loadProvider();
            this.setState({editing: false});
        }
    };

    save = (e) => {
        if (e) e.preventDefault();
        if (!this.validateForm()) {
            return;
        }
        let p = this.state.provider;
        this.context.apiRequest("POST", `/providers/${p.providerId}`, p)
            .then(d => {
                if (d.data && d.data.providerId) {
                    this.setState({provider: d.data, loading: false, editing: false, validated: false});
                }
            });
    };

    validateForm = () => {
        let errors = [];
        let valid = true;
        let form = document.getElementById('provider-form');
        valid &= form.checkValidity();
        if (this.state.provider.groups.length == 0) {
            errors.push('Providers must belong to a group.');
            valid = false;
        }
        this.setState({validated: true, errorMessages: errors});
        return valid;
    };

    providerFieldChanged = (e, overrideValue) => {
        let fieldName = e.target.name;
        let value = overrideValue === undefined ? e.target.value : overrideValue;
        this.setState(prvState => ({
            provider: {
                ...prvState.provider,
                [fieldName]: value
            }
        }))
    };

    removeGroup = (e, groupId) => {
        e.preventDefault();
        let newGroups = this.state.provider.groups || [];
        newGroups = newGroups.filter(g => g.groupId !== groupId);
        this.providerFieldChanged({target: {name: "groups"}}, newGroups);
    };

    showAddGroupModal = () => {
        this.setState({groupSearchModalVisible: true});
    };

    groupSearchModalOk = (groupId, groupName) => {
        if (groupId) {
            this.setState({groupSearchModalVisible: false});
            let newGroups = this.state.provider.groups || [];
            newGroups.push({groupId, name: groupName});
            this.providerFieldChanged({target: {name: "groups"}}, newGroups);
        }
    }

    render() {
        let p = this.state.provider;
        return (
            <Card>
                <Card.Body>
                    <Card.Title>
                        <Row>
                            <Col xs={8}>
                                <h4><Glyph name={"person-fill"} className={"mr-2"}/>{p ? `${p.firstName} ${p.lastName}` : `Loading...`}</h4>
                            </Col>
                            <Col className={"text-right"}>
                                {this.state.editing ?
                                    <Button variant={"secondary"} onClick={this.cancelEdit}>Cancel</Button> : <span/>}
                                {this.state.editing ?
                                    <Button variant={"dark"} className={"ml-2"} onClick={this.save}>Save</Button> :
                                    <span/>}
                                {this.state.editing ? <span/> :
                                    <Button variant={"dark"} onClick={this.edit}>Edit</Button>}
                            </Col>
                        </Row>
                    </Card.Title>
                    {p ? (
                        <div>
                            <Form id={"provider-form"} noValidate validated={this.state.validated} onSubmit={this.save}>
                                {
                                    this.state.errorMessages ? <Form.Row>
                                        {this.state.errorMessages.map((e,i) => <Alert key={i} variant={'danger'}>{e}</Alert>)}
                                    </Form.Row> : <></>
                                }
                                <Form.Row>
                                    <Form.Group as={Col} controlId="firstName">
                                        <Form.Label size="sm">
                                            First name
                                        </Form.Label>
                                        <Form.Control required size="sm" name={"firstName"} value={p.firstName}
                                                      onChange={this.providerFieldChanged}
                                                      disabled={!this.state.editing}/>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="lastName">
                                        <Form.Label size="sm">
                                            Last name
                                        </Form.Label>
                                        <Form.Control required size="sm" name={"lastName"} value={p.lastName}
                                                      onChange={this.providerFieldChanged}
                                                      disabled={!this.state.editing}/>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="titleCode">
                                        <Form.Label size="sm">
                                            Title
                                        </Form.Label>
                                        <OptionalSelect name={"titleCode"} value={p.titleCode} options={this.state.titles} valueKey={"titleCode"} labelKey={"titleCode"} onChange={this.providerFieldChanged} noneLabel={"Select a title"} disabled={!this.state.editing}/>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="providerTypeCode">
                                        <Form.Label size="sm">
                                            Type
                                        </Form.Label>
                                        <OptionalSelect name={"providerTypeCode"} value={p.providerTypeCode} options={this.state.providerTypes} valueKey={"providerTypeCode"} labelKey={"providerType"} onChange={this.providerFieldChanged} noneLabel={"Select a type"} disabled={!this.state.editing}/>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="externalProviderId">
                                        <Form.Label size="sm">
                                            Intergy ID
                                        </Form.Label>
                                        <Form.Control size="sm" name={"externalProviderId"}
                                                      value={p.externalProviderId || ''}
                                                      onChange={this.providerFieldChanged}
                                                      disabled={!this.state.editing}/>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="npid">
                                        <Form.Label size="sm">
                                            NPI
                                        </Form.Label>
                                        <Form.Control required size="sm" name={"npid"}
                                                      value={p.npid || ''}
                                                      onChange={this.providerFieldChanged}
                                                      disabled={!this.state.editing}/>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="internal" xs={1}>
                                        <Form.Label size="sm">
                                            Internal
                                        </Form.Label>
                                        <Form.Check
                                            type={"switch"}
                                            id={"internal"}
                                            name={"internal"}
                                            aria-label={"Internal"}
                                            checked={p.internal === 1}
                                            disabled={!this.state.editing}
                                            onChange={e => this.providerFieldChanged(e, e.target.checked ? 1 : 0)}/>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="active" xs={1}>
                                        <Form.Label size="sm">
                                            Active
                                        </Form.Label>
                                        <Form.Check
                                            type={"switch"}
                                            id={"active"}
                                            name={"active"}
                                            aria-label={"Active"}
                                            checked={p.active === 1}
                                            disabled={!this.state.editing}
                                            onChange={e => this.providerFieldChanged(e, e.target.checked ? 1 : 0)}/>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="phone">
                                        <Form.Label size="sm">
                                            Phone
                                        </Form.Label>
                                        <Form.Control size="sm" name={"phone"} value={p.phone || ""}
                                                      onChange={this.providerFieldChanged}
                                                      disabled={!this.state.editing}/>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="fax">
                                        <Form.Label size="sm">
                                            Fax
                                        </Form.Label>
                                        <Form.Control size="sm" name={"fax"} value={p.fax || ""}
                                                      onChange={this.providerFieldChanged}
                                                      disabled={!this.state.editing}/>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="email">
                                        <Form.Label size="sm">
                                            Email
                                        </Form.Label>
                                        <Form.Control size="sm" name={"email"} value={p.email || ""}
                                                      onChange={this.providerFieldChanged}
                                                      disabled={!this.state.editing}/>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="specialtyCode">
                                        <Form.Label size="sm">
                                            Specialty
                                        </Form.Label>
                                        <OptionalSelect name={"specialtyCode"} value={p.specialtyCode} options={this.state.specialties} valueKey={"specialtyCode"} labelKey={"specialty"} onChange={this.providerFieldChanged} noneLabel={"Select a specialty"} disabled={!this.state.editing}/>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="notes">
                                        <Form.Label size="sm">
                                            Notes
                                        </Form.Label>
                                        <Form.Control
                                            name={"notes"}
                                            as="textarea"
                                            size={"sm"}
                                            style={{ height: '75px' }}
                                            onChange={this.providerFieldChanged}
                                            value={p.notes || ''}
                                            disabled={!this.state.editing}
                                        />
                                    </Form.Group>

                                </Form.Row>
                            </Form>
                            <h5 className={"mt-3"}>Member of Groups{this.state.editing ? <Glyph name={"plus-circle"} className={"clickable ml-3"} onClick={this.showAddGroupModal} label={"Add to Group"}/> : <span/>}</h5>
                            <Row>
                                <Col lg={6}>
                                    <GroupSearchModal show={this.state.groupSearchModalVisible} onOk={this.groupSearchModalOk} onCancel={() => this.setState({groupSearchModalVisible: false})}/>
                                    {p.groups.length === 0 ? <span>None</span> : <ListGroup>{p.groups.map(g =>
                                        <Link to={`/marketing/groups/${g.groupId}`} key={g.groupId}>
                                            <ListGroup.Item key={g.groupId} action>
                                                <Row>
                                                    <Col>{g.name}</Col>
                                                    <Col className={"text-right"}>{this.state.editing ? <Glyph name={"x"} className={"clickable"} label={"Remove"} onClick={(e) => this.removeGroup(e, g.groupId)}/> : <span/>}</Col>
                                                </Row>
                                            </ListGroup.Item>
                                        </Link>)}
                                    </ListGroup>}
                                </Col>
                            </Row>
                        </div>
                    ) : <span/>}
                    {p && p.providerId > 0 ? <div>
                        <h5 className={"mt-3"}>Referrals/Authorizations</h5>
                        {this.state.referralStats.map(rs => <ProviderMonthlyReferralList key={rs.year+rs.monthName} year={rs.year} month={rs.month} monthName={rs.monthName} providerId={this.props.match.params.providerId} referralCount={rs.referralCount} authCount={rs.authCount}/>)}
                    </div> : <span/>}
                </Card.Body>
            </Card>
        );
    }
}

export default withRouter(ProviderView);