import React from 'react';
import {GlobalContext} from "../../../GlobalContext";
import {Table} from "react-bootstrap";
import {SpinnerWrapper} from "../SpinnerWrapper";

export class PatientIcd10List extends React.Component {
    static contextType = GlobalContext;

    render() {
        return <SpinnerWrapper visible={this.props.loading}>
            <Table striped bordered>
                <thead>
                    <tr>
                        <th>Code</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    { this.props.icd10List.map(icd => <tr key={icd.code}>
                        <td>{icd.code}</td>
                        <td>{icd.description}</td>
                    </tr>)
                    }
                </tbody>
            </Table>
        </SpinnerWrapper>;
    }
}