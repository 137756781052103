import React from "react";
import {Col, Form} from "react-bootstrap";
import {OptionalSelect} from "../common/OptionalSelect";
import {ItemModal} from "../common/ItemModal";
import {GlobalContext} from "../../GlobalContext";

export class GroupAssignmentModal extends ItemModal {
    static contextType = GlobalContext;

    idField = 'assignmentId';
    itemTypeName = 'Assignment';

    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            marketingUsers: []
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        super.componentDidUpdate(prevProps, prevState, snapshot);
        if (!prevProps.show && this.props.show) {
            this.loadMarketingUsers();
        }
    }

    loadMarketingUsers = () => {
        this.context.apiRequest("GET", "/users?role=Marketing")
            .then(d => {
                if (d.data) {
                    this.setState({marketingUsers: d.data});
                }
            });
    };

    renderFormContents = (a) => {
        return <div>
            <Form.Row>
                <Form.Group as={Col} controlId="userId">
                    <Form.Label size="sm">
                        Marketing User
                    </Form.Label>
                    <OptionalSelect name={"userId"} value={a.userId} options={this.state.marketingUsers} valueKey={"userId"} labelKey={"fullName"} onChange={this.itemFieldChanged} noneLabel={"Select a user"} canSelectNone={false}/>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} controlId="startDate">
                    <Form.Label size="sm">
                        Start Date
                    </Form.Label>
                    <Form.Control size="sm" name={"startDate"} value={a.startDate}
                                  onChange={this.itemFieldChanged}/>
                </Form.Group>
                <Form.Group as={Col} controlId="endDate">
                    <Form.Label size="sm">
                        End Date
                    </Form.Label>
                    <Form.Control size="sm" name={"endDate"} value={a.endDate}
                                  onChange={this.itemFieldChanged}/>
                </Form.Group>
                <Form.Group as={Col} controlId="active" xs={2} className={"text-center"}>
                    <Form.Label size="sm">
                        Active
                    </Form.Label>
                    <Form.Check
                        type={"switch"}
                        id={"assignment-active"}
                        name={"active"}
                        aria-label={"Active"}
                        checked={a.active === 1}
                        onChange={e => this.itemFieldChanged(e, e.target.checked ? 1 : 0)}/>
                </Form.Group>
            </Form.Row>
        </div>;
    };
}