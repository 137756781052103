import React from "react";
import {Col, Form} from "react-bootstrap";
import {ItemModal} from "../common/ItemModal";
import moment from "moment";
import {DateInput} from "../common/DateInput";

export class LienChargeModal extends ItemModal {

    idField = 'id';
    itemTypeName = 'Charge';

    doCustomValidation = () => {
        let valid = true;
        let dt = this.state.item.chargeDate;
        if (!moment(dt).isValid()) {
            valid = false;
            this.addValidationErrors(`Invalid date format: ${dt}`);
        }
        if (!/^\-?\d+\.?\d*$/.test(this.state.item.amount)) {
            valid = false;
            this.addValidationErrors(`Invalid number format: ${this.state.item.amount}`);
        }
        return valid;
    }

    renderFormContents = (t) => {
        return <div>
            <Form.Row>
                <Form.Group as={Col} controlId="chargeDate" md={4}>
                    <Form.Label size="sm">
                        Charge Date
                    </Form.Label>
                    <DateInput required size="sm" name={"chargeDate"} value={t.chargeDate}
                               pattern={"^(0?[1-9]|1[0-2])\\/(0?[1-9]|1\\d|2\\d|3[01])\\/\\d{2}$"}
                               placeholder={"mm/dd/yy"}
                               onChange={this.itemFieldChanged}/>
                </Form.Group>
                <Form.Group as={Col} controlId="amount" md={8}>
                    <Form.Label size="sm">
                        Amount
                    </Form.Label>
                    <Form.Control required size="sm" name={"amount"} value={t.amount} placeholder={'0.00'}
                                  onChange={this.itemFieldChanged}/>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} controlId="description">
                    <Form.Label size="sm">
                        Description
                    </Form.Label>
                    <Form.Control as={"textarea"} size="sm" name={"description"} value={t.description}
                                  onChange={this.itemFieldChanged}/>
                </Form.Group>
            </Form.Row>
        </div>;
    };
}