import React from "react";
import {Button, Col, Modal, Row} from "react-bootstrap";
import {GlobalContext} from "../../GlobalContext";
import {Link} from "react-router-dom";
import {nlToBr} from "../../utils/Strings";

export class AlertModal extends React.Component {
    static contextType = GlobalContext;

    onDelete = () => {
        this.context.apiRequest('POST', `/alerts/${this.props.alert.alertId}/dismiss`)
            .then(this.props.onDelete);
    };

    render() {
        let a = this.props.alert;
        return <Modal size={this.props.size || "md"} show={this.props.show} onHide={this.props.onCancel}>
            <Modal.Header closeButton>
                <Modal.Title>Alert Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className={"mb-3"}>
                    <Col><h5>{a.alertCode}</h5></Col>
                </Row>
                {a.providerFirst ? <Row className={"mb-3"}>
                    <Col>Provider: <Link className="text-dark" replace={true} to={`/marketing/providers/${a.providerId}`} onClick={this.props.onCancel}>{a.providerFirst} {a.providerLast} {a.providerCredentials}</Link></Col>
                </Row> : <span/>}
                {a.groupName ? <Row className={"mb-3"}>
                    <Col>Group: <Link className="text-dark" replace={true} to={`/marketing/groups/${a.groupId}`} onClick={this.props.onCancel}>{a.groupName}</Link></Col>
                </Row> : <span/>}
                {a.patientId ? <Row className={"mb-3"}>
                    <Col>Patient: <Link className="text-dark" replace={true} to={`/patients/${a.patientId}`} onClick={this.props.onCancel}>{a.patientFirst} {a.patientLast}</Link></Col>
                </Row> : <span/>}
                <Row className={"mb-3"}>
                    <Col>{nlToBr(a.text)}</Col>
                </Row>
                <Row>
                    <Col className={"small font-weight-light font-italic"}>Created {a.created}</Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="dark" onClick={this.props.onCancel}>
                    Close
                </Button>
                <Button variant="danger" onClick={this.onDelete}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    }
}