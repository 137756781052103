import React from "react";
import {Button, Card, Col, Form, Modal, Row} from "react-bootstrap";
import {GlobalContext} from "../../GlobalContext";
import {SpinnerWrapper} from "../common/SpinnerWrapper";
import {Glyph} from "../common/Glyph";
import moment from "moment-timezone";
import {formatPhone} from "../../utils/Phone";
import {RadioList} from "../common/RadioList";

export class LienDataSheetModal extends React.Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.state = {
            data: {},
            loading: true
        }
    }

    injuryTypes = ['MVA', 'PED vs MV', 'Slip & Fall'];
    pharmacies = ['Las Vegas', 'Spring Valley', 'Partell', 'Wellcare', 'Pay Later', 'No Preference'];

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.lien && this.props.lien.id > 0 && prevProps.lien.id !== this.props.lien.id) {
            this.loadData();
        }
    }

    loadData = () => {
        this.context.apiRequest("GET", `/liens/${this.props.lien.id}/datasheet`)
            .then(this.dataLoaded);
    }

    ok = () => {
        this.context.apiRequest("POST", `/liens/${this.props.lien.id}/datasheet`, this.state.data)
            .then(this.dataLoaded)
            .then(this.props.close)
    }

    dataLoaded = (d) => {
        if (d.data) {
            let toi = d.data.typeOfInjury || null;
            d.data.typeOfInjuryCtl = toi;
            if (toi && !this.injuryTypes.includes(toi)) {
                d.data.typeOfInjuryCtl = 'Other'
            }
            let pharm = d.data.pharmacy || null;
            d.data.pharmacyCtl = pharm;
            if (pharm && !this.pharmacies.includes(pharm)) {
                d.data.pharmacyCtl = 'Other'
            }
            this.setState({data: d.data, loading: false});
        }
    }

    fieldChanged = (e) => {
        let fname = e.target.name;
        let val = e.target.value;
        let d = this.state.data;
        d[fname] = val;
        if (fname == 'typeOfInjuryCtl' && val !== 'Other') {
            d.typeOfInjury = val;
        }
        if (fname == 'pharmacyCtl' && val !== 'Other') {
            d.pharmacy = val;
        }

        this.setState({data: d});

    }

    render() {
        let d = this.state.data || {};
        let lc = this.props.lien;
        let p = this.props.patient;
        let age = '';
        if (!this.props.loading && p && p.dob) {
            let now = moment();
            let dobMoment = moment(p.dob, "MM/DD/YYYY");
            age = Math.floor(moment.duration(now.diff(dobMoment)).as('years'));
        }

        return <Modal size={this.props.size || "xl"} show={this.props.show} onHide={this.props.close}>
            <Modal.Header closeButton>
                <Modal.Title className={"w-100"}>
                    {this.props.title}
                    <a href={`/viewReport/${encodeURIComponent('Lien Data Sheet.rptdesign')}?pdf=true&lienId=${this.props.lien.id}`} target={"_blank"}>
                        <Glyph name={"printer"} className={"float-right clickable"} label={"Print"}/></a>

                </Modal.Title>
            </Modal.Header>
            <Modal.Body ref={el => (this.componentRef = el)}>
                <SpinnerWrapper visible={this.props.loading}>
                    {p && <Form>
                        <Form.Row>
                            <Col sm={6}>Referral Date: {lc.referral ? lc.referral.receivedDate : 'N/A'}</Col>
                            <Col sm={6}>
                                Referred By:&nbsp;
                                {lc.referral && lc.referral.source1 && <span>{lc.referral.source1.firstName} {lc.referral.source1.lastName}</span>}
                                {lc.referral && lc.referral.source2 && <span>{lc.referral.source1 && ' / '}{lc.referral.source2.firstName} {lc.referral.source2.lastName}</span>}
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col sm={6}>Patient Name: {p.firstName} {p.lastName}</Col>
                            <Col sm={3}>DOB: {p.dob}</Col>
                            <Col sm={3}>Age: {age}</Col>
                        </Form.Row>
                        <Form.Row>
                            <Col sm={6}>DOI: {lc.dateOfInjury}</Col>
                        </Form.Row>
                        <Form.Row>
                            <Col sm={3}>
                                <Form.Group>
                                    <Form.Label>Type Of Injury:</Form.Label>
                                    <Form.Control as={"select"} className={"d-inline-block"} custom={true}
                                                  name="typeOfInjuryCtl" value={d.typeOfInjuryCtl}
                                                  onChange={this.fieldChanged}>
                                        {
                                            ['', ...this.injuryTypes, 'Other']
                                                .map(v => <option key={v} value={v}>{v}</option>)
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col sm={3}>
                                {
                                    d.typeOfInjuryCtl === 'Other' &&
                                    <Form.Group>
                                        <Form.Label>Other Injury Type:</Form.Label>
                                        <Form.Control name={"typeOfInjury"} onChange={this.fieldChanged}
                                                      value={d.typeOfInjury}/>
                                    </Form.Group>
                                }
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col sm={12}>
                                <Card className={"mt-2"}>
                                    <Card.Body>
                                        <Form.Row>
                                            <Col sm={6}>
                                                <Form.Group>
                                                    <Form.Label>3rd Party Policy Limits:</Form.Label>
                                                    <Form.Control name={"thirdPartyPolicyLimits"}
                                                                  value={d.thirdPartyPolicyLimits || ''}
                                                                  onChange={this.fieldChanged}/>
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>
                                        <Form.Row>
                                            <Col sm={3}>
                                                <Form.Group>
                                                    <Form.Label>MedPay?</Form.Label>
                                                    <Form.Row>
                                                        <RadioList inline={true} name={"medpay"} onChange={this.fieldChanged} values={['Y', 'N', 'U']} labels={['Y', 'N', 'Unknown']} currentValue={d.medpay}/>
                                                    </Form.Row>
                                                </Form.Group>
                                            </Col>
                                            <Col sm={5}>
                                                {d.medpay === 'Y' && <Form.Group>
                                                    <Form.Label>Insurance Name / Policy Number / Limits</Form.Label>
                                                    <Form.Control name={"medpayInfo"} value={d.medpayInfo || ''}
                                                                  onChange={this.fieldChanged}/>
                                                </Form.Group>}
                                            </Col>
                                        </Form.Row>
                                        <Form.Row>
                                            <Col sm={3}>
                                                <Form.Group>
                                                    <Form.Label>Underinsured Motorist (UIM)?</Form.Label>
                                                    <Form.Row>
                                                        <RadioList inline={true} name={"uim"} onChange={this.fieldChanged} values={['Y', 'N', 'U']} labels={['Y', 'N', 'Unknown']} currentValue={d.uim}/>
                                                    </Form.Row>
                                                </Form.Group>
                                            </Col>
                                            <Col sm={5}>
                                                {d.uim === 'Y' && <Form.Group>
                                                    <Form.Label>UIM Limits</Form.Label>
                                                    <Form.Control name={"uimLimits"} value={d.uimLimits || ''} onChange={this.fieldChanged}/>
                                                </Form.Group>}
                                            </Col>
                                        </Form.Row>
                                        <Form.Row>
                                            <Col sm={3}>
                                                <Form.Group>
                                                    <Form.Label>Commercial Policy?</Form.Label>
                                                    <Form.Row>
                                                        <RadioList inline={true} name={"commercialPolicy"} onChange={this.fieldChanged} values={['Y', 'N']} currentValue={d.commercialPolicy}/>
                                                    </Form.Row>
                                                </Form.Group>
                                            </Col>
                                            <Col sm={3}>
                                                {d.commercialPolicy === 'Y' && <Form.Group>
                                                    <Form.Label>Liability Accepted?</Form.Label>
                                                    <Form.Row>
                                                        <RadioList inline={true} name={"liabilityAccepted"} onChange={this.fieldChanged} values={['Y', 'N']} currentValue={d.liabilityAccepted}/>
                                                    </Form.Row>
                                                </Form.Group>}
                                            </Col>
                                            <Col sm={6}>
                                                {d.commercialPolicy === 'Y' && <Form.Group>
                                                    <Form.Label>Liability Info</Form.Label>
                                                    <Form.Control name={"liabilityInfo"} value={d.liabilityInfo || ''} onChange={this.fieldChanged}/>
                                                </Form.Group>}
                                            </Col>
                                        </Form.Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col sm={12}>
                                <Card className={"mt-2"}>
                                    <Card.Body>
                                        <Form.Row>
                                            <Col sm={6}>
                                                <Form.Group>
                                                    <Form.Label>Patient Had Prior Accidents In Past 2
                                                        Years?</Form.Label>
                                                    <Form.Row>
                                                        <RadioList inline={true} name={"priorAccidents2y"} onChange={this.fieldChanged} values={['Y', 'N']} currentValue={d.priorAccidents2y}/>
                                                    </Form.Row>
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>
                                        {
                                            d.priorAccidents2y === 'Y' && <Form.Row>
                                                <Col sm={4}>
                                                    <Form.Group>
                                                        <Form.Label>Date of Prior Accident:</Form.Label>
                                                        <Form.Control name={"priorAccidentDates"}
                                                                      value={d.priorAccidentDates || ''}
                                                                      onChange={this.fieldChanged}/>
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={4}>
                                                    <Form.Group>
                                                        <Form.Label>Prior Accident POB:</Form.Label>
                                                        <Form.Control name={"priorAccidentPob"}
                                                                      value={d.priorAccidentPob || ''}
                                                                      onChange={this.fieldChanged}/>
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={2} className={"ml-3"}>
                                                    <Form.Group>
                                                        <Form.Label>Case Settled?</Form.Label>
                                                        <Form.Row>
                                                            <RadioList inline={true} name={"priorCaseSettled"} onChange={this.fieldChanged} values={['Y', 'N']} currentValue={d.priorCaseSettled}/>
                                                        </Form.Row>
                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>
                                        }
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col sm={7}>
                                <Card className={"mt-2"}>
                                    <Card.Body>
                                        <Form.Row>
                                            <Col sm={6}>
                                                <Form.Group>
                                                    <Form.Label>Prior Neuro Care?</Form.Label>
                                                    <Form.Row>
                                                        <RadioList inline={true} name={"priorNeuroCare"} onChange={this.fieldChanged} values={['Y', 'N']} currentValue={d.priorNeuroCare}/>
                                                    </Form.Row>
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>
                                        {
                                            d.priorNeuroCare === 'Y' && <Form.Row>
                                                <Col sm={6}>
                                                    <Form.Group>
                                                        <Form.Label>Prior Neuro Care - Who?</Form.Label>
                                                        <Form.Control name={"priorNeuroCareWho"}
                                                                      value={d.priorNeuroCareWho || ''}
                                                                      onChange={this.fieldChanged}/>
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={6}>
                                                    <Form.Group>
                                                        <Form.Label>Prior Neuro Care - When?</Form.Label>
                                                        <Form.Control name={"priorNeuroCareWhen"}
                                                                      value={d.priorNeuroCareWhen || ''}
                                                                      onChange={this.fieldChanged}/>
                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>
                                        }
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={5}>
                                <Card className={"mt-2"}>
                                    <Card.Body>
                                        <Form.Group>
                                            <Form.Label>Treating/Treated With:</Form.Label>
                                            <Form.Control as={"textarea"} rows={3} name={"treatingWith"}
                                                          value={d.treatingWith || ''} onChange={this.fieldChanged}/>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col sm={12}>
                                <Card className={"mt-2"}>
                                    <Card.Body>
                                        <Form.Row>
                                            <Col sm={6}>
                                                <Form.Group>
                                                    <Form.Label>Adverse Driver Name:</Form.Label>
                                                    <Form.Control name={"adverseDriverName"}
                                                                  value={d.adverseDriverName || ''}
                                                                  onChange={this.fieldChanged}/>
                                                </Form.Group>
                                            </Col>
                                            <Col sm={6}>
                                                <Form.Group>
                                                    <Form.Label>Adverse Driver DOB:</Form.Label>
                                                    <Form.Control name={"adverseDriverDob"}
                                                                  value={d.adverseDriverDob || ''}
                                                                  onChange={this.fieldChanged}/>
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col sm={12}>
                                <Card className={"mt-2"}>
                                    <Card.Body>
                                        <Form.Row>
                                            <Form.Group as={Col} sm={6}>
                                                <Form.Label>Referred For:</Form.Label>
                                                <Form.Control as={"select"} custom={true} name={"referredFor"}
                                                              value={d.referredFor || ''}
                                                              onChange={this.fieldChanged}>
                                                    {
                                                        ['', 'Consult', 'Testing Only', 'Consult and Testing']
                                                            .map(v => <option key={v} value={v}>{v}</option>)
                                                    }
                                                </Form.Control>
                                            </Form.Group>
                                            <Form.Group as={Col} sm={6}>
                                                <Form.Label>POB:</Form.Label>
                                                <Form.Control name={"pob"} value={d.pob || ''} onChange={this.fieldChanged}/>
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Col sm={3}>
                                                <Form.Group>
                                                    <Form.Label>Services Auth'd By Attorney?</Form.Label>
                                                    <Form.Row>
                                                        <RadioList inline={true} name={"servicesAuthorized"} onChange={this.fieldChanged} values={['Y', 'N']} currentValue={d.servicesAuthorized}/>
                                                    </Form.Row>
                                                </Form.Group>
                                            </Col>
                                            <Col sm={3}>
                                                <Form.Group>
                                                    <Form.Label>Lien?</Form.Label>
                                                    <Form.Control as={"select"} custom={true} name={"lien"}
                                                                  value={d.lien || ''} onChange={this.fieldChanged}>
                                                        {
                                                            ['', 'Yes', 'No', 'Secondary']
                                                                .map(v => <option key={v} value={v}>{v}</option>)
                                                        }
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col sm={2} className={"ml-3"}>
                                                <Form.Group>
                                                    <Form.Label>Insurance?</Form.Label>
                                                    <Form.Row>
                                                        <RadioList inline={true} name={"insurance"} onChange={this.fieldChanged} values={['Y', 'N']} currentValue={d.insurance}/>
                                                    </Form.Row>
                                                </Form.Group>
                                            </Col>
                                            <Col sm={3}>
                                                <Form.Group>
                                                    <Form.Label>Patient's Insurance:</Form.Label>
                                                    <Form.Row>
                                                        <Col>
                                                            {
                                                                p.insurances && p.insurances.length > 0 ? p.insurances.map(i =>
                                                                        <p key={i.emrInsuranceId}>{i.planCode} {i.companyName}</p>) :
                                                                    <p>None</p>
                                                            }
                                                        </Col>
                                                    </Form.Row>
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>
                                        <Form.Row>
                                            <Col sm={12}>
                                                <Form.Group>
                                                    <Form.Label>Brief Description of Accident:</Form.Label>
                                                    <Form.Control name={"descriptionOfAccident"}
                                                                  value={d.descriptionOfAccident || ''}
                                                                  onChange={this.fieldChanged}/>
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col sm={12}>
                                <Card className={"mt-2"}>
                                    <Card.Body>
                                        <Form.Row>
                                            <Col sm={2}>Law Firm:</Col>
                                            <Col sm={4}>{lc.linkedGroupName || lc.attorneyName}</Col>
                                            <Col sm={4}>
                                                <p>{lc.attorneyGroupPhone && formatPhone(lc.attorneyGroupPhone) + ' (phone)'}</p>
                                                <p>{lc.attorneyGroupFax && formatPhone(lc.attorneyGroupFax) + ' (fax)'}</p>
                                            </Col>
                                        </Form.Row>
                                        <Form.Row>
                                            <Col sm={2}>Attorney:</Col>
                                            <Col sm={4}>{lc.linkedAttorneyFirst ? `${lc.linkedAttorneyFirst} ${lc.linkedAttorneyLast}` : ''}</Col>
                                        </Form.Row>
                                        <Form.Row>
                                            <Col sm={2}>Case Manager:</Col>
                                            <Col sm={4}>{lc.caseManagerName || ''}</Col>
                                            <Col sm={2}>Contact Email:</Col>
                                            <Col sm={4}>{lc.attorneyContactEmail || ''}</Col>
                                        </Form.Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col sm={12}>
                                <Card className={"mt-2"}>
                                    <Card.Body>
                                        <Form.Row>
                                            <Form.Group as={Col} sm={2}>
                                                <Form.Label>Police Report?</Form.Label>
                                                <Form.Row>
                                                    <RadioList inline={true} name={"policeReport"} onChange={this.fieldChanged} values={['Y', 'N']} currentValue={d.policeReport}/>
                                                </Form.Row>
                                            </Form.Group>
                                            <Form.Group as={Col} sm={3}>
                                                <Form.Label>Reason</Form.Label>
                                                <Form.Control as={"select"} custom={true} name={"policeReportReason"}
                                                              value={d.policeReportReason || ''} onChange={this.fieldChanged}>
                                                    {
                                                        ['', 'No police report available', 'Atty will not disclose TAR', 'Police report pending', 'Received']
                                                            .map(v => <option key={v} value={v}>{v}</option>)
                                                    }
                                                </Form.Control>
                                            </Form.Group>
                                            <Form.Group as={Col} sm={2} className={"ml-3"}>
                                                <Form.Label>Will Fax/Email Report?</Form.Label>
                                                <Form.Row>
                                                    <RadioList inline={true} name={"policeReportWillSend"} onChange={this.fieldChanged} values={['Y', 'N']} currentValue={d.policeReportWillSend}/>
                                                </Form.Row>
                                            </Form.Group>
                                        </Form.Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Form.Row>
                        <Row>
                            <Col sm={12}>
                                <Card className={"mt-2"}>
                                    <Card.Body>
                                        <Form.Row>
                                            <Col sm={2}>Pharmacy:</Col>
                                            <Col sm={2}>
                                                <RadioList inline={false} name={"pharmacyCtl"} onChange={this.fieldChanged} values={[...this.pharmacies, 'Other']} currentValue={d.pharmacyCtl}/>
                                            </Col>
                                            <Col sm={4}>
                                                {d.pharmacyCtl === 'Other' && <Form.Group>
                                                    <Form.Label>Other pharmacy:</Form.Label>
                                                    <Form.Control name={"pharmacy"} value={d.pharmacy}
                                                                  onChange={this.fieldChanged}/>
                                                </Form.Group>}
                                            </Col>
                                        </Form.Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <Card className={"mt-2"}>
                                    <Card.Body>
                                        <Row>
                                            <Col sm={2}>Approval Status:</Col>
                                            <Col>
                                                <RadioList inline={true} name={"approvalStatus"} onChange={this.fieldChanged} values={['Approved', 'Denied']} currentValue={d.approvalStatus}/>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Form>}
                </SpinnerWrapper>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={this.props.close}>
                    Cancel
                </Button>
                <Button variant="dark" onClick={this.ok}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    }}