import {Col, Form, Row, Table} from "react-bootstrap";
import React from "react";
import {GlobalContext} from "../../../GlobalContext";
import {OptionalSelect} from "../OptionalSelect";

export class PatientPreferencesForm extends React.Component {
    static contextType = GlobalContext;

    static timesOfDay = [
        {label: "Morning", value: "AM"},
        {label: "Afternoon", value: "PM"}
    ];

    constructor(props) {
        super(props);
        this.state = {
            locations: []
        }
    }

    componentDidMount() {
        this.context.cachedGet(`/lookup/location?filter=${encodeURIComponent('internal=1 and active=1')}&orderBy=addr1`, 300)
            .then(d => this.setState({locations: d.data}));
    }

    render() {
        let p = this.props.patient;
        let days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
        return <Form id={"patient-preferences-form"} noValidate validated={this.props.validated} onSubmit={e => e.preventDefault()}>
            <Row>
                <Col xs={6}>
                    <Table striped size={"sm"}>
                        <thead>
                            <tr>
                                <td>Day of Week</td>
                                <td className={"text-center"}>Available</td>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            days.map(d => <tr key={d}>
                                <td>{d}</td>
                                <td className={"text-center"}>
                                    <Form.Check
                                        type={"checkbox"}
                                        id={`available${d}`}
                                        name={`available${d}`}
                                        aria-label={d}
                                        checked={p[`available${d}`] === 1}
                                        disabled={!this.props.editing}
                                        onChange={e => this.props.patientFieldChanged(e, e.target.checked ? 1 : 0)}/>
                                </td>
                            </tr>)
                        }
                        </tbody>
                    </Table>
                </Col>
                <Col>
                    <Form.Row>
                        <Form.Check
                            type={"checkbox"}
                            id={`shortNoticeAvailable`}
                            name={`shortNoticeAvailable`}
                            label={"Available on short notice (< 24 hrs)"}
                            checked={p.shortNoticeAvailable === 1}
                            disabled={!this.props.editing}
                            onChange={e => this.props.patientFieldChanged(e, e.target.checked ? 1 : 0)}/>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group controlId="preferredLocationId">
                            <Form.Label size="sm">
                                Preferred Location
                            </Form.Label>
                            <OptionalSelect name={"preferredLocationId"}
                                            value={p.preferredLocationId}
                                            options={this.state.locations}
                                            valueKey={"locationId"}
                                            labelKey={"addr1"}
                                            onChange={this.props.patientFieldChanged}
                                            noneLabel={"No Preference"}
                                            canSelectNone={true}
                                            disabled={!this.props.editing}/>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group controlId="preferredTimeOfDay">
                            <Form.Label size="sm">
                                Preferred Time of Day
                            </Form.Label>
                            <OptionalSelect name={"preferredTimeOfDay"}
                                            value={p.preferredTimeOfDay}
                                            options={PatientPreferencesForm.timesOfDay}
                                            valueKey={"value"}
                                            labelKey={"label"}
                                            onChange={this.props.patientFieldChanged}
                                            noneLabel={"No Preference"}
                                            canSelectNone={true}
                                            disabled={!this.props.editing}/>
                        </Form.Group>
                    </Form.Row>
                </Col>
            </Row>
        </Form>;
    }
}