import React from 'react';
import {GlobalContext} from "../../../GlobalContext";
import {Table} from "react-bootstrap";
import {SpinnerWrapper} from "../SpinnerWrapper";

export class PatientMipsList extends React.Component {
    static contextType = GlobalContext;

    render() {
        return <SpinnerWrapper visible={this.props.loading}>
            <Table striped bordered>
                <tbody>
                    { this.props.mipsList.map(mips => <tr key={mips.code}>
                        <td>{mips.text}</td>
                    </tr>)
                    }
                </tbody>
            </Table>
        </SpinnerWrapper>;
    }
}