import React from "react";
import {Button, Col, Form, Modal} from "react-bootstrap";
import {GlobalContext} from "../../GlobalContext";

export class ChangePasswordModal extends React.Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.state = {
            validated: false,
            errorMessage: '',
            form: {
                currentPassword: '',
                newPassword1: '',
                newPassword2: ''
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.show !== this.props.show) {
            this.setState({validated: false, errorMessage: '', form: {currentPassword: '',
                    newPassword1: '',
                    newPassword2: ''}});
        }
    }

    itemFieldChanged = (e, overrideValue) => {
        let fieldName = e.target.name;
        let value = overrideValue === undefined ? e.target.value : overrideValue;
        this.setState(prvState => ({
            form: {
                ...prvState.form,
                [fieldName]: value
            }
        }));
    };

    ok = () => {
        if (this.validate()) {
            this.context.apiRequest("POST", "/my/password", this.state.form)
                .then(resp => {
                    if (resp.status === 200) {
                        this.props.onOk();
                    } else if (resp.message) {
                        this.setState({errorMessage: resp.message});
                    }
                });
        }
    };

    validate = () => {
        this.setState({validated: false, errorMessage: ''});
        let valid = this.state.form.newPassword1 === this.state.form.newPassword2;
        if (!valid) {
            this.setState({errorMessage: 'New password and confirmation don\'t match.'});
        }
        let form = document.getElementById('password-form');
        valid &= form.checkValidity();
        this.setState({validated: true});
        return valid;
    }

    render() {
        return <Modal size={this.props.size || "md"} show={this.props.show} onHide={this.props.onCancel}>
            <Modal.Header closeButton>
                <Modal.Title>Change My Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id={"password-form"} noValidate validated={this.state.validated} onSubmit={this.ok}>
                    <Form.Row>
                        <Form.Group as={Col} controlId="currentPassword">
                            <Form.Label size="sm">
                                Current Password
                            </Form.Label>
                            <Form.Control required type={"password"} size="sm" name={"currentPassword"} value={this.state.currentPassword} onChange={this.itemFieldChanged}/>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>&nbsp;</Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="newPassword1">
                            <Form.Label size="sm">
                                New Password
                            </Form.Label>
                            <Form.Control required type={"password"} size="sm" name={"newPassword1"} value={this.state.newPassword1} onChange={this.itemFieldChanged}/>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="newPassword2">
                            <Form.Label size="sm">
                                Confirm New Password
                            </Form.Label>
                            <Form.Control required type={"password"} size="sm" name={"newPassword2"} value={this.state.newPassword2} onChange={this.itemFieldChanged}/>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Col>
                            <div className={"text-center text-danger"}>{this.state.errorMessage}</div>
                        </Col>
                    </Form.Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={this.props.onCancel}>
                    Cancel
                </Button>
                <Button variant="dark" onClick={this.ok}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    }
}