import React from "react";
import {Col, Form} from "react-bootstrap";
import {ItemModal} from "../common/ItemModal";
import {GlobalContext} from "../../GlobalContext";
import {OptionalSelect} from "../common/OptionalSelect";

export class GroupActivityModal extends ItemModal {
    static contextType = GlobalContext;

    idField = 'groupMarketingActivityId';
    itemTypeName = 'Activity';

    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            marketingUsers: [],
            locations: [],
            providers: [],
            flagReasons: [],
            contactTypes: [],
            visitStatuses: []
        };
    }

    becameVisible = () => {
        this.loadLists();
    }

    loadLists = () => {
        this.context.apiRequest("GET", "/users?role=Marketing")
            .then(d => {
                if (d.data) {
                    this.setState({marketingUsers: d.data});
                }
            });
        this.context.apiRequest("GET", `/groups/${this.props.item.groupId}/locations`)
            .then(d => {
                if (d.data) {
                    this.setState({locations: d.data});
                }
            });
        this.context.apiRequest("GET", `/groups/${this.props.item.groupId}/providers`)
            .then(d => {
                if (d.data) {
                    this.setState({providers: d.data});
                }
            });
        this.context.cachedGet("/lookup/group_activity_flag_reason")
            .then(d => {
                if (d.data) {
                    this.setState({flagReasons: d.data});
                }
            });
        this.context.cachedGet("/lookup/marketing_contact_type")
            .then(d => {
                if (d.data) {
                    this.setState({contactTypes: d.data});
                }
            });
        this.context.cachedGet(`/lookup/marketing_visit_status?filter=${encodeURIComponent('active=1')}`)
            .then(d => {
                if (d.data) {
                    this.setState({visitStatuses: d.data});
                }
            });


    };

    /**
     * Override me
     * @returns {boolean}
     */
    doCustomValidation = () => {
        return true;
    };

    /**
     * Override me
     * @returns {JSX.Element}
     */
    renderFormContents = (a) => {
        return <div>
            <Form.Row>
                <Form.Group as={Col} controlId="marketingRepUserId" md={4}>
                    <Form.Label size="sm">
                        Marketing Rep
                    </Form.Label>
                    <OptionalSelect name={"marketingRepUserId"} value={a.marketingRepUserId} options={this.state.marketingUsers} valueKey={"userId"} labelKey={"fullName"} onChange={this.itemFieldChanged} noneLabel={"Select a user"}/>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} controlId="timestamp" md={2}>
                    <Form.Label size="sm">
                        Date
                    </Form.Label>
                    <Form.Control size="sm" name={"timestamp"} value={a.timestamp || ''}
                                  onChange={this.itemFieldChanged} placeholder={'M/D/YYYY'}/>
                </Form.Group>
                <Form.Group as={Col} controlId="contactTypeCode" md={4}>
                    <Form.Label size="sm">
                        Contact Type
                    </Form.Label>
                    <OptionalSelect name={"contactTypeCode"} value={a.contactTypeCode} options={this.state.contactTypes} valueKey={"contactTypeCode"} labelKey={"contactType"} onChange={this.itemFieldChanged} noneLabel={"Select a type"}/>
                </Form.Group>

                <Form.Group as={Col} controlId="locationId" md={6}>
                    <Form.Label size="sm">
                        Location
                    </Form.Label>
                    <OptionalSelect name={"locationId"} value={a.locationId} options={this.state.locations} valueKey={"locationId"} labelKey={"addr1"} onChange={this.itemFieldChanged} noneLabel={""} canSelectNone={true}/>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} controlId="visitStatusCode" md={6}>
                    <Form.Label size="sm">
                        Status
                    </Form.Label>
                    <OptionalSelect name={"visitStatusCode"} value={a.visitStatusCode} options={this.state.visitStatuses} valueKey={"visitStatusCode"} labelKey={"status"} onChange={this.itemFieldChanged} noneLabel={"Select a status"}/>
                </Form.Group>
                <Form.Group as={Col} controlId="flagReasonId" md={3}>
                    <Form.Label size="sm">
                        Flag
                    </Form.Label>
                    <OptionalSelect name={"flagReasonId"} value={a.flagReasonId} options={this.state.flagReasons} valueKey={"flagReasonId"} labelKey={"reason"} onChange={this.itemFieldChanged} noneLabel={""} canSelectNone={true}/>
                </Form.Group>
                <Form.Group as={Col} controlId="appointmentTime" md={3}>
                    <Form.Label size="sm">
                        Appointment Time
                    </Form.Label>
                    <Form.Control size="sm" name={"appointmentTime"} value={a.appointmentTime || ''}
                                  onChange={this.itemFieldChanged}/>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} controlId="appointmentTime" md={6}>
                    <Form.Label size="sm">
                        Providers Seen:
                    </Form.Label>
                    {
                    this.state.providers.map(p => <Form.Check
                        key={p.providerId}
                        type={"checkbox"}
                        id={"providerIds"}
                        name={"providerIds"}
                        label={p.name}
                        defaultChecked={a.providerIds.includes(p.providerId)}
                        onChange={e => this.providerClicked(e, p.providerId)}/>)
                    }
                </Form.Group>
                <Form.Group as={Col} controlId="followUpDate" md={3}>
                    <Form.Label size="sm">
                        Follow Up Date
                    </Form.Label>
                    <Form.Control size="sm" name={"followUpDate"} value={a.followUpDate || ''}
                                  onChange={this.itemFieldChanged}/>
                </Form.Group>
                <Form.Group as={Col} controlId="expense" md={3}>
                    <Form.Label size="sm">
                        Expense
                    </Form.Label>
                    <Form.Control size="sm" name={"expense"} value={a.expense || ''}
                                  onChange={this.itemFieldChanged}/>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} controlId="notes">
                    <Form.Label size="sm">
                        Notes
                    </Form.Label>
                    <Form.Control name={"notes"} as="textarea" size={"sm"} rows={5} value={a.notes || ''} onChange={this.itemFieldChanged}/>
                </Form.Group>
            </Form.Row>
        </div>;
    };

    providerClicked = (evt, providerId) => {
        let newProvs = [...this.state.item.providerIds];
        console.log(`Clicked ${providerId} ${evt.target.checked} ${evt}`);
        if (evt.target.checked) {
            // ensure they're included
            if (!newProvs.includes(providerId)) {
                newProvs.push(providerId);
            }
        } else {
            // ensure they're excluded
            newProvs = newProvs.filter(pid => pid !== providerId);
        }
        this.itemFieldChanged(evt, newProvs);
    };
}