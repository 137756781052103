import {todayStr} from "../../utils/Dates";
import {GroupAssignmentModal} from "./GroupAssignmentModal";
import {ItemList} from "../common/ItemList";

export class GroupAssignmentList extends ItemList {

    baseUrl = `/groups/${this.props.groupId}/assignments`;
    idField = 'assignmentId';
    itemTypeName = 'Assignment';
    newItem = () => {
        return {
            assignmentId: 0,
            userId: '',
            firstName: '',
            lastName: '',
            groupId: this.props.groupId,
            startDate: todayStr(),
            endDate: '',
            active: 1,
            locked: 0
        };
    };
    headerColumns = ['Marketing User',
        'Start Date',
        'End Date',
        'Status'];
    headerColumnClasses = null;
    dataColumnClasses = null;
    addSupported = true;
    editSupported = true;
    deleteSupported = false;
    paging = false;
    modalClass = GroupAssignmentModal;

    getItemCellContents = (a) => {
        return [`${a.firstName} ${a.lastName}`,
            a.startDate,
            a.endDate,
            a.active === 1 ? 'Active' : 'Inactive']
    };
}