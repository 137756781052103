import React from 'react';
import {Card, Col, Row, Table} from "react-bootstrap";
import {withRouter} from 'react-router-dom';
import {parse} from "qs";
import {SpinnerWrapper} from "../common/SpinnerWrapper";
import {GlobalContext} from "../../GlobalContext";

class MarketingSearchResults extends React.Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            loading: true
        };
    }

    getTerm = (props) => {
        const query = parse((props || this.props).location.search, {
            ignoreQueryPrefix: true
        });
        return query.q;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let term = this.getTerm();
        let prevTerm = this.getTerm(prevProps);
        if (term !== prevTerm) {
            this.doSearch(term);
        }
    }

    componentDidMount() {
        let term = this.getTerm();
        this.doSearch(term);
    }

    doSearch = (term) => {
        this.context.apiRequest("GET", "/marketing/search?q="+encodeURIComponent(term))
            .then(d => {
                if(d.data) this.setState({items: d.data, loading: false});
            })
    }

    render() {
        return (
            <Card>
                <Card.Body>
                    <SpinnerWrapper visible={this.state.loading}>
                        <Card.Title>
                            <Row>
                                <Col>
                                    <h4>Search Results ({this.state.items.length})</h4>
                                </Col>
                            </Row>
                        </Card.Title>
                        <Table striped hover>
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Name</th>
                                    <th>Address/Specialty</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.items.map(i => <tr key={i.id} className={"clickable"} onClick={() => this.props.itemSelected(i.discrim, i.id)}>
                                    <td>{i.discrim}</td>
                                    <td>{i.name}</td>
                                    <td>{i.specialty}{i.addr1}</td>
                                </tr>)
                            }
                            </tbody>
                        </Table>
                    </SpinnerWrapper>
                </Card.Body>
            </Card>
        );
    }
}

export default withRouter(MarketingSearchResults);