import {Button, Col, Form, Modal} from "react-bootstrap";
import {OptionalSelect} from "../common/OptionalSelect";
import React from "react";

export class WorkTaskReasonAndUserModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            options: [],
            selectedValue: null,
            otherValue: null,
            otherKey: this.props.otherKey || 'Other',
            selectedUsers: []
        }
    }

    valueSelected = (e) => {
        let val = e.target.value;
        this.setState({selectedValue: val});
        if (val != this.state.otherKey) {
            this.setState({otherValue: null});
        }
    }

    ok = () => {
        let val = this.state.selectedValue;
        if (this.props.allowOther && this.state.selectedValue === this.state.otherKey && this.state.otherValue) {
            val = this.state.otherValue;
        }
        this.props.onOk(val || null, this.state.selectedUsers);
    }

    userToggled = (userId, checked) => {
        let newUsers = [...this.state.selectedUsers];
        let user = this.props.taggableUsers.find(u => u.userId == userId);
        if (user) {
            if (checked && !newUsers.includes(user)) {
                newUsers.push(user);
                this.setState({selectedUsers: newUsers});
            } else {
                this.setState({selectedUsers: newUsers.filter(u => u.userId != userId)});
            }
        }
    }

    render() {
        return <Modal size={this.props.size || "md"} show={this.props.show} onHide={this.props.onCancel}>
            <Modal.Header closeButton>
                <Modal.Title>{this.props.title || 'Select a Value'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {
                        this.props.options && <Form.Row>
                            <Col>
                                <OptionalSelect options={this.props.options}
                                                labelKey={this.props.labelKey} valueKey={this.props.valueKey}
                                                canSelectNone={this.props.canSelectNone}
                                                noneValue={this.props.noneValue}
                                                noneLabel={this.props.noneLabel}
                                                value={this.state.selectedValue} onChange={this.valueSelected}/>
                            </Col>
                        </Form.Row>
                    }
                    {
                        this.props.allowOther && this.state.selectedValue === this.state.otherKey &&
                        <Form.Row className={"mt-2"}>
                            <Col>
                                <Form.Control placeholder={'Other value'} value={this.state.otherValue} onChange={e => this.setState({otherValue: e.target.value})}/>
                            </Col>
                        </Form.Row>
                    }
                    {
                        this.props.taggableUsers && <Form.Row className={"mt-2"}>
                            <Form.Group as={Col}>
                                <Form.Label size="sm">
                                    {this.props.selectUsersLabel || 'User(s) who assisted (optional):'}
                                </Form.Label>
                                {this.props.taggableUsers.map(u => <Form.Check id={`u-${u.userId}`} key={u.userId} label={u.fullName} onChange={e => this.userToggled(u.userId, e.target.checked)}/> )}
                            </Form.Group>
                        </Form.Row>
                    }
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={this.props.onCancel}>
                    Cancel
                </Button>
                <Button variant="dark" onClick={this.ok} disabled={this.props.options && !this.props.canSelectNone && this.state.selectedValue == null}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    }
}