import React from 'react';
import {ItemList} from "../common/ItemList";
import {LienTaskModal} from "./LienTaskModal";
import {LienTaskResponseStatus, LienTaskStatus} from "../../utils/Enums";
import moment from "moment-timezone";

export default class LienTaskList extends ItemList {
    baseUrl = `/liens/${this.props.lienId}/tasks`;
    idField = 'id';
    itemTypeName = 'Task';
    newItem = () => { return {id: 0, title: 'Send Request for Care Authorization', description: '', dueDate: moment().add(7, 'days').format('MM/DD/YY'), status: '', taskType: 'ATTY_AUTH_REQUEST'}};
    headerColumns = ['Title', 'Due Date', 'Status', 'Response'];
    headerColumnClasses = null;
    dataColumnClasses = null;
    addSupported = true;
    editSupported = true;
    deleteSupported = false;
    paging = false;
    modalClass = LienTaskModal;
    updateProperty = 'lastUpdate';

    getItemCellContents = (task) => {
        return [
            <><div>{task.title}</div><div className={'font-medium font-italic'}>{task.description}</div></>,
            task.dueDate,
            LienTaskStatus[task.status],
            LienTaskResponseStatus[task.responseStatus]];
    };
}