import React from 'react';
import {AppHeader} from './AppHeader';
import {AppFooter} from './AppFooter';
import {GlobalContext} from "../GlobalContext";
import {Container, Toast} from "react-bootstrap";
import SchedulingHome from "./scheduling/SchedulingHome";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import {MarketingHome} from "./marketing/MarketingHome";
import {LiensHome} from "./liens/LiensHome";
import {AdminHome} from "./admin/AdminHome";
import {RoleWrapper} from "./common/RoleWrapper";
import {ReportsHome} from "./reports/ReportsHome";
import ReportView from "./reports/ReportView";
import {WorkTaskBoard} from "./workTasks/WorkTaskBoard";
import {
    addWsMessageHandler,
    closeWebSocket,
    isWebSocketConnected,
    isWebSocketConnecting,
    wsReconnect
} from "../utils/WebSockets";

export class AppUI extends React.Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.state = {
            notifications: []
        };
    }

    notificationMessageHandler = (msg) => {
        if (msg.event == 'notification') {
            this.context.toast('Notification', msg.data.text, msg.data.iconName, msg.data.iconColor);
            this.setState({notifications: [msg.data, ...this.state.notifications]});
        }
    }

    refreshNotificationsHandler = (msg) => {
        if (msg.event == 'refreshNotifications') {
            this.loadNotifications();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.context.authenticated && this.context.userProfile && this.context.userProfile.userId && !isWebSocketConnected() && !isWebSocketConnecting()) {
            wsReconnect(this.context.userProfile.userId);
            addWsMessageHandler(this.notificationMessageHandler);
            addWsMessageHandler(this.refreshNotificationsHandler);
            this.loadNotifications();
        }
    }

    componentWillUnmount() {
        closeWebSocket();
    }

    loadNotifications() {
        this.context.apiRequest("GET", "/notifications")
            .then(r => {
                if (r && r.data) {
                    this.setState({notifications: r.data});
                }
            })
    }

    authBoardConfig = {
        dismissReasons: [
            {value: 'Completed/no action required', label: 'Completed/no action required'},
            {value: 'Order - Duplicate', label: 'Order - Duplicate'},
            {value: 'Chart Deactivated - Patient discharged', label: 'Chart Deactivated - Patient discharged'},
            {value: 'Patient declined order', label: 'Patient declined order'},
            {value: 'Chart Deactivated - Transfer of Care', label: 'Chart Deactivated - Transfer of Care'},
            {value: 'Order - Closed', label: 'Order - Closed'},
            {value: 'Order - Not found', label: 'Order - Not found'},
            {value: 'Accident Case - Medical Lien', label: 'Accident Case - Medical Lien'},
            {value: 'Accident/Injury Case - Worker\'s Compensation', label: 'Accident/Injury Case - Worker\'s Compensation'},
            {value: 'Appointment Cancelled/Rescheduled', label: 'Appointment Cancelled/Rescheduled'},
            {value: 'Order - Expired', label: 'Order - Expired'},
            {value: 'Self Pay', label: 'Self Pay'},
            {value: 'Order - Status Check', label: 'Order - Status Check'},
            {value: 'Unable to process due to insurance guidelines', label: 'Unable to process due to insurance guidelines'}
        ].sort((a,b) => a.label.localeCompare(b.label)),

        pendingReasons: {
            'UPCOMING_APPT_ELIG': [
                {value: 'Authorization', label: 'Authorization'},
                {value: 'Beginning of Month', label: 'Beginning of Month'},
                {value: 'Referral', label: 'Referral'},
                {value: 'Pending Auth Tile', label: 'Pending Auth Tile'},
                {value: 'Clarification', label: 'Clarification'}
            ].sort((a,b) => a.label.localeCompare(b.label)),
            '_DEFAULT': [
                {value: 'With Insurance', label: 'With Insurance'},
                {value: 'Dictation', label: 'Dictation'},
                {value: 'Clarification', label: 'Clarification'},
                {value: 'Referral', label: 'Referral'},
                {value: 'Beginning of Month (BOM)', label: 'Beginning of Month (BOM)'}
            ].sort((a,b) => a.label.localeCompare(b.label))
        },

        completeReasons: [
            {value: 'Medicare TPL Request Completed - None on file', label: 'Medicare TPL Request Completed - None on file'},
            {value: 'Medicare TPL Request Completed - Accident/Injury Case - Worker\'s Compensation on file', label: 'Medicare TPL Request Completed - Accident/Injury Case - Worker\'s Compensation on file'},
            {value: 'Medicare TPL Request Completed - Accident/Injury Case - MedPay on file', label: 'Medicare TPL Request Completed - Accident/Injury Case - MedPay on file'},
            {value: 'Medicare TPL Request Completed - Medicare Advantage Plan on file', label: 'Medicare TPL Request Completed - Medicare Advantage Plan on file'},
            {value: 'Medicare TPL Request Completed - Medicare Part B Inactive', label: 'Medicare TPL Request Completed - Medicare Part B Inactive'},
            {value: 'Medicare TPL Request Completed - Other Liability Insurance is Primary', label: 'Medicare TPL Request Completed - Other Liability Insurance is Primary'}
        ]
    };
    render() {
        return (
            <Router>
                <Container id="appui" fluid style={{position: 'relative'}}>
                    <Switch>
                        <Route exact={true} path={"/"}>
                            <BodyWrapper header={true} notifications={this.state.notifications}>
                                <RoleWrapper roles={["Scheduling", "Scheduling (Read Only)", "Authorizations", "Medical Assistant"]}><SchedulingHome/></RoleWrapper>
                            </BodyWrapper>
                        </Route>
                        <Route path={["/patients"]}>
                            <BodyWrapper header={true} notifications={this.state.notifications}>
                                <RoleWrapper roles={["Scheduling", "Scheduling (Read Only)", "Authorizations", "Medical Assistant"]}><SchedulingHome/></RoleWrapper>
                            </BodyWrapper>
                        </Route>
                        <Route path={["/marketing"]}>
                            <BodyWrapper header={true} notifications={this.state.notifications}>
                                <RoleWrapper roles={["Scheduling", "Marketing"]}><MarketingHome/></RoleWrapper>
                            </BodyWrapper>
                        </Route>
                        <Route path={["/liens"]}>
                            <BodyWrapper header={true} notifications={this.state.notifications}>
                                <RoleWrapper roles={["Liens"]}><LiensHome/></RoleWrapper>
                            </BodyWrapper>
                        </Route>
                        <Route path={["/authBoard"]}>
                            <BodyWrapper header={true} notifications={this.state.notifications}>
                                <RoleWrapper roles={["System Admin", "Authorizations"]}>
                                    <WorkTaskBoard category={"AUTH"}
                                                   department={"Auth"}
                                                   pendingReasons={this.authBoardConfig.pendingReasons}
                                                   dismissReasons={this.authBoardConfig.dismissReasons}
                                                   completeReasons={this.authBoardConfig.completeReasons}
                                                   showCompleteReasonsForTaskTypes={["MEDICARE_TPL"]}
                                    />
                                </RoleWrapper>
                            </BodyWrapper>
                        </Route>
                        <Route path={["/reports"]}>
                            <BodyWrapper header={true} notifications={this.state.notifications}>
                                <RoleWrapper roles={["System Admin","Authorizations","Scheduling","Marketing","Medical Assistant"]}><ReportsHome/></RoleWrapper>
                            </BodyWrapper>
                        </Route>
                        <Route path={["/viewReport/:fileName"]}>
                            <BodyWrapper header={false}>
                                <RoleWrapper roles={["System Admin","Authorizations","Scheduling","Marketing","Medical Assistant"]}><ReportView/></RoleWrapper>
                            </BodyWrapper>
                        </Route>
                        <Route path={["/admin"]}>
                            <BodyWrapper header={true} notifications={this.state.notifications}>
                                <RoleWrapper roles={["System Admin", "Supervisor"]}><AdminHome/></RoleWrapper>
                            </BodyWrapper>
                        </Route>
                    </Switch>
                <AppFooter/>
            </Container>
        </Router>
        );
    }
}

export class BodyWrapper extends React.Component {
    render() {
        return <div id="appBody">
            {this.props.header && <AppHeader notifications={this.props.notifications}/>}
            <div style={{overflow: "auto"}}>
                {this.props.children}
            </div>
        </div>
    }
}