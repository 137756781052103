import {ItemModal} from "../common/ItemModal";
import {Button, Col, Form, FormControl, InputGroup, Table} from "react-bootstrap";
import React from "react";
import {GlobalContext} from "../../GlobalContext";
import {ICD10Search} from "../common/ICD10Search";
import {Glyph} from "../common/Glyph";
import {OptionalSelect} from "../common/OptionalSelect";

export class AdminSocRuleModal extends ItemModal {
    static contextType = GlobalContext;

    idField = 'id';
    itemTypeName = 'Rule';

    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            testTypes: [],
            testSubtypes: []
        }
    }

    componentDidMount() {
        this.context.cachedGet(`/lookup/test_type?orderBy=testType`, 300)
            .then(d => this.setState({testTypes: d.data}));
        this.context.cachedGet(`/lookup/subspecialty?filter=${encodeURIComponent('active=1')}&orderBy=subspecialty`, 300)
            .then(d => this.setState({testSubtypes: d.data}));
    }

    ruleSettingChanged = (e) => {
        let settingField = e.target.name;
        let value = e.target.value;
        let rule = this.state.item;
        rule.settings[settingField] = value;
        this.setItemField('settings', rule.settings);
    };

    icd10Selected = (code, description) => {
        let settings = this.state.item.settings;
        if (settings.icds == null) {
            settings.icds = [];
        }
        settings.icds.push({code, description});
        this.setItemField('settings', settings);
    };

    deleteIcd = (code) => {
        let settings = this.state.item.settings;
        if (settings.icds) {
            settings.icds = settings.icds.filter(i => i.code !== code);
            this.setItemField('settings', settings);
        }
    };

    addMatchString = () => {
        let input = document.getElementById('matchString');
        let str = input.value;
        if (str == null || str.trim() === '') return;
        let settings = this.state.item.settings;
        if (settings.matchStrings === null) {
            settings.matchStrings = [];
        }
        settings.matchStrings.push(str);
        this.setItemField('settings', settings);
        input.value = '';
        input.focus();
    };

    matchStringKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.addMatchString();
            e.preventDefault();
        }
    }

    deleteMatchString = (str) => {
        let settings = this.state.item.settings;
        if (settings.matchStrings) {
            settings.matchStrings = settings.matchStrings.filter(s => s !== str);
            this.setItemField('settings', settings);
        }
    };

    addProcedure = () => {
        let typeSelect = document.getElementById('testCode');
        let typeOption = typeSelect.options[typeSelect.selectedIndex];
        let subtypeSelect = document.getElementById('testSubtypeCode');
        let subtypeOption = subtypeSelect.options[subtypeSelect.selectedIndex || 0];
        let followUp = document.getElementById('followUp').checked;
        let fuValue = followUp ? document.getElementById('followUpValue').value : null;
        let unitSelect = document.getElementById('followUpUnit');
        let fuUnit = followUp ? unitSelect.options[unitSelect.selectedIndex || 0].value : null;

        let procedure = {
            type: typeOption.text,
            typeCode: typeOption.value,
            subtype: subtypeOption.text,
            subtypeCode: subtypeOption.value,
            followUp,
            followUpValue: fuValue,
            followUpUnit: fuUnit
        }
        let settings = this.state.item.settings;
        let procedures = settings.recommendedProcedures || [];
        procedures.push(procedure);
        settings.recommendedProcedures = procedures;
        this.setItemField('settings', settings);
    };

    followUpChanged = (value) => {

    };

    deleteProcedure = (index) => {
        console.log(`deleting procedure at index ${index}`);
        let settings = this.state.item.settings;
        if (settings.recommendedProcedures) {
            settings.recommendedProcedures.splice(index, 1);
            this.setItemField('settings', settings);
        }
    };

    renderFormContents = (r) => {
        let settings = r.settings;
        return <div>
            <Form.Row>
                <Form.Group as={Col} controlId="name">
                    <Form.Label size="sm">
                        Rule Name
                    </Form.Label>
                    <Form.Control required size="sm" name={"name"} value={r.name || ''}
                                  onChange={this.itemFieldChanged}/>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} controlId="ruleType">
                    <Form.Label size="sm">
                        Condition
                    </Form.Label>
                    <Form.Control as="select" size="sm" custom name={"ruleType"} onChange={this.ruleSettingChanged} value={settings.ruleType}>
                        <option value="DIAGNOSIS_CONTAINS">Diagnosis text contains:</option>
                        <option value="ICD_DESCRIPTION_CONTAINS">Any of patient's ICD10 descriptions contain:</option>
                        <option value="DIAGNOSIS_OR_ICD_DESCRIPTION_CONTAINS">Diagnosis text OR any of patient's ICD10 descriptions contain:</option>
                        <option value="ICD_CODES">Patient has any of these ICD10 codes:</option>
                    </Form.Control>
                </Form.Group>
            </Form.Row>
            {
                settings.ruleType === "ICD_CODES" ?
                    <div>
                        <Form.Row>
                            <Form.Group as={Col} controlId="icdSearch">
                                <Form.Label size="sm">
                                    Search ICDs:
                                </Form.Label>
                                <ICD10Search id={"icdSearch"} icd10Selected={this.icd10Selected}/>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Col>
                                {settings.icds && settings.icds.length > 0 ? <Table striped size={"sm"}>
                                        <thead>
                                        <tr>
                                            <th>Code</th>
                                            <th>Description</th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {settings.icds.map(icd => <tr key={icd.code}>
                                            <td>{icd.code}</td>
                                            <td>{icd.description}</td>
                                            <td className={"text-right"}><Glyph name={"x"} label={"Remove"} className={"clickable"} onClick={() => this.deleteIcd(icd.code)}/></td>
                                        </tr>)}
                                        </tbody>
                                    </Table>
                                    :
                                    <div className={"font-italic text-secondary text-center"}>No ICD's added yet</div>}
                            </Col>
                        </Form.Row>
                    </div>
                    :
                    <div>
                        <Form.Row>
                            <Form.Group as={Col} controlId="matchString">
                                <Form.Label size="sm">
                                    Match Strings:
                                </Form.Label>
                                <InputGroup className="mb-3">
                                    <FormControl
                                        placeholder="Enter match string"
                                        aria-label="Enter match string"
                                        aria-describedby="basic-addon2"
                                        onKeyDown={this.matchStringKeyDown}
                                    />
                                    <InputGroup.Append>
                                        <Button variant="outline-secondary" onClick={this.addMatchString}>Add</Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Col>
                                {settings.matchStrings && settings.matchStrings.length > 0 ? <Table striped size={"sm"}>
                                        <thead>
                                        <tr>
                                            <th>Match String</th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {settings.matchStrings.map(s => <tr key={s}>
                                            <td>{s}</td>
                                            <td className={"text-right"}><Glyph name={"x"} label={"Remove"} className={"clickable"} onClick={() => this.deleteMatchString(s)}/></td>
                                        </tr>)}
                                        </tbody>
                                    </Table>
                                    :
                                    <div className={"font-italic text-secondary text-center"}>No match strings added yet</div>}
                            </Col>
                        </Form.Row>
                    </div>
            }
            <Form.Row className={"mt-3"}>
                <Col>
                    When patient matches the criteria, recommend the following procedures:
                </Col>
            </Form.Row>
            <Form.Row className={"align-items-center"}>
                <Form.Group as={Col} controlId={"testCode"}>
                    <Form.Label size="sm">
                        Test Type
                    </Form.Label>
                    <OptionalSelect name={"testCode"} options={this.state.testTypes} valueKey={"testCode"} labelKey={"testType"} canSelectNone={false} defaultValue={""}/>
                </Form.Group>
                <Form.Group as={Col} controlId={"testSubtypeCode"}>
                    <Form.Label size="sm">
                        Test Subtype
                    </Form.Label>
                    <OptionalSelect name={"testSubtypeCode"} options={this.state.testSubtypes} valueKey={"subspecialtyCode"} labelKey={"subspecialty"} canSelectNone={true} defaultValue={""}/>
                </Form.Group>
                <Form.Group as={Col} controlId={"followUp"} md={2} className={"text-center"}>
                    <Form.Label size="sm">
                        Follow Up?
                    </Form.Label>
                    <Form.Check
                        type={"switch"}
                        id={"followUp"}
                        name={"followUp"}
                        aria-label={"Follow Up?"}
                        onChange={(e) => this.followUpChanged(e.target.checked)}/>
                </Form.Group>
                <Form.Group as={Col} controlId={"followUpValue"} md={1}>
                    <Form.Label size="sm">
                        Number
                    </Form.Label>
                    <Form.Control size="sm" name={"followUpValue"} defaultValue={""}/>
                </Form.Group>
                <Form.Group as={Col} controlId={"followUpUnit"}>
                    <Form.Label size="sm">
                        Unit
                    </Form.Label>
                    <Form.Control as="select" size="sm" custom name={"followUpUnit"} defaultValue={"Months"}>
                        <option value="Days">Days</option>
                        <option value="Weeks">Weeks</option>
                        <option value="Months">Months</option>
                    </Form.Control>
                </Form.Group>
                <Col md={1}>
                    <Button variant={"dark"} onClick={this.addProcedure}>Add</Button>
                </Col>
            </Form.Row>
            <Form.Row>
                <Col>
                    {settings.recommendedProcedures && settings.recommendedProcedures.length > 0 ? <Table striped size={"sm"}>
                            <thead>
                            <tr>
                                <th>Test Type</th>
                                <th>Test Subtype</th>
                                <th>Follow Up</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {settings.recommendedProcedures.map((p, i) => <tr key={i}>
                                <td>{p.type}</td>
                                <td>{p.subtype}</td>
                                <td>{p.followUp ? p.followUpValue + ' ' + p.followUpUnit : 'None'}</td>
                                <td className={"text-right"}><Glyph name={"x"} label={"Remove"} className={"clickable"} onClick={() => this.deleteProcedure(i)}/></td>
                            </tr>)}
                            </tbody>
                        </Table>
                        :
                        <div className={"font-italic text-secondary text-center"}>No procedures added yet</div>}
                </Col>
            </Form.Row>
        </div>;
    };

}